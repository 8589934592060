import Axios from 'axios';
import { axios } from '../config/axios';
import storage from '../config/storage';

const endPoint = '/project/document';

const getListByPage = (search, page, limit) => {
    return axios.post(`${endPoint}/pagination`, {
        search: search,
        page: page,
        limit: limit
    });
}

const create = (createDto) => {
    return axios.post(`${endPoint}/`, createDto);
}

const getDocumentUrlById = (documentId) => {
    return axios.get(`${process.env.REACT_APP_API_URL}${endPoint}/document-file/${documentId}`);
}

const deleteById = (documentId) => {
    return axios.delete(`${endPoint}/${documentId}`);
}

export {
    getListByPage,
    create,
    getDocumentUrlById,
    deleteById,
}
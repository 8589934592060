import Axios from 'axios';
import { axios } from '../config/axios';
import storage from '../config/storage';

const endPoint = '/project/candidate';

const downloadCandidateTemplate = () => {
    const token = storage.getToken();

    return Axios.get(`${process.env.REACT_APP_API_URL}${endPoint}/download-candidate-template`, {
        responseType: 'blob',
        headers: {
            authorization: token?.accessToken
        }
    });
}

const getPresentCount = (projectMasterId) => {
    return axios.get(`${endPoint}/present-count/${projectMasterId}`);
}

const downloadAttendanceSheet = (projectMasterId) => {
    const token = storage.getToken();

    return Axios.get(`${process.env.REACT_APP_API_URL}${endPoint}/download-attendance-sheet/${projectMasterId}`, {
        responseType: 'blob',
        headers: {
            authorization: token?.accessToken
        }
    });
}

const getListByPage = async (projectMasterId, examHallId, searchTerm, pageNumber, limit) => {
    return axios.post(`${endPoint}/list-by-project-master-with-pagination`, {
        projectMasterId: projectMasterId,
        examHallId: examHallId ?? 0,
        search: searchTerm,
        page: pageNumber,
        limit: limit
    });
}

export {
    downloadCandidateTemplate,
    getPresentCount,
    downloadAttendanceSheet,
    getListByPage,
}
import { useContext, useEffect, useState } from 'react';
import { Box, Breadcrumbs, Typography, Table, TableContainer, Paper, TableHead, TableRow, TableCell, TableBody, Pagination, Modal } from '@mui/material';

import { deleteById, getListByPage } from '../../../../services/macAddressService';
import { Container, MainContainer } from '../../../../components';
import { Menu } from '../Menu/Menu';
import RadiusButton from '../../../../components/RadiusButton'
import { useToaster } from '../../../../providers/ToasterProvider';
import dayjs from '../../../../config/dayjs';

import SEARCH_ICON from '../../../../assets/images/search-icon.svg';
import ARROW from '../../../../assets/images/breadcrumb-arrow.svg';
import ARROW_OPEN from '../../../../assets/images/breadcrumb-arrow-open.svg';
import ACTION_DELETE from '../../../../assets/images/action-delete.svg';
import { ProjectContext } from '../../../../providers/projectProvider';

const columns = [
    { id: 'driveName', label: 'Drive Name', minWidth: 140 },
    { id: 'courseName', label: 'Course Name', minWidth: 140 },
    { id: 'batchName', label: 'Batch Name', minWidth: 140 },
    { id: 'examDate', label: 'Exam Date', minWidth: 120 },
    { id: 'hallName', label: 'Exam Hall Name', minWidth: 120 },
    { id: 'serverId', label: 'Server Id', minWidth: 120 },
    { id: 'serverType', label: 'Server Type', minWidth: 120 },
    { id: 'macAddress', label: 'Mac ID', minWidth: 120 },
    { id: 'actions', label: 'Action', minWidth: 200 },
];
const limit = parseInt(process.env.REACT_APP_PAGE_LIMIT);

export const MacIds = () => {

    const { projectDetails } = useContext(ProjectContext);
    const [macIds, setMacIds] = useState([]);
    const [maxPages, setMaxPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const { addNotification } = useToaster();
    const [search, setSearch] = useState('');
    const [newSearch, setNewSearch] = useState(false);
    const [deleteModalToggler, setDeleteModalToggler] = useState(false);
    const [selectedMacAddress, setSelectedMacAddress] = useState(null);
    const [timer, setTimer] = useState(null);


    useEffect(() => {
        async function fetchData() {
            try {
                const response = await getListByPage(search, currentPage, limit);
                if (response?.code === 'OK') {
                    const data = response.data;
                    const totalPages = Math.ceil(data.count / limit);
                    setMaxPages(totalPages);
                    setMacIds(
                        data.rows.map((macId) => {
                            macId.examDate = dayjs.utc(macId['projectMaster']?.examDate);
                            return macId;
                        })
                    );
                }
            } catch (error) {
                console.log('There is an error while fetching projects list', error);
            }
        }
        fetchData();
    }, [newSearch]);

    const handleDeleteMacAddress = (masterIndex) => {
        const master = macIds[masterIndex];
        if (master?.id) {
            setSelectedMacAddress(master.id);
            setDeleteModalToggler(true);
        }
    }
    const handlePageChange = (e, value) => {
        setCurrentPage(value);
        setNewSearch(!newSearch);
    };

    const onConfirmDelete = async () => {
        if (!selectedMacAddress) return;
        try {
            const res = await deleteById(selectedMacAddress);
            if (res.code === 'OK') {
                addNotification('Mac id deleted successfully.', 'success');
                setMacIds(
                    macIds.filter((macId) => macId.id !== selectedMacAddress)
                )
                setDeleteModalToggler(false);
                setSelectedMacAddress(null);
            }
        } catch (error) {
            const code = error?.response?.data?.code;
            if (code === 'UNPROCESSABLE_ENTITY') {
                addNotification('Unable to delete mac id.', 'danger');
                setDeleteModalToggler(false);
                setSelectedMacAddress(null);
            }
            console.log('There is an error while deleting mac address.', error);
        }
    }

    const onSearch = (e) => {
        setSearch(e.target.value);
        if (timer) {
            clearTimeout(timer);
        }
        const id = setTimeout(() => {
            setCurrentPage(1);
            setNewSearch(!newSearch);
        }, 400);
        setTimer(id);
    }

    const renderTableCell = (columnId, rowIndex) => {
        const row = macIds[rowIndex];
        switch (columnId) {
            case 'driveName':
                return row['projectMaster']['drive']['driveName']
            case 'courseName':
                return row['projectMaster']['course']['courseName']
            case 'batchName':
                return row['projectMaster']['batch']['batchName']
            case 'examDate':
                return dayjs(row['examDate']).format('DD-MM-YYYY')
            case 'hallName':
                return row['examHall']['hallName']
            case 'serverId':
                return row['server']['serverId']
            case 'actions':
                return <>
                    <Box component='span' sx={{
                        mx: 1,
                        cursor: 'pointer'
                    }} onClick={() => handleDeleteMacAddress(rowIndex)}>
                        <img src={ACTION_DELETE} alt='' />
                        <Typography variant='body1' sx={{
                            display: 'inline-block',
                            verticalAlign: 'super',
                            ml: 1,
                            color: 'error.main'
                        }}>Delete</Typography>
                    </Box>
                </>
            default:
                return row[columnId];
        }
    }

    return (
        <MainContainer sx={{ bgcolor: 'red' }}>
            <Menu>
                <Breadcrumbs aria-label="breadcrumb" separator='' sx={{ gap: 12 }}>
                    <Typography color="text.primary" sx={{ fontWeight: 400, textTransform: 'capitalize' }}>{projectDetails?.projectName}</Typography>
                    <Box sx={{ display: 'flex', alignContent: 'center' }}>
                        <img src={ARROW} alt=''></img>
                    </Box>

                    <Typography color="text.primary" sx={{ fontWeight: 500 }}>Mac IDs</Typography>
                    <Box sx={{ display: 'flex', alignContent: 'center' }}>
                        <img src={ARROW_OPEN} alt='' ></img>
                    </Box>
                </Breadcrumbs>
            </Menu>
            <Container sx={{
                display: 'flex',
                flexDirection: 'column'
            }}>
                <h1 className='title'>Mac IDs</h1>

                <div className="search-header-wrapper">
                    <div className='search-wrapper'>
                        <input type='text' className='search-box' value={search} onChange={onSearch} placeholder='Search...' />
                        <img className='search-icon' src={SEARCH_ICON} alt='' />
                    </div>
                </div>

                <Paper sx={{
                    width: '100%',
                    overflow: 'hidden',
                    bgcolor: 'background.base',
                    maxHeight: 'calc(100vh - 305px)',
                    maxWidth: 'calc(100vw - 140px)',
                    borderRadius: 1,
                    mb: 4
                }}>
                    <TableContainer sx={{
                        px: 1,
                        pb: 1,
                        maxHeight: 'inherit',
                        maxWidth: 'inherit',
                        border: '1px solid',
                        borderColor: 'primary.main',
                        borderRadius: 1
                    }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                            sx={{
                                                color: 'primary.main'
                                            }}
                                            key={column.id}
                                            align='center'
                                            style={{ minWidth: column.minWidth }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {!macIds?.length
                                    ? <TableRow sx={{ mx: 'auto', p: 2, color: 'grey.500' }}>
                                        <TableCell colSpan={7} sx={{ border: 'none', textAlign: 'center' }}>
                                            No records available
                                        </TableCell>
                                    </TableRow>
                                    : macIds.map((row, rowIndex) => {
                                        return (
                                            <TableRow hover role="checkbox" key={rowIndex}>
                                                {columns.map((column, index) => {
                                                    return <TableCell key={column.id} align='center' sx={{
                                                        px: 1.5,
                                                        py: 1.25
                                                    }}>
                                                        {renderTableCell(column.id, rowIndex)}
                                                    </TableCell>
                                                })}
                                            </TableRow>
                                        );
                                    })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>

                <Pagination sx={{ mt: 'auto' }} count={maxPages} page={currentPage} onChange={handlePageChange} shape="rounded" />

                <Modal open={deleteModalToggler} hideBackdrop={true} className='backdrop' onClose={() => setDeleteModalToggler(false)}>
                    <Box className='modal-container' sx={{
                        maxWidth: 480,
                        p: 9,
                    }}>
                        <Typography variant='h6' sx={{
                            fontWeight: 400,
                            mb: 8,
                            textAlign: 'center'
                        }}>
                            Do you want to delete this mac id?
                        </Typography>

                        <div className='actions-container' style={{ justifyContent: 'center', gap: '40px' }}>
                            <RadiusButton variant='outlined' onClick={() => setDeleteModalToggler(false)}>
                                No
                            </RadiusButton>
                            <RadiusButton variant='contained' onClick={() => onConfirmDelete()}>
                                Yes
                            </RadiusButton>
                        </div>
                    </Box>
                </Modal>

            </Container>
        </MainContainer >
    );
}

import { createContext, useState } from "react";
import storage from "../config/storage";

export const AuthContext = createContext();

const getInitialState = () => {
    const token = storage.getToken();
    const user = storage.getUserDetails();
    
    if (token?.accessToken && user?.email && user?.userType) {
        return user
    }
    return null;
}

const AuthContextProvider = ({ children }) => {
    const [user, setUser] = useState(getInitialState);

    return (
        <AuthContext.Provider value={{ user, setUser }}>
            {children}
        </AuthContext.Provider>
    )
};

export default AuthContextProvider;

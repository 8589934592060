import Axios from 'axios';
import { axios } from '../config/axios';
import storage from '../config/storage';

const endPoint = '/project/project-master';

const getAll = () => {
    return axios.get(`${endPoint}`);
}

const getListByPage = async (search, pageNumber, limit) => {
    return axios.post(`${endPoint}/pagination`, {
        search: search,
        page: pageNumber,
        limit: limit
    });
}

const getById = async (masterId) => {
    return axios.get(`${endPoint}/${masterId}`);
}

const createMaster = (formData) => {
    return axios.post(`${endPoint}`, formData);
}

const updateMaster = (masterId, formData) => {
    return axios.put(`${endPoint}/${masterId}`, formData);
}

const uploadCandidate = (masterId, formData) => {
    return axios.put(`${endPoint}/upload-candidate/${masterId}`, formData);
}

const deleteMaster = (masterId) => {
    return axios.delete(`${endPoint}/${masterId}`);
}

const deleteAllCandidates = (masterId) => {
    return axios.delete(`${endPoint}/delete-all-candidates/${masterId}`);
}

const getCandidateDataCsv = (masterId) => {
    const token = storage.getToken();

    return Axios.get(`${process.env.REACT_APP_API_URL}${endPoint}/download-csv/${masterId}`, {
        responseType: 'blob',
        headers: {
            authorization: token?.accessToken
        }
    });
}

const getExamHallAndServerId = ({ driveId, courseId, batchId, examDate }) => {
    return axios.post(`${endPoint}/get-exam-hall-and-server-id`, {
        driveId,
        courseId,
        batchId,
        examDate
    });
}

const getExamDates = ({ driveId, courseId, batchId }) => {
    return axios.post(`${endPoint}/get-exam-dates`, {
        driveId,
        courseId,
        batchId
    });
}

const listForContentDashboard = (pageNumber, limit) => {
    return axios.post(`${endPoint}/list-for-content-dashboard-with-pagination`, {
        page: pageNumber,
        limit: limit
    });
}

const listByDriveAndCourse = (driveId, courseId) => {
    return axios.post(`${endPoint}/list-by-drive-and-course`, { driveId, courseId });
}

const enableExam = (masterId, body) => {
    return axios.post(`${endPoint}/enable-exam/${masterId}`, body);
}

const listWithConfigurationStatusByPagination = (search, pageNumber, limit) => {
    return axios.post(`${endPoint}/pagination/list-with-configuration-status`, {
        search: search,
        page: pageNumber,
        limit: limit
    });
}

const getDataForEnableExam = (configurationId) => {
    return axios.get(`${endPoint}/data-for-enable-exam/${configurationId}`);
}

const getDataForScoreManagement = () => {
    return axios.get(`${endPoint}/data-for-score-management`);
}

export {
    getAll,
    getListByPage,
    getById,
    createMaster,
    updateMaster,
    uploadCandidate,
    deleteMaster,
    deleteAllCandidates,
    getCandidateDataCsv,
    getExamHallAndServerId,
    getExamDates,
    listForContentDashboard,
    listByDriveAndCourse,
    enableExam,
    listWithConfigurationStatusByPagination,
    getDataForEnableExam,
    getDataForScoreManagement,
}
import { createContext, useContext, useReducer } from 'react';

const ToasterContext = createContext();

const notificationReducer = (state, action) => {
    switch (action.type) {
        case 'ADD_NOTIFICATION':
            return [...state, action.payload];
        case 'REMOVE_NOTIFICATION':
            return state.filter((n) => n.id !== action.payload);
        default:
            throw new Error();
    }
}

export const useToaster = () => useContext(ToasterContext);

export const ToasterProvider = ({ children }) => {
    const [notifications, dispatch] = useReducer(notificationReducer, []);

    const addNotification = (message, type = 'information') => {
        const newNotification = { id: Date.now(), message, type };
        dispatch({ type: 'ADD_NOTIFICATION', payload: newNotification });
        setTimeout(() => {
            removeNotification(newNotification.id)
        }, 5000);
    };

    const removeNotification = (id) => {
        dispatch({ type: 'REMOVE_NOTIFICATION', payload: id });
    };

    return (
        <ToasterContext.Provider
            value={{ notifications, addNotification, removeNotification }}
        >
            {children}
        </ToasterContext.Provider>
    );
};

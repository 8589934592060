import { axios } from '../config/axios';

const endPoint = '/project/mac-address';

const getListByPage = async (search, pageNumber, limit) => {
    return axios.post(`${endPoint}/pagination`, {
        search: search,
        page: pageNumber,
        limit: limit
    });
}

const deleteById = async (macAddressId) => {
    return axios.delete(`${endPoint}/${macAddressId}`);
}


export {
    getListByPage,
    deleteById,
}
import { useContext, useEffect, useState } from 'react';
import { Box, Breadcrumbs, Typography, Table, TableContainer, Paper, TableHead, TableRow, TableCell, TableBody, Pagination, Modal } from '@mui/material';

import { getListByPage, create, getDocumentUrlById, deleteById } from '../../../../services/documentService';
import { Container, MainContainer } from '../../../../components';
import { Menu } from '../Menu/Menu';
import RadiusButton from '../../../../components/RadiusButton'
import { useToaster } from '../../../../providers/ToasterProvider';

import SEARCH_ICON from '../../../../assets/images/search-icon.svg';
import ARROW from '../../../../assets/images/breadcrumb-arrow.svg';
import ARROW_OPEN from '../../../../assets/images/breadcrumb-arrow-open.svg';
import UPLOAD from '../../../../assets/images/upload.svg';
import ACTION_DELETE from '../../../../assets/images/action-delete.svg';
import ACTION_DOWNLOAD from '../../../../assets/images/download-blue.svg';
import DELETE_ICON from '../../../../assets/images/action-delete.svg';
import './Documents.scss';
import { LoadingButton } from '@mui/lab';
import { ProjectContext } from '../../../../providers/projectProvider';

let timer = null;

const columns = [
    { id: 'driveName', label: 'Drive Name', minWidth: 180 },
    { id: 'courseName', label: 'Course Name', minWidth: 180 },
    { id: 'sop', label: 'SOP Document', minWidth: 350 },
    { id: 'direction', label: 'Direction Document', minWidth: 350 },
];
const limit = parseInt(process.env.REACT_APP_PAGE_LIMIT);
const acceptedMimes = ['application/pdf', 'application/zip', 'application/x-zip-compressed', 'application/x-rar']

export const Documents = () => {

    const [documents, setDocuments] = useState([]);
    const [maxPages, setMaxPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const { addNotification } = useToaster();
    const { projectDetails } = useContext(ProjectContext);
    const handlePageChange = (e, value) => {
        setCurrentPage(value)
    };
    const [search, setSearch] = useState('');
    const [newSearch, setNewSearch] = useState(false);
    const [uploadDocumentData, setUploadDocumentData] = useState({
        isVisible: false,
        file: null,
        index: null,
        documentType: null
    });
    const [deleteDocumentData, setDeleteDocumentData] = useState({
        isVisible: false,
        index: null,
        documentType: null
    });

    const [dataUpdated, setDataUpdated] = useState(false);
    const [isUploading, setIsUploading] = useState(false);

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await getListByPage(search, currentPage, limit);
                if (response?.code === 'OK') {
                    const totalPages = Math.ceil(response.data.count / limit);
                    setMaxPages(totalPages);
                    setDocuments(response.data.rows);
                }
            } catch (error) {
                console.log('There is an error while fetching projects list', error);
            }
        }
        fetchData();
    }, [currentPage, newSearch, dataUpdated]);
    const handleDeleteDocument = (rowIndex, documentType) => {
        setDeleteDocumentData({
            isVisible: true,
            index: rowIndex,
            documentType: documentType
        });
    }
    const handleCloseDeleteModal = async () => {
        setDeleteDocumentData({
            isVisible: false,
            index: null,
            documentType: null
        })
    }
    const onConfirmDeleteMaster = async () => {
        const { index, documentType } = deleteDocumentData;
        if (index == null || documentType == null) return;
        const record = documents[index];
        let documentId = null;
        if (documentType === 'sop') {
            documentId = record.sopDocument?.id;
        } else {
            documentId = record.directionDocument?.id;
        }
        if (!documentId) return;
        try {
            const res = await deleteById(documentId);
            if (res.code === 'OK') {
                addNotification('Document deleted successfully.', 'success');
                setDeleteDocumentData({
                    isVisible: false,
                    index: null,
                    documentType: null,
                });
                setDataUpdated(!dataUpdated);
            }
        } catch (error) {
            console.log('There is an error while deleting document', error);
        }
    }
    const handleDownloadData = async (rowIndex, documentType) => {
        const record = documents[rowIndex];
        let documentId = null;
        if (documentType === 'sop') {
            documentId = record.sopDocument?.id;
        } else {
            documentId = record.directionDocument?.id;
        }
        if (!documentId) return;

        try {
            const res = await getDocumentUrlById(documentId);
            const url = res.data.documentUrl;
            const extension = res.data.extension;
            const link = document.createElement('a');
            link.href = url;
            if (extension === '.pdf') {
                link.target = '_blank';
            }
            link.setAttribute(
                'download',
                `${documentType}${extension}`,
            );
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            addNotification('File downloaded successfully.', 'success');
        } catch (error) {
            console.log('There is an error whild downloading document', error);
        }
    }
    const onUploadDocument = (index, documentType) => {
        setUploadDocumentData({
            isVisible: true,
            file: null,
            index: index,
            documentType: documentType
        });
    }
    const handleCloseUploadDocumentModel = () => {
        setUploadDocumentData({
            isVisible: false,
            file: null,
            index: null,
            documentType: null
        });
    }
    const onConfirmUploadDocument = async () => {
        const { index, file, documentType } = uploadDocumentData;
        if (index == null || documentType == null || file == null) {
            return;
        }
        setIsUploading(true);
        try {
            const pm = documents[index];
            const body = new FormData();
            body.append('driveId', pm.driveId);
            body.append('courseId', pm.courseId);
            body.append('documentType', documentType);
            body.append('file', file);
            const res = await create(body);
            console.log(res);
            if (res.code === 'CREATED') {
                addNotification('Document uploaded successfully.', 'success');
                setDataUpdated(!dataUpdated);
                setUploadDocumentData({
                    isVisible: false,
                    index: null,
                    documentType: null,
                    file: null,
                });
            }
        } catch (error) {
            const code = error?.response?.data?.code;
            if (code === 'UNPROCESSABLE_ENTITY') {
                addNotification('Document already exists.', 'danger');
            }
            console.log('There is an error while creating document', error);
        } finally {
            setIsUploading(false);
        }
    }
    const onSearch = (e) => {
        setSearch(e.target.value);
        if (timer) {
            clearTimeout(timer);
        }
        timer = setTimeout(() => {
            setCurrentPage(1);
            setNewSearch(!newSearch);
        }, 400);
    }
    const renderTableCell = (columnId, rowIndex) => {
        const row = documents[rowIndex];
        switch (columnId) {
            case 'sop':
                return <>
                    <Box component='span' sx={{
                        mx: 1,
                        cursor: 'pointer'
                    }} onClick={() => onUploadDocument(rowIndex, 'sop')}>
                        <img src={UPLOAD} alt='' />
                        <Typography variant='body1' sx={{
                            display: 'inline-block',
                            verticalAlign: 'super',
                            ml: 1,
                            color: 'primary.main'
                        }}>Upload</Typography>
                    </Box>
                    {row?.sopDocument && <>
                        <Box component='span' sx={{
                            mx: 1,
                            cursor: 'pointer'
                        }} onClick={() => handleDownloadData(rowIndex, 'sop')}>
                            <img src={ACTION_DOWNLOAD} alt='' />
                            <Typography variant='body1' sx={{
                                display: 'inline-block',
                                verticalAlign: 'super',
                                ml: 1,
                                color: 'primary.main'
                            }}>Download</Typography>
                        </Box>
                        <Box component='span' sx={{
                            mx: 1,
                            cursor: 'pointer'
                        }} onClick={() => handleDeleteDocument(rowIndex, 'sop')}>
                            <img src={ACTION_DELETE} alt='' />
                            <Typography variant='body1' sx={{
                                display: 'inline-block',
                                verticalAlign: 'super',
                                ml: 1,
                                color: 'error.main'
                            }}>Delete</Typography>
                        </Box>
                    </>}
                </>;
            case 'direction':
                return <>
                    <Box component='span' sx={{
                        mx: 1,
                        cursor: 'pointer'
                    }} onClick={() => onUploadDocument(rowIndex, 'direction')}>
                        <img src={UPLOAD} alt='' />
                        <Typography variant='body1' sx={{
                            display: 'inline-block',
                            verticalAlign: 'super',
                            ml: 1,
                            color: 'primary.main'
                        }}>Upload</Typography>
                    </Box>
                    {row?.directionDocument && <>
                        <Box component='span' sx={{
                            mx: 1,
                            cursor: 'pointer'
                        }} onClick={() => handleDownloadData(rowIndex, 'direction')}>
                            <img src={ACTION_DOWNLOAD} alt='' />
                            <Typography variant='body1' sx={{
                                display: 'inline-block',
                                verticalAlign: 'super',
                                ml: 1,
                                color: 'primary.main'
                            }}>Download</Typography>
                        </Box>
                        <Box component='span' sx={{
                            mx: 1,
                            cursor: 'pointer'
                        }} onClick={() => handleDeleteDocument(rowIndex, 'direction')}>
                            <img src={ACTION_DELETE} alt='' />
                            <Typography variant='body1' sx={{
                                display: 'inline-block',
                                verticalAlign: 'super',
                                ml: 1,
                                color: 'error.main'
                            }}>Delete</Typography>
                        </Box>
                    </>}
                </>;
            default:
                return row[columnId];
        }
    }
    const handleFileChange = ({ target }) => {
        const file = target?.files[0];
        if (file && acceptedMimes.includes(file.type)) {
            setUploadDocumentData({
                ...uploadDocumentData,
                file: file,
            });
        }
    }
    const handleFileRemove = ({ target }) => {
        const element = document.getElementById('documentFile');
        if (element) {
            element.value = '';
            setUploadDocumentData({
                ...uploadDocumentData,
                file: null,
            });
        }
    }

    return (
        <MainContainer sx={{ bgcolor: 'red' }}>
            <Menu>
                <Breadcrumbs aria-label="breadcrumb" separator='' sx={{ gap: 12 }}>
                    <Typography color="text.primary" sx={{ fontWeight: 400, textTransform: 'capitalize' }}>{projectDetails?.projectName}</Typography>
                    <Box sx={{ display: 'flex', alignContent: 'center' }}>
                        <img src={ARROW} alt=''></img>
                    </Box>

                    <Typography color="text.primary" sx={{ fontWeight: 500 }}>Documents List</Typography>
                    <Box sx={{ display: 'flex', alignContent: 'center' }}>
                        <img src={ARROW_OPEN} alt='' ></img>
                    </Box>
                </Breadcrumbs>
            </Menu>
            <Container sx={{
                display: 'flex',
                flexDirection: 'column'
            }}>
                <h1 className='title'>Documents List</h1>

                <div className="search-header-wrapper">
                    <div className='search-wrapper'>
                        <input type='text' className='search-box' value={search} onChange={onSearch} placeholder='Search...' />
                        <img className='search-icon' src={SEARCH_ICON} alt='' />
                    </div>
                </div>

                <Paper sx={{
                    width: '100%',
                    overflow: 'hidden',
                    bgcolor: 'background.base',
                    maxHeight: 'calc(100vh - 305px)',
                    maxWidth: 'calc(100vw - 140px)',
                    borderRadius: 1,
                    mb: 4
                }}>
                    <TableContainer sx={{
                        px: 1,
                        pb: 1,
                        maxHeight: 'inherit',
                        maxWidth: 'inherit',
                        border: '1px solid',
                        borderColor: 'primary.main',
                        borderRadius: 1
                    }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                            sx={{
                                                color: 'primary.main'
                                            }}
                                            key={column.id}
                                            align='center'
                                            style={{ minWidth: column.minWidth }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {!documents?.length
                                    ? <TableRow sx={{ mx: 'auto', p: 2, color: 'grey.500' }}>
                                        <TableCell colSpan={4} sx={{ border: 'none', textAlign: 'center' }}>
                                            No records available
                                        </TableCell>
                                    </TableRow>
                                    : documents.map((row, rowIndex) => {
                                        return (
                                            <TableRow key={rowIndex}>
                                                {columns.map((column, index) => {
                                                    return <TableCell key={column.id} align='center' sx={{
                                                        px: 1.5,
                                                        py: 1.25
                                                    }}>
                                                        {renderTableCell(column.id, rowIndex)}
                                                    </TableCell>
                                                })}
                                            </TableRow>
                                        );
                                    })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>

                <Pagination sx={{ mt: 'auto' }} count={maxPages} page={currentPage} onChange={handlePageChange} shape="rounded" />

                <Modal hideBackdrop={true} className='backdrop' open={deleteDocumentData.isVisible} onClose={handleCloseDeleteModal}>
                    <Box className='modal-container' sx={{
                        maxWidth: 580,
                        p: 9,
                    }}>
                        <Typography variant='h6' sx={{
                            fontWeight: 400,
                            mb: 8,
                            textAlign: 'center'
                        }}>
                            Do you want to delete this document?
                        </Typography>

                        <div className='actions-container' style={{ justifyContent: 'center', gap: '40px' }}>
                            <RadiusButton variant='outlined' onClick={handleCloseDeleteModal}>
                                No
                            </RadiusButton>
                            <RadiusButton variant='contained' onClick={onConfirmDeleteMaster}>
                                Yes
                            </RadiusButton>
                        </div>
                    </Box>
                </Modal>

                <Modal hideBackdrop={true} className='backdrop' open={uploadDocumentData.isVisible} onClose={handleCloseUploadDocumentModel}>
                    <Box className='modal-container' sx={{
                        maxWidth: 580,
                        p: 3,
                    }}>
                        <Typography variant="h6" component="h6" sx={{
                            color: 'primary.main',
                            mb: 2,
                        }}>
                            Document Upload
                        </Typography>

                        <Box sx={{
                            pb: 2,
                            overflow: 'auto',
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1,
                        }}>
                            <RadiusButton
                                variant='contained'
                                component='label'
                                htmlFor='documentFile'
                                color='secondary'
                            >
                                Select File
                            </RadiusButton>
                            <input
                                style={{ display: 'none' }}
                                type='file'
                                id='documentFile'
                                onChange={handleFileChange}
                                accept={acceptedMimes}
                            />
                            {
                                uploadDocumentData?.file?.name && <>
                                    <Typography variant='subtitle1' >{uploadDocumentData.file.name}</Typography>
                                    <img
                                        style={{ cursor: 'pointer' }}
                                        src={DELETE_ICON}
                                        onClick={handleFileRemove}
                                    />
                                </>
                            }
                        </Box>

                        <div className='actions-container' style={{ justifyContent: 'flex-end', gap: '20px' }}>
                            <RadiusButton
                                sx={{ textTransform: 'capitalize'}}
                                variant='outlined'
                                onClick={handleCloseUploadDocumentModel}
                                disabled={isUploading}
                            >
                                Cancel
                            </RadiusButton>
                            <LoadingButton
                                sx={{
                                    borderRadius: 40,
                                    textTransform: 'capitalize'
                                }}
                                disabled={uploadDocumentData?.file ? false : true}
                                variant='contained'
                                onClick={onConfirmUploadDocument}
                                loading={isUploading}
                            >
                                Upload
                            </LoadingButton>
                        </div>
                    </Box>
                </Modal>
            </Container>
        </MainContainer >
    );
}

import { useState, useContext, useEffect } from "react";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Box, Drawer, List, ListItem, ListItemButton, Typography, ListItemAvatar, Avatar } from "@mui/material"

import { logout } from "../../services/authService";
import storage from "../../config/storage";
import { ProjectContext } from "../../providers/projectProvider";
import { AuthContext } from "../../providers/auth";

import BRAND from '../../assets/images/logo.png';
import DASHBOARD_ICON from '../../assets/images/dashboard.svg';
import USER_MANAGEMENT_ICON from '../../assets/images/users-management.svg';
import MASTER_MANAGEMENT_ICON from '../../assets/images/master-management.svg';
import DOCUMENT_UPLOAD from '../../assets/images/document-upload.svg';
import UPDATE_TIME_ICON from '../../assets/images/update-time.svg';
import LOGOUT_ICON from '../../assets/images/logout.svg';
import CHANGE_PASSWORD_ICON from '../../assets/images/answer-key.svg';
import SCORE_ICON from '../../assets/images/raw-scores.svg';
import MAC_ID_ICON from '../../assets/images/it-support-blue.svg';

export const SidebarMenu = ({ isFloating, children }) => {
    const drawerWidth = 85;

    const params = useParams();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { projectDetails } = useContext(ProjectContext);
    const { user, setUser } = useContext(AuthContext);

    const [activeRoute, setActiveRoute] = useState('');

    useEffect(() => {
        if (projectDetails && projectDetails.projectName) {
            const sections = projectDetails.projectName.split(' ').filter((section) => section);
            const projectName = sections.map((section) => {
                return section[0].toUpperCase() + section.slice(1);
            }).join(' ');
            document.title = projectName;
        } else {
            document.title = 'HubbleHox';
        }
    }, [projectDetails]);

    useEffect(() => {
        if (!projectDetails) {
            const projectName = params?.projectName;
            if (!projectName) {
                navigate('/list');
            }
        }
    }, [projectDetails, navigate]);

    useEffect(() => {
        const segments = pathname.split('/');
        setActiveRoute(segments[segments.length - 1]);
    }, [pathname]);

    const handleLogout = async () => {
        await logout();
        setUser(null);
        const projectShortName = params?.projectName;
        if (projectShortName) {
            navigate(`/${projectShortName}/login`);
        }
    }

    const goToContentDashboard = () => {
        const projectShortName = params?.projectName;
        if (projectShortName) {
            navigate(`/${projectShortName}/content-dashboard`);
        }
    }
    const goToMastersManagement = () => {
        const projectShortName = params?.projectName;
        if (projectShortName) {
            navigate(`/${projectShortName}/masters`);
        }
    }
    const goToUserManagement = () => {
        const projectShortName = params?.projectName;
        if (projectShortName) {
            navigate(`/${projectShortName}/users`);
        }
    }
    const goToDocumentUpload = () => {
        const projectShortName = params?.projectName;
        if (projectShortName) {
            navigate(`/${projectShortName}/documents`);
        }
    }
    const goToUpdateTime = () => {
        const projectShortName = params?.projectName;
        if (projectShortName) {
            navigate(`/${projectShortName}/update-time`);
        }
    }
    const goToScoreManagement = () => {
        const projectShortName = params?.projectName;
        if (projectShortName) {
            navigate(`/${projectShortName}/score-management`);
        }
    }
    const goToCustomerDashboard = () => {
        const projectShortName = params?.projectName;
        if (projectShortName) {
            navigate(`/${projectShortName}/customer/dashboard`);
        }
    }
    const goToMacIds = () => {
        const projectShortName = params?.projectName;
        if (projectShortName) {
            navigate(`/${projectShortName}/mac-ids`);
        }
    }
    const goToChangePassword = () => {
        const projectShortName = params?.projectName;
        if (projectShortName) {
            navigate(`/${projectShortName}/change-password`);
        }
    }

    return (
        <Drawer
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: drawerWidth,
                    boxSizing: 'border-box',
                    border: 'unset',
                    bgcolor: isFloating ? 'background.default' : 'background.base'
                },
            }}
            variant="permanent"
            anchor="left"
        >
            <Box sx={{
                p: 1,
                height: 80,
                flexShrink: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                bgcolor: isFloating ? 'background.default' : 'background.base',
                borderBottom: '1px solid',
                borderColor: isFloating ? 'transparent' : 'grey.300'
            }}>
                {
                    projectDetails?.logo
                        // ? <img className='brand-image' src={`${process.env.REACT_APP_API_URL.replace('/api', '')}/${projectDetails?.logo}`} alt='' />
                        ? <img className='brand-image' src={`${projectDetails?.logo}`} alt='' />
                        : <img className='brand-image' src={BRAND} alt='' />
                }
            </Box>

            {
                !isFloating && <Box sx={{ overflow: 'auto', flexGrow: 1 }}>
                    {
                        user?.userType === 2 && <List>
                            <ListItem className='active' onClick={goToMastersManagement}>
                                <ListItemButton sx={{
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    flexGrow: 1,
                                    '&:hover': {
                                        bgcolor: 'unset',
                                        color: 'primary.main !important'
                                    },
                                    maxWidth: 56,
                                    padding: 0
                                }} disableRipple disableGutters>
                                    <ListItemAvatar sx={{ marginBottom: 0.5 }}>
                                        <Avatar sx={{
                                            color: 'inherit',
                                            width: 56,
                                            height: 32,
                                            borderRadius: 16,
                                            bgcolor: (activeRoute === 'masters' || activeRoute === 'master-details') ? 'primary.100' : 'transparent',
                                            '&:hover': {
                                                bgcolor: 'primary.100'
                                            }
                                        }}>
                                            <img src={MASTER_MANAGEMENT_ICON} alt='' />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <Typography sx={{ textAlign: 'center', fontSize: 12, fontWeight: 400, lineHeight: 1.2 }}>
                                        Masters Management
                                    </Typography>
                                </ListItemButton>
                            </ListItem>
                            <ListItem className='active' onClick={goToUserManagement}>
                                <ListItemButton sx={{
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    flexGrow: 1,
                                    '&:hover': {
                                        bgcolor: 'unset',
                                        color: 'primary.main !important'
                                    },
                                    maxWidth: 56,
                                    padding: 0
                                }} disableRipple disableGutters>
                                    <ListItemAvatar sx={{ marginBottom: 0.5 }}>
                                        <Avatar sx={{
                                            color: 'inherit',
                                            width: 56,
                                            height: 32,
                                            borderRadius: 16,
                                            bgcolor: (
                                                activeRoute === 'users'
                                                || activeRoute === 'user-details'
                                                || activeRoute === 'content'
                                                || activeRoute === 'content-details'
                                                || activeRoute === 'it-support'
                                                || activeRoute === 'it-support-details'
                                                || activeRoute === 'biometric'
                                                || activeRoute === 'biometric-details'
                                            ) ? 'primary.100' : 'transparent',
                                            '&:hover': {
                                                bgcolor: 'primary.100'
                                            }
                                        }}>
                                            <img src={USER_MANAGEMENT_ICON} alt='' />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <Typography sx={{ textAlign: 'center', fontSize: 12, fontWeight: 400, lineHeight: 1.2 }}>
                                        User Management
                                    </Typography>
                                </ListItemButton>
                            </ListItem>
                            <ListItem className='active' onClick={goToDocumentUpload}>
                                <ListItemButton sx={{
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    flexGrow: 1,
                                    '&:hover': {
                                        bgcolor: 'unset',
                                        color: 'primary.main !important'
                                    },
                                    maxWidth: 56,
                                    padding: 0
                                }} disableRipple disableGutters>
                                    <ListItemAvatar sx={{ marginBottom: 0.5 }}>
                                        <Avatar sx={{
                                            color: 'inherit',
                                            width: 56,
                                            height: 32,
                                            borderRadius: 16,
                                            bgcolor: activeRoute === 'documents' ? 'primary.100' : 'transparent',
                                            '&:hover': {
                                                bgcolor: 'primary.100'
                                            }
                                        }}>
                                            <img src={DOCUMENT_UPLOAD} alt='' />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <Typography sx={{ textAlign: 'center', fontSize: 12, fontWeight: 400, lineHeight: 1.2 }}>
                                        Document Upload
                                    </Typography>
                                </ListItemButton>
                            </ListItem>
                            <ListItem className='active' onClick={goToUpdateTime}>
                                <ListItemButton sx={{
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    flexGrow: 1,
                                    '&:hover': {
                                        bgcolor: 'unset',
                                        color: 'primary.main !important'
                                    },
                                    maxWidth: 56,
                                    padding: 0
                                }} disableRipple disableGutters>
                                    <ListItemAvatar sx={{ marginBottom: 0.5 }}>
                                        <Avatar sx={{
                                            color: 'inherit',
                                            width: 56,
                                            height: 32,
                                            borderRadius: 16,
                                            bgcolor: activeRoute === 'update-time' ? 'primary.100' : 'transparent',
                                            '&:hover': {
                                                bgcolor: 'primary.100'
                                            }
                                        }}>
                                            <img style={{ height: '25px' }} src={UPDATE_TIME_ICON} alt='' />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <Typography sx={{ textAlign: 'center', fontSize: 12, fontWeight: 400, lineHeight: 1.2 }}>
                                        Update Time
                                    </Typography>
                                </ListItemButton>
                            </ListItem>
                            <ListItem className='active' onClick={goToMacIds}>
                                <ListItemButton sx={{
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    flexGrow: 1,
                                    '&:hover': {
                                        bgcolor: 'unset',
                                        color: 'primary.main !important'
                                    },
                                    maxWidth: 56,
                                    padding: 0
                                }} disableRipple disableGutters>
                                    <ListItemAvatar sx={{ marginBottom: 0.5 }}>
                                        <Avatar sx={{
                                            color: 'inherit',
                                            width: 56,
                                            height: 32,
                                            borderRadius: 16,
                                            bgcolor: activeRoute === 'mac-ids' ? 'primary.100' : 'transparent',
                                            '&:hover': {
                                                bgcolor: 'primary.100'
                                            }
                                        }}>
                                            <img style={{ height: '25px' }} src={MAC_ID_ICON} alt='' />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <Typography sx={{ textAlign: 'center', fontSize: 12, fontWeight: 400, lineHeight: 1.2 }}>
                                        Mac IDs
                                    </Typography>
                                </ListItemButton>
                            </ListItem>
                        </List>
                    }
                    {
                        user?.userType === 3 && <List>
                            {user?.modules.includes(101)
                                && <ListItem className='active' onClick={goToContentDashboard}>
                                    <ListItemButton sx={{
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        flexGrow: 1,
                                        '&:hover': {
                                            bgcolor: 'unset',
                                            color: 'primary.main !important'
                                        },
                                        maxWidth: 56,
                                        padding: 0
                                    }} disableRipple disableGutters>
                                        <ListItemAvatar sx={{ marginBottom: 0.5 }}>
                                            <Avatar sx={{
                                                color: 'inherit',
                                                width: 56,
                                                height: 32,
                                                borderRadius: 16,
                                                bgcolor: activeRoute === 'content-dashboard' ? 'primary.100' : 'transparent',
                                                '&:hover': {
                                                    bgcolor: 'primary.100'
                                                }
                                            }}>
                                                <img src={DASHBOARD_ICON} alt='' />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <Typography sx={{ textAlign: 'center', fontSize: 12, fontWeight: 400, lineHeight: 1.2 }}>
                                            Content Dashboard
                                        </Typography>
                                    </ListItemButton>
                                </ListItem>}
                            {user?.modules.includes(102)
                                && <ListItem className='active' onClick={goToMacIds}>
                                    <ListItemButton sx={{
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        flexGrow: 1,
                                        '&:hover': {
                                            bgcolor: 'unset',
                                            color: 'primary.main !important'
                                        },
                                        maxWidth: 56,
                                        padding: 0
                                    }} disableRipple disableGutters>
                                        <ListItemAvatar sx={{ marginBottom: 0.5 }}>
                                            <Avatar sx={{
                                                color: 'inherit',
                                                width: 56,
                                                height: 32,
                                                borderRadius: 16,
                                                bgcolor: activeRoute === 'mac-ids' ? 'primary.100' : 'transparent',
                                                '&:hover': {
                                                    bgcolor: 'primary.100'
                                                }
                                            }}>
                                                <img style={{ height: '25px' }} src={MAC_ID_ICON} alt='' />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <Typography sx={{ textAlign: 'center', fontSize: 12, fontWeight: 400, lineHeight: 1.2 }}>
                                            Mac IDs
                                        </Typography>
                                    </ListItemButton>
                                </ListItem>}
                            {user?.modules.includes(103)
                                && <ListItem className='active' onClick={goToScoreManagement}>
                                    <ListItemButton sx={{
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        flexGrow: 1,
                                        '&:hover': {
                                            bgcolor: 'unset',
                                            color: 'primary.main !important'
                                        },
                                        maxWidth: 56,
                                        padding: 0
                                    }} disableRipple disableGutters>
                                        <ListItemAvatar sx={{ marginBottom: 0.5 }}>
                                            <Avatar sx={{
                                                color: 'inherit',
                                                width: 56,
                                                height: 32,
                                                borderRadius: 16,
                                                bgcolor: activeRoute === 'score-management' ? 'primary.100' : 'transparent',
                                                '&:hover': {
                                                    bgcolor: 'primary.100'
                                                }
                                            }}>
                                                <img src={SCORE_ICON} alt='' />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <Typography sx={{ textAlign: 'center', fontSize: 12, fontWeight: 400, lineHeight: 1.2 }}>
                                            Score Management
                                        </Typography>
                                    </ListItemButton>
                                </ListItem>}
                            {user?.modules.includes(104)
                                && <ListItem className='active' onClick={goToCustomerDashboard}>
                                    <ListItemButton sx={{
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        flexGrow: 1,
                                        '&:hover': {
                                            bgcolor: 'unset',
                                            color: 'primary.main !important'
                                        },
                                        maxWidth: 56,
                                        padding: 0
                                    }} disableRipple disableGutters>
                                        <ListItemAvatar sx={{ marginBottom: 0.5 }}>
                                            <Avatar sx={{
                                                color: 'inherit',
                                                width: 56,
                                                height: 32,
                                                borderRadius: 16,
                                                bgcolor: (activeRoute === 'dashboard' || activeRoute === 'mock-exam' || activeRoute === 'live-exam') ? 'primary.100' : 'transparent',
                                                '&:hover': {
                                                    bgcolor: 'primary.100'
                                                }
                                            }}>
                                                <img src={DASHBOARD_ICON} alt='' />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <Typography sx={{ textAlign: 'center', fontSize: 12, fontWeight: 400, lineHeight: 1.2 }}>
                                            Dashboard
                                        </Typography>
                                    </ListItemButton>
                                </ListItem>
                            }
                        </List>
                    }
                    {
                        user?.userType === 4 && <List>
                            <ListItem className='active' onClick={goToContentDashboard}>
                                <ListItemButton sx={{
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    flexGrow: 1,
                                    '&:hover': {
                                        bgcolor: 'unset',
                                        color: 'primary.main !important'
                                    },
                                    maxWidth: 56,
                                    padding: 0
                                }} disableRipple disableGutters>
                                    <ListItemAvatar sx={{ marginBottom: 0.5 }}>
                                        <Avatar sx={{
                                            color: 'inherit',
                                            width: 56,
                                            height: 32,
                                            borderRadius: 16,
                                            bgcolor: activeRoute === 'content-dashboard' ? 'primary.100' : 'transparent',
                                            '&:hover': {
                                                bgcolor: 'primary.100'
                                            }
                                        }}>
                                            <img src={DASHBOARD_ICON} alt='' />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <Typography sx={{ textAlign: 'center', fontSize: 12, fontWeight: 400, lineHeight: 1.2 }}>
                                        Content Dashboard
                                    </Typography>
                                </ListItemButton>
                            </ListItem>
                        </List>
                    }
                </Box>
            }
            {
                !isFloating && <List>
                    <ListItem className='active' onClick={goToChangePassword}>
                        <ListItemButton sx={{
                            flexDirection: 'column',
                            justifyContent: 'center',
                            flexGrow: 1,
                            '&:hover': {
                                bgcolor: 'unset',
                                color: 'primary.main'
                            },
                            maxWidth: 56,
                            padding: 0
                        }} disableRipple disableGutters>
                            <ListItemAvatar sx={{ marginBottom: 0.5 }}>
                                <Avatar sx={{
                                    color: 'inherit',
                                    width: 56,
                                    height: 32,
                                    borderRadius: 16,
                                    bgcolor: activeRoute === 'change-password' ? 'primary.100' : 'transparent',
                                    '&:hover': {
                                        bgcolor: 'primary.100'
                                    }
                                }}>
                                    <img src={CHANGE_PASSWORD_ICON} alt='' />
                                </Avatar>
                            </ListItemAvatar>
                            <Typography sx={{
                                textAlign: 'center',
                                fontSize: 12,
                                fontWeight: 400,
                                lineHeight: 1.2,
                            }}>
                                Change Password
                            </Typography>
                        </ListItemButton>
                    </ListItem>
                    <ListItem sx={{
                        pb: 3
                    }} className='active' onClick={handleLogout}>
                        <ListItemButton sx={{
                            flexDirection: 'column',
                            justifyContent: 'center',
                            flexGrow: 1,
                            '&:hover': {
                                bgcolor: 'unset',
                                color: 'primary.main'
                            },
                            maxWidth: 56,
                            padding: 0
                        }} disableRipple disableGutters>
                            <ListItemAvatar sx={{ marginBottom: 0.5 }}>
                                <Avatar sx={{
                                    color: 'inherit',
                                    width: 56,
                                    height: 32,
                                    borderRadius: 16,
                                    bgcolor: 'transparent',
                                    '&:hover': {
                                        bgcolor: 'primary.100'
                                    }
                                }}>
                                    <img src={LOGOUT_ICON} alt='' />
                                </Avatar>
                            </ListItemAvatar>
                            <Typography sx={{
                                textAlign: 'center',
                                fontSize: 12,
                                fontWeight: 400,
                                lineHeight: 1.2,
                            }}>
                                Logout
                            </Typography>
                        </ListItemButton>
                    </ListItem>
                </List>
            }


        </Drawer>
    )
}

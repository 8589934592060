import { Fragment, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Typography, Box, Table, TableHead, TableRow, TableCell, TableBody, TableContainer, Link, Autocomplete, TextField } from '@mui/material';
import { useToaster } from '../../../../providers/ToasterProvider';

import { addFeedbackQuestions, deleteFeedbackQuestoinsByLanguage, getFeedbackQuestions } from '../../../../services/configurationService';
import { createFromFile, deleteAll, getAllByConfigurationId, getDataForFeedbackDashboard } from '../../../../services/feedbackQuestionService';
import { ContentUploadErrorsModal } from '../ContentUploadErrorsModal/ContentUploadErrorsModal'

import DELETE from '../../../../assets/images/action-delete.svg';
import RadiusButton from '../../../../components/RadiusButton';
import { LoadingButton } from '@mui/lab';


export const FeedbackUpload = ({ drive, course, configurationId }) => {

    const params = useParams();
    const projectName = params?.projectName;
    const navigate = useNavigate();
    const [numberOfFeedbackQuestions, setNumberOfFeedbackQuestions] = useState(null);
    const [fileDetails, setFileDetails] = useState({
        file: null,
        language: null
    });
    const [errorModal, setErrorModal] = useState({
        isVisible: false,
        errors: []
    });
    const [languages, setLanguages] = useState([]);
    const [recordsUpdated, setRecordsUpdated] = useState(false);
    const { addNotification } = useToaster();
    const [isUploading, setIsUploading] = useState(false);

    useEffect(() => {
        if (configurationId) {
            const fetchDetails = async () => {
                try {
                    const body = {
                        configurationId: configurationId,
                        imageSize: 'L',
                    }
                    const res = await getDataForFeedbackDashboard(configurationId);
                    if (res.code === 'OK') {
                        setNumberOfFeedbackQuestions(res.data.numberOfFeedbackQuestions);
                        setLanguages(res.data.languageGroups.map((lan) => {
                            lan.count = Number(lan.count);
                            return lan;
                        }));
                    }
                } catch (error) {
                    console.log('There is an error while getting feedback questions.', error);
                }
            }
            fetchDetails();
        }
    }, [recordsUpdated]);

    const handleFileDrop = (e, language) => {
        const inputFile = e.target.files?.[0];
        if (fileDetails?.file && fileDetails.language) {
            const ele = document.getElementById(`file_${fileDetails.language}`);
            if (ele) {
                ele.value = '';
            }
        }
        if (inputFile && inputFile.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
            setFileDetails({
                file: inputFile,
                language: language,
            })
        }
    }
    const handleRemoveFile = () => {
        const language = fileDetails.language;
        if (language) {
            document.getElementById(`file_${language}`).value = '';
            setFileDetails({
                file: null,
                language: null
            });
        }
    }
    const handleFeedbackUpload = async () => {
        if (fileDetails?.file && fileDetails?.language && configurationId) {
            setIsUploading(true);
            try {
                const body = new FormData();
                body.append('configurationId', configurationId);
                body.append('language', fileDetails.language);
                body.append('file', fileDetails.file);
                const res = await createFromFile(body);

                if (res.code === 'E_BAD_REQUEST') {
                    addNotification('Invalid data in file data', 'danger');
                    setErrorModal({
                        ...errorModal,
                        isVisible: true,
                        errors: res.data.errors,
                    });
                } else {
                    setRecordsUpdated(!recordsUpdated);
                    addNotification('Questions uploaded successfully.', 'success');
                    document.getElementById(`file_${fileDetails.language}`).value = '';
                    setFileDetails({
                        file: null,
                        language: null
                    });
                }

            } catch (error) {
                const code = error?.response?.data?.code;
                if (code === 'WORD_FILE_NO_MATCHING_FIELDS') {
                    addNotification('file has no matching fields.', 'danger');
                } else if (code === 'WORD_FILE_MISSING_FIELDS') {
                    addNotification('file has no data.', 'danger');
                } else {
                    addNotification('File does not uploaded', 'danger');
                }
                console.log('There is an error while uploading file set wise', error);
            } finally {
                setIsUploading(false);
            }
        }
    }
    const handlePreview = (index) => {
        const language = languages[index];
        if (language && projectName) {
            navigate(`/${projectName}/upload/feedback-preview`, {
                state: {
                    drive: drive,
                    course: course,
                    configurationId: configurationId,
                    questionNo: 1,
                    maxQuestions: language.count,
                    language: language.language
                }
            });
        }
    }
    const handleDeleteAll = async (language) => {
        if (configurationId && language) {
            try {
                const res = await deleteAll({
                    configurationId: configurationId,
                    language: language
                });
                if (res.code === 'OK') {
                    setRecordsUpdated(!recordsUpdated);
                    addNotification('Feedback questions deleted successfully.', 'success');
                }
            } catch (error) {
                console.log('There is an error while deleteing feedback questions', error);
            }
        }
    }

    return (
        <Fragment>
            <TableContainer sx={{
                p: 2,
                maxHeight: 'inherit',
                maxWidth: 'inherit',
                borderRadius: 1
            }}>
                <Table stickyHeader aria-label="table">
                    <TableHead>
                        <TableRow>
                            <TableCell
                                align='left'
                                style={{ width: 200 }}
                                sx={{ border: 'none', p: 0 }}
                            ></TableCell>
                            <TableCell
                                align='left'
                                style={{ minWidth: 320 }}
                                sx={{ border: 'none', p: 0 }}
                            ></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {languages?.map((lan, index) => {
                            return <TableRow key={index}>
                                <TableCell sx={{ border: 'none', verticalAlign: 'top' }}>
                                    <Typography
                                        component='span'
                                        variant='subtitle1'
                                        sx={{ textTransform: 'capitalize' }}
                                    >{lan?.language}</Typography>
                                </TableCell>
                                <TableCell sx={{
                                    border: 'none',
                                    flex: 1,
                                    p: 0,
                                }}>
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'flex-start',
                                        flexDirection: 'column',
                                        gap: 1.5,
                                        py: 1.5,
                                        backgroundColor: 'grey.100',
                                        px: 2.5,
                                        borderRadius: 2,
                                        mb: 1
                                    }}>
                                        <Typography
                                            variant='subtitle1'
                                            component='h6'
                                        >
                                            Uploaded ({lan.count} / {numberOfFeedbackQuestions})
                                        </Typography>
                                        {lan.count == 0 && (
                                            <Box sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: 1,
                                                height: 24,

                                            }}>
                                                <Link
                                                    sx={{
                                                        fontSize: 14,
                                                        fontWeight: 500,
                                                        cursor: 'pointer',
                                                    }}
                                                    component='label'
                                                    htmlFor={`file_${lan.language}`}
                                                >Select file</Link>
                                                <input
                                                    style={{ display: 'none' }}
                                                    type='file'
                                                    id={`file_${lan.language}`}
                                                    onChange={(e) => handleFileDrop(e, lan.language)}
                                                    accept='application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                                                />
                                                {
                                                    fileDetails?.language === lan.language && fileDetails?.file && fileDetails?.file?.name
                                                        ? (
                                                            <>
                                                                <Box component='span'>{fileDetails.file.name}</Box>
                                                                <img
                                                                    style={{ cursor: 'pointer' }}
                                                                    src={DELETE}
                                                                    onClick={handleRemoveFile}
                                                                    alt=''
                                                                />
                                                            </>
                                                        ) : null
                                                }
                                            </Box>
                                        )}
                                        <Box sx={{
                                            display: 'flex',
                                            gap: 1,
                                            alignItems: 'center',
                                        }}>

                                            {fileDetails?.language === lan.language && fileDetails?.file && <LoadingButton
                                                sx={{ borderRadius: 40, textTransform: 'capitalize' }}
                                                variant='contained'
                                                color='primary'
                                                onClick={handleFeedbackUpload}
                                                loading={isUploading}
                                            >Upload</LoadingButton>}
                                            {lan?.count > 0 && <>
                                                <RadiusButton
                                                    variant='contained'
                                                    color='primary'
                                                    onClick={() => handlePreview(index)}
                                                >Preview</RadiusButton>
                                            </>}
                                            {lan?.count > 0 && <RadiusButton
                                                variant='contained'
                                                color='secondary'
                                                onClick={() => handleDeleteAll(lan.language)}
                                            >Delete All</RadiusButton>}
                                        </Box>
                                    </Box>
                                </TableCell>
                            </TableRow>
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <ContentUploadErrorsModal currentState={errorModal} setCurrentState={setErrorModal}></ContentUploadErrorsModal>
        </Fragment>
    );
}
import Axios from "axios";

import storage from './storage';
import dayjs from "../config/dayjs";

async function authRequestInterceptor(config) {
  let token = storage.getToken();
  config.headers.Accept = 'application/json';

  if (config.url.startsWith('/project/auth/refresh-tokens')) {
    return config;
  }
  if (!token || !token?.accessToken || !token?.exp) {
    return config;
  }

  const expiresAt = dayjs.utc(token.exp * 1000);
  if (dayjs.utc().isAfter(expiresAt)) {
    token = await getRefreshedToken();
  }
  if (!token) {
    return config;
  }
  config.headers.authorization = `${token.accessToken}`;
  return config;
}

export const axios = Axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

axios.interceptors.request.use(authRequestInterceptor);
axios.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    const code = error?.response?.data?.code;

    if (code === "E_UNAUTHORIZED") {
      const userDetails = storage.getUserDetails();
      if (userDetails?.userType !== 1) {
        const projectShortName = window.location.pathname.split('/')[1] ?? '';
        if (projectShortName) {
          window.location.assign(`${window.location.origin}/${projectShortName}/login`);
          storage.clearToken();
          return;
        }
      }

      storage.clearToken();
      window.location.assign(`${window.location.origin}/login`);
    }

    return Promise.reject(error);
  }
);

async function getRefreshedToken() {
  try {
    const token = storage.getRefreshToken();
    if (token?.refreshToken) {
      const response = await axios.post('/project/auth/refresh-tokens', { refreshToken: token.refreshToken });
      if (response.code === 'OK') {
        storage.setToken(response.data.token.accessToken);
        storage.setRefreshToken(response.data.refreshToken.refreshToken);
        return response.data.token;
      }
    }
    return null;
  } catch (error) {
    console.log('There is an error while refreshing token', error);
    return null;
  }
}

import { createContext, useEffect, useState } from "react";
import { getDetailsByEncryptedShortName } from "../services/projectService";

import storage from '../config/storage';
import { useParams } from "react-router-dom";

export const ProjectContext = createContext();

const ProjectContextProvider = ({ children }) => {
    const params = useParams();
    const [projectDetails, setProjectDetails] = useState(null);

    const setCurrentProjectDetails = async (projectShortName) => {
        try {
            if (projectShortName === null) {
                setProjectDetails(null);
                storage.clearProjectShortName();
                return;
            }
            const response = await getDetailsByEncryptedShortName(projectShortName);
            if (!response?.data?.id) {
                setProjectDetails(null);
                storage.clearProjectShortName();
                return;
            }
            setProjectDetails(response.data);
            storage.setProjectShortName(response.data.projectShortName)
        } catch (error) {
            console.log('There is an error while getting project details by short name', error);
            setProjectDetails(null);
            storage.clearProjectShortName();
        }
    }

    useEffect(() => {
        const projectEncryptedName = params?.projectName;
        if (projectEncryptedName){
            async function fetchData() {
                await setCurrentProjectDetails(projectEncryptedName);
            }
            fetchData();
        }
    }, []);

    return (
        <ProjectContext.Provider value={{ projectDetails, setCurrentProjectDetails }}>
            {children}
        </ProjectContext.Provider>
    );

}

export default ProjectContextProvider;
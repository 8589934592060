import { useEffect, useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import {
    ClassicEditor, AccessibilityHelp, Alignment, Autoformat, AutoLink, Autosave, BlockQuote, Bold, Code, CodeBlock, Essentials, FindAndReplace, FontBackgroundColor, FontColor, FontFamily, FontSize, GeneralHtmlSupport, Heading, Highlight, HorizontalLine, Indent, IndentBlock, Italic, Link, PageBreak, Paragraph, RemoveFormat, SelectAll, SpecialCharacters, SpecialCharactersArrows, SpecialCharactersCurrency, SpecialCharactersEssentials, SpecialCharactersLatin, SpecialCharactersMathematical, SpecialCharactersText, Strikethrough, Style, Subscript, Superscript, Table, TableCaption, TableCellProperties, TableColumnResize, TableProperties, TableToolbar, TextTransformation, Underline, Undo,
    List
} from 'ckeditor5';

import { Box, Tabs, Tab } from '@mui/material';

import { useToaster } from '../../../../providers/ToasterProvider';
import { getById, updateById } from '../../../../services/configurationService';
import RadiusButton from '../../../../components/RadiusButton';

import './InstructionsUpload.scss';

export const InstructionsUpload = ({ configurationId }) => {

    const [tabIndex, setTabIndex] = useState(0);
    const [configuration, setConfiguration] = useState(null);
    const [initInstruction, setInitInstruction] = useState(null);
    const [instruction, setInstruction] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const [editor, setEditor] = useState(null);
    const { addNotification } = useToaster();

    let editorConfig = {
        toolbar: {
            items: [
                'undo', 'redo', '|', 'findAndReplace', 'selectAll', '|', 'heading', '|', 'fontSize', 'fontFamily', 'fontColor', 'fontBackgroundColor', '|', 'bold', 'italic', 'underline', 'strikethrough', 'subscript', 'superscript', 'code', 'removeFormat', '|', 'numberedList', 'bulletedList', 'indent', 'outdent', '|', 'horizontalLine', 'pageBreak', 'link', 'insertTable', 'highlight', 'blockQuote', '|', 'alignment', '|', 'accessibilityHelp'
            ],
            shouldNotGroupWhenFull: true
        },
        plugins: [AccessibilityHelp, Alignment, Autoformat, AutoLink, Autosave, BlockQuote, Bold, Code, CodeBlock, Essentials, FindAndReplace, FontBackgroundColor, FontColor, FontFamily, FontSize, GeneralHtmlSupport, Heading, Highlight, HorizontalLine, Indent, IndentBlock, List, Italic, Link, PageBreak, Paragraph, RemoveFormat, SelectAll, SpecialCharacters, SpecialCharactersArrows, SpecialCharactersCurrency, SpecialCharactersEssentials, SpecialCharactersLatin, SpecialCharactersMathematical, SpecialCharactersText, Strikethrough, Style, Subscript, Superscript, Table, TableCaption, TableCellProperties, TableColumnResize, TableProperties, TableToolbar, TextTransformation, Underline, Undo
        ],
        fontFamily: {
            supportAllValues: true
        },
        fontSize: {
            options: [10, 12, 14, 'default', 18, 20, 22],
            supportAllValues: true
        },
        heading: {
            options: [
                {
                    model: 'paragraph',
                    title: 'Paragraph',
                    class: 'ck-heading_paragraph'
                },
                {
                    model: 'heading1',
                    view: 'h1',
                    title: 'Heading 1',
                    class: 'ck-heading_heading1'
                },
                {
                    model: 'heading2',
                    view: 'h2',
                    title: 'Heading 2',
                    class: 'ck-heading_heading2'
                },
                {
                    model: 'heading3',
                    view: 'h3',
                    title: 'Heading 3',
                    class: 'ck-heading_heading3'
                },
                {
                    model: 'heading4',
                    view: 'h4',
                    title: 'Heading 4',
                    class: 'ck-heading_heading4'
                },
                {
                    model: 'heading5',
                    view: 'h5',
                    title: 'Heading 5',
                    class: 'ck-heading_heading5'
                },
                {
                    model: 'heading6',
                    view: 'h6',
                    title: 'Heading 6',
                    class: 'ck-heading_heading6'
                }
            ]
        },
        htmlSupport: {
            allow: [
                {
                    name: /^.*$/,
                    styles: true,
                    attributes: true,
                    classes: true
                }
            ]
        },
        initialData: null,
        link: {
            addTargetToExternalLinks: true,
            defaultProtocol: 'https://',
            decorators: {
                toggleDownloadable: {
                    mode: 'manual',
                    label: 'Downloadable',
                    attributes: {
                        download: 'file'
                    }
                }
            }
        },
        placeholder: 'Enter instructions here.',
        style: {
            definitions: [
                {
                    name: 'Article category',
                    element: 'h3',
                    classes: ['category']
                },
                {
                    name: 'Title',
                    element: 'h2',
                    classes: ['document-title']
                },
                {
                    name: 'Subtitle',
                    element: 'h3',
                    classes: ['document-subtitle']
                },
                {
                    name: 'Info box',
                    element: 'p',
                    classes: ['info-box']
                },
                {
                    name: 'Side quote',
                    element: 'blockquote',
                    classes: ['side-quote']
                },
                {
                    name: 'Marker',
                    element: 'span',
                    classes: ['marker']
                },
                {
                    name: 'Spoiler',
                    element: 'span',
                    classes: ['spoiler']
                },
                {
                    name: 'Code (dark)',
                    element: 'pre',
                    classes: ['fancy-code', 'fancy-code-dark']
                },
                {
                    name: 'Code (bright)',
                    element: 'pre',
                    classes: ['fancy-code', 'fancy-code-bright']
                }
            ]
        },
        table: {
            contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells', 'tableProperties', 'tableCellProperties']
        }
    };

    useEffect(() => {
        if (configurationId) {
            const fetchConfiguration = async () => {
                try {
                    const res = await getById(configurationId);
                    if (res.code === 'OK') {
                        setConfiguration(res.data);
                        const firstLanguage = res.data.languages[0];
                        const instructionIndex = res.data.instructions.findIndex((i) => i.language === firstLanguage);
                        if (instructionIndex > -1) {
                            setInitInstruction(res.data.instructions[instructionIndex].value);
                        }
                        setLoaded(true);
                    }
                } catch (error) {
                    console.log('There is an error while fetching configuration', error);
                }
            }
            fetchConfiguration();
        }
    }, []);

    const handleChange = (e, value) => {
        setTabIndex(value);
        const language = configuration.languages[value];
        const instructionIndex = configuration.instructions.findIndex((i) => i.language === language);
        if (instructionIndex > -1) {
            editor.setData(configuration.instructions[instructionIndex].value)
        } else {
            editor.setData('');
        }
    }

    const handleEditorChange = (event, editor) => {
        const data = editor.getData();
        setInstruction(data);
    }
    const handleOnReady = (ed) => {
        setEditor(ed);
        if (initInstruction) {
            ed.setData(initInstruction);
        }
    }

    const handleSave = async () => {
        if (!instruction) {
            addNotification('Please enter some content before saving instruction.', 'danger');
        }
        try {
            const language = configuration.languages[tabIndex];
            let instructions = configuration.instructions ?? [];
            const instructionIndex = instructions.findIndex((i) => i.language === language);
            if (instructionIndex === -1) {
                instructions.push({
                    language: language,
                    value: instruction,
                });
            } else {
                instructions[instructionIndex].value = instruction;
            }
            const res = await updateById(configuration.id, { instructions });
            if (res.code === 'OK') {
                addNotification('Instruction saved successfully', 'success');
                setConfiguration({
                    ...configuration,
                    instructions: instructions
                });
            }
        } catch (error) {
            console.log('There is an error while uploading instruction', error);
        }
        console.log(configuration);
    }

    return (
        <Box>
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 2,
                borderBottom: 1,
                borderColor: 'divider'
            }}>
                <Tabs variant='scrollable' value={tabIndex} onChange={handleChange}>
                    {configuration?.languages.map((language) => {
                        return <Tab
                            key={language}
                            sx={{
                                textTransform: 'capitalize',
                                fontSize: 15,
                                fontWeight: 600
                            }}
                            label={language}
                            id={'tabpanel' + language}
                            aria-controls={'tabpanel' + language}
                        />
                    })}
                </Tabs>
                <RadiusButton
                    variant='contained'
                    color='secondary'
                    sx={{ ml: 'auto', mr: 2 }}
                    onClick={handleSave}
                >
                    Save
                </RadiusButton>
            </Box>
            <Box
                role="tabpanel"
                id='tabpanel'
                sx={{ overflow: 'auto' }}
            >
                {loaded && <CKEditor
                    style={{ overflow: 'auto', maxHeight: '200px' }}
                    editor={ClassicEditor}
                    config={editorConfig}
                    onReady={handleOnReady}
                    onChange={handleEditorChange}
                />}
            </Box>
        </Box>
    );
}
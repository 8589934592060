import { Breadcrumbs, Typography, Box } from '@mui/material';
import { MainContainer, Container } from '../../../../components';
import { Menu } from '../Menu/Menu';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import RadiusButton from '../../../../components/RadiusButton';

import ARROW_OPEN from '../../../../assets/images/breadcrumb-arrow-open.svg';
import CONTENT_ICON from '../../../../assets/images/content-blue.svg';
import MOCK_EXAM from '../../../../assets/images/mock-exam.svg';
import LIVE_EXAM from '../../../../assets/images/live-exam.svg';


export const CustomerChart = () => {

    const options = {
        chart: {
            type: 'column',
            height: '500px',
            maxPointWidth: 10
        },
        legend: {
            enabled: false
        },
        credits: {
            enabled: false
        },
        // title: {
        //     text: 'Corn vs wheat estimated production for 2020',
        //     align: 'left'
        // },
        // subtitle: {
        //     text:
        //         'Source: <a target="_blank" ' +
        //         'href="https://www.indexmundi.com/agriculture/?commodity=corn">indexmundi</a>',
        //     align: 'left'
        // },

        yAxis: {
            title: {
                text: ''
            },
            min: 0,
            // max: 100,
            margin: 20,
            tickLength: 10,
            tickInterval: 10,
        },
        xAxis: {
            categories: ['Content Configured', 'Content Uploaded', 'Content Locked', 'Mock Test', 'Exam Status', 'Exam Data Uploded', 'Score Generated']
        },
        // tooltip: {
        //     valueSuffix: ' (1000 MT)'
        // },
        plotOptions: {
            column: {
                // pointPadding: 0.2,
                // borderWidth: 0
                step: 10,
                pointWidth: 24
            }
        },
        series: [
            {
                data: [58, 150, 20,58, 150, 20, 10]
            }
        ]
    }



    return (
        <MainContainer >
            <Menu>
                <Breadcrumbs aria-label="breadcrumb" separator='' sx={{ gap: 12 }}>
                    <Typography sx={{
                        fontWeight: 500,
                        color: 'text.primary'
                    }}>
                        Dashboard
                    </Typography>
                    <Box sx={{ display: 'flex', alignContent: 'center' }}>
                        <img src={ARROW_OPEN} alt='' ></img>
                    </Box>
                </Breadcrumbs>
            </Menu>

            <Container sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column'
            }}>

                <div className="search-header-wrapper">
                    <h1 className="title" style={{ marginRight: 'auto' }}>
                        Dashboard
                    </h1>
                    <RadiusButton sx={{
                        fontWeight: 500,
                    }} color='primary'
                        variant='outlined'
                        size='large'
                        startIcon={<img src={CONTENT_ICON} />}
                    >
                        Content
                    </RadiusButton>
                    <RadiusButton sx={{
                        fontWeight: 500,
                    }} color='primary'
                        variant='outlined'
                        size='large'
                        startIcon={<img src={MOCK_EXAM} />}
                    >
                        Mock Exam
                    </RadiusButton>
                    <RadiusButton sx={{
                        fontWeight: 500,
                    }} color='primary'
                        variant='outlined'
                        size='large'
                        startIcon={<img src={LIVE_EXAM} />}
                    >
                        LIve Exam
                    </RadiusButton>
                </div>

                <HighchartsReact
                    highcharts={Highcharts}
                    options={options}
                />

            </Container>

        </MainContainer>
    );
}

import { Projects, ProjectDetails, SuperAdminLayout, SuperAdminLogin, EncodeDecode } from "../Modules/SuperAdmin";
import SuperAdminAuthGuard from "./SuperAdminAuthGuard";

export const SuperAdminRoutes = [
    {
        path: '/',
        element: <SuperAdminLayout />,
        children: [
            {
                path: 'login',
                element: <SuperAdminLogin />
            },
            {
                path: 'projects',
                element: <SuperAdminAuthGuard component={<Projects />} />,
            },
            {
                path: 'encode-decode',
                element: <EncodeDecode />,
            },
            {
                path: 'project-details',
                element: <SuperAdminAuthGuard component={<ProjectDetails />} />,
            }
        ]
    }
];

import Box from "@mui/material/Box"

export const MainContainer = ({ children }) => {
    return (
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                height: '100vh',
                bgcolor: 'background.default'
            }}
        >
            {children}
        </Box>
    );
}
import { Fragment, useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { ProjectContext } from "../providers/projectProvider";
import { AuthContext } from "../providers/auth";


const AdminAuthGuard = ({ allowedTypes = [], allowedModules = [], component }) => {
    const { user } = useContext(AuthContext);
    const { projectDetails } = useContext(ProjectContext);
    const navigate = useNavigate();
    const params = useParams();

    useEffect(() => {
        const projectName = params?.projectName ? params.projectName : 'list';
        if (!user || !allowedTypes.includes(user?.userType)) {
            if (projectName) {
                navigate(`/${projectName}/login`)
            } else {
                navigate('/list');
            }
        }

        if (user.userType === 3) {
            const modules = user?.modules ?? [];
            const isAllowed = modules.some(item => allowedModules.includes(item));
            if (!isAllowed) {
                if (projectName) {
                    navigate(`/${projectName}/login`)
                } else {
                    navigate('/list');
                }
            }
        }

    }, [user, navigate, projectDetails]);

    return (
        <Fragment>{component}</Fragment>
    );
}

export default AdminAuthGuard;
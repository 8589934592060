import { ThemeProvider, createTheme } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { BrowserRouter as Router } from "react-router-dom";

import AuthContextProvider from './auth';
import { ToasterProvider } from "./ToasterProvider";

export const AppProvider = ({ children }) => {

    const theme = createTheme({
        palette: {
            mode: 'light',
            common: {
                black: '#212121',
            },
            primary: {
                50: '#CFD5F1',
                100: '#c9caf6',
                200: '#a4a7ef',
                300: '#7e84e9',
                400: '#6167e4',
                500: '#444adc',
                600: '#3F41D1',
                700: '#3636c4',
                800: '#2d2ab9',
                900: '#1d0ea7',
                main: '#3F41D1',
                contrastText: '#FFFFFF'
            },
            secondary: {
                main: '#E697AB',
                contrastText: '#310000'
            },
            grey: {
                main: "#FFFFFF",
            },
            success: {
                100: '#bcfde0',
                main: '#06C270'
            },
            warning: {
                100: '#fff8b9',
                main: '#ffeb3b'
            },
            error: {
                100: '#f7c1c3',
                main: '#E6393E'
            },
            background: {
                base: '#FFFFFF',
                default: '#F7F7F7'
            },
            text: {
                primary: '#212121'
            }
        },
        typography: {
            fontFamily: [
                '"Plus Jakarta Sans"',
                'Arial',
                'sans-serif',
            ].join(','),
        },
    });

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <AuthContextProvider>
                <ThemeProvider theme={theme}>
                    <ToasterProvider>
                        <Router>{children}</Router>
                    </ToasterProvider>
                </ThemeProvider>
            </AuthContextProvider>
        </LocalizationProvider>
    );
}

// todo: implement loader in project
// <Suspense fallback={
//     <div className="loader">Loader</div>
// }>
// </Suspense>
import { useEffect } from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { Outlet, useLocation } from 'react-router-dom';

import './SuperAdminLayout.scss';
import { Toaster } from '../../../../components';

export const SuperAdminLayout = () => {

    const location = useLocation();
    useEffect(() => {
        if (location.pathname === '/') {
            window.location.href = '/login';
        }
    }, [location]);

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />

            {/* <SidebarMenu isFloating={isFloating}>
                {
                    !isFloating && (
                        <>
                            <Box sx={{ overflow: 'auto', flexGrow: 1 }}>
                                <List>
                                    {
                                        ['1', '2', '3', 'df'].map((item) => (
                                            <ListItem className='active' key={item}>
                                                <ListItemButton sx={{
                                                    flexDirection: 'column',
                                                    justifyContent: 'center',
                                                    flexGrow: 1,
                                                    '&:hover': {
                                                        bgcolor: 'unset',
                                                        color: 'primary.main'
                                                    },
                                                    maxWidth: 56,
                                                    padding: 0
                                                }} disableRipple disableGutters>
                                                    <ListItemAvatar sx={{ marginBottom: 0.5 }}>
                                                        <Avatar sx={{
                                                            color: 'inherit',
                                                            width: 56,
                                                            height: 32,
                                                            borderRadius: 16,
                                                            bgcolor: 'transparent',
                                                            '&:hover': {
                                                                bgcolor: 'primary.100'
                                                            }
                                                        }}>
                                                        </Avatar>
                                                    </ListItemAvatar>
                                                    <Typography sx={{ textAlign: 'center', fontSize: 12, fontWeight: 400, lineHeight: 1.2 }}>
                                                        First menu
                                                    </Typography>
                                                </ListItemButton>
                                            </ListItem>

                                        ))
                                    }
                                </List>
                            </Box>
                            <ListItem sx={{
                                pb: 3
                            }} className='active'>
                                <ListItemButton sx={{
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    flexGrow: 1,
                                    '&:hover': {
                                        bgcolor: 'unset',
                                        color: 'primary.main'
                                    },
                                    maxWidth: 56,
                                    padding: 0
                                }} disableRipple disableGutters>
                                    <ListItemAvatar sx={{ marginBottom: 0.5 }}>
                                        <Avatar sx={{
                                            color: 'inherit',
                                            width: 56,
                                            height: 32,
                                            borderRadius: 16,
                                            bgcolor: 'transparent',
                                            '&:hover': {
                                                bgcolor: 'primary.100'
                                            }
                                        }}>
                                        </Avatar>
                                    </ListItemAvatar>
                                    <Typography sx={{ textAlign: 'center', fontSize: 12, fontWeight: 400, lineHeight: 1.2 }}>
                                        First menu
                                    </Typography>
                                </ListItemButton>
                            </ListItem>
                        </>
                    )
                }
            </SidebarMenu> */}
            <Toaster />
            <Outlet />
        </Box>
    );
}

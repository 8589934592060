import { useContext, useEffect, useState } from 'react';
import { Typography, Box } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import storage from '../../../../config/storage';

import { getPublicList } from '../../../../services/projectService';
import { AuthContext } from '../../../../providers/auth';
import { ProjectContext } from '../../../../providers/projectProvider';

import { MainContainer, Container } from '../../../../components';
import { Menu } from '../Menu/Menu';


import './AdminProjects.scss';

export const AdminProjects = () => {

    const params = useParams();
    const [projects, setProjects] = useState([]);
    const { projectDetails, setCurrentProjectDetails } = useContext(ProjectContext);
    const { user } = useContext(AuthContext)
    const navigate = useNavigate();

    const goToProject = async (project) => {
        const projectShortName = project.encryptedProjectShortName;
        if (!projectShortName) return;
        await setCurrentProjectDetails(projectShortName);
        storage.setProjectShortName(project.projectShortName)
        navigate(`/${projectShortName}/login`);
    }

    useEffect(() => {
        if (projectDetails && projectDetails.projectName) {
            setCurrentProjectDetails(null);
            storage.clearProjectShortName();
        }
    }, []);

    useEffect(() => {
        if (user) {
            const projectShortName = params?.projectName;
            if (!projectShortName) return;
            if (user.userType === 2) {
                navigate(`/${projectShortName}/dashboard`);
            } else if (user.userType === 3) {
                if (user.modules.includes(101)) {
                    navigate(`/${projectShortName}/content-dashboard`);
                } else if (user.modules.includes(103)) {
                    navigate(`/${projectShortName}/score-management`);
                } else if (user.modules.includes(104)) {
                    navigate(`/${projectShortName}/customer/dashboard`);
                }
            } else if (user.userType === 4) {
                navigate(`/${projectShortName}/content-dashboard`);
            }
        }
    }, [navigate, user]);

    useEffect(() => {
        const getProjectsList = async () => {
            try {
                const response = await getPublicList();
                setProjects([...response.data.list])
            } catch (error) {
                console.log('There is an error while getting list', error);
            }
        }
        getProjectsList();
    }, [])

    return (
        <MainContainer>
            <Menu menuVisible={false}></Menu>
            <Container>
                <Box sx={{
                    display: 'grid',
                    placeContent: 'center'
                }}>

                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        rowGap: 2,
                        columnGap: 5,
                        flexWrap: 'wrap'
                    }}>
                        {
                            projects.map((project) => {
                                return (
                                    <Box key={project.projectShortName} onClick={(e) => goToProject(project)} sx={{
                                        minWidth: '220px',
                                        p: 4,
                                        border: '1px solid',
                                        borderColor: 'primary.main',
                                        borderRadius: 3,
                                        textAlign: 'center',
                                        bgcolor: 'background.base',
                                        cursor: 'pointer'
                                    }}>
                                        <Typography sx={{
                                            fontSize: 16,
                                            textTransform: 'uppercase',
                                        }}>
                                            {project.projectName}
                                        </Typography>
                                    </Box>
                                );
                            })
                        }
                    </Box>
                </Box>
            </Container>
        </MainContainer>
    )
}
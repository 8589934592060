import React from 'react';
import { Button } from '@mui/material';
import { styled } from '@mui/system';

// Define your custom styles
const MyButton = styled(Button)(({ theme }) => ({
  borderRadius: '46px',
  textTransform: 'none'
}));

const RadiusButton = (props) => {
  return <MyButton {...props} />;
};

export default RadiusButton;

import { useToaster } from '../../providers/ToasterProvider';
import { Box } from '@mui/material';

import CLOSE from '../../assets/images/close-black.svg'

export const Toaster = () => {
  const { notifications, removeNotification } = useToaster();

  return (
    <>
      {
        notifications.length
          ? <Box sx={{
            bgcolor: 'transparent',
            position: 'fixed',
            alignItems: 'center',
            top: 24,
            right: 12,
            width: 300,
            zIndex: 1400,
            p: 1
          }}>
            {notifications.map((notification, index) => {
              let color = 'primary.50';
              switch (notification.type) {
                case 'warning':
                  color = 'warning.100';
                  break;
                case 'danger':
                  color = 'error.100';
                  break;
                case 'success':
                  color = 'success.100';
                  break;
                default:
                  color = 'primary.100';
                  break;
              }
              return <Box
                key={index}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                  bgcolor: color,
                  py: 0.75,
                  px: 1.5,
                  borderRadius: 1,
                  mb: 1
                }}
              >
                <Box sx={{ flexGrow: 1 }}>{notification.message}</Box>
                <img style={{cursor: 'pointer', height: '24px'}} src={CLOSE} onClick={() => removeNotification(notification.id)} alt='' />
              </Box>
            })}
          </Box>
          : null
      }
    </>

  );
};

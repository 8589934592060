const blobToJson = async (data) => {
    const blob = new Blob([data]);
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
        reader.onload = () => {
            try {
                const jsonData = JSON.parse(reader.result);
                resolve(jsonData);
            } catch (error) {
                reject(error);
            }
        };
        reader.onerror = () => {
            reject(new Error('Failed to read blob'));
        };
        reader.readAsText(blob);
    });
}

export default blobToJson;
import Box from "@mui/material/Box"

export const Container = ({ sx, children }) => {
    return (
        <Box
            component="main"
            sx={{
                p: 3,
                pt: 1.5,
                bgcolor: 'background.default',
                height: 'calc(100vh - 80px)',
                maxHeight: 'calc(100vh - 80px)',
                overflow: 'auto',
                ...sx,
            }}
        >
            {children}
        </Box>
    );
}
import { Fragment, useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../../../providers/auth';

import { ContentDashboard } from '../ContentDashboard/ContentDashboard';
import { ContentDashboardAdmin } from '../ContentDashboardAdmin/ContentDashboardAdmin';

export const ContentDashboardLayout = () => {

    const { user } = useContext(AuthContext);

    const [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {
        if (user) {
            const isAdmin = user?.userType === 4;
            setIsAdmin(isAdmin);
        }
    }, [user]);

    return (
        <Fragment>
            {isAdmin
                ? <ContentDashboard />
                : <ContentDashboardAdmin />}
        </Fragment>
    );
}
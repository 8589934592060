import { useContext, useEffect, useState } from 'react';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Breadcrumbs, Typography, Box, FormControl, Select, MenuItem, InputLabel, FormHelperText } from '@mui/material';
import { MainContainer, Container } from '../../../../components';
import { Menu } from '../Menu/Menu';
import blobToJson from '../../../../utils/blobToJson';

import { getDataForScoreManagement } from '../../../../services/masterService';

import ARROW from '../../../../assets/images/breadcrumb-arrow.svg';
import ARROW_OPEN from '../../../../assets/images/breadcrumb-arrow-open.svg';
import RadiusButton from '../../../../components/RadiusButton';
import ATTENDANCE_DATA from '../../../../assets/images/attendance-data.svg';
import QUESTION_TEMPLATE from '../../../../assets/images/question.svg';
import ANSWER_KEY from '../../../../assets/images/answer-key.svg';
import RAW_SCORES from '../../../../assets/images/raw-scores.svg';
import REPORT from '../../../../assets/images/report.svg';
import DOWNLOAD from '../../../../assets/images/download.svg';
import UPLOAD from '../../../../assets/images/upload-green.svg';
import GENERATE from '../../../../assets/images/generate.svg';

import { DatePicker } from '@mui/x-date-pickers';
import dayjs from '../../../../config/dayjs';
import { downloadAttendanceSheet, getPresentCount } from '../../../../services/candidateService';
import { useToaster } from '../../../../providers/ToasterProvider';
import { LoadingButton } from '@mui/lab';
import { downloadQuestionTemplate } from '../../../../services/questionService';
import { UpdateAnswerKeyModal } from '../UpdateAnswerKeyModal/UpdateAnswerKeyModal';
import { downloadRawScore, downloadTenXReport, generateRawScore } from '../../../../services/rawScoreService';
import { ProjectContext } from '../../../../providers/projectProvider';


export const ScoreManagement = () => {

    const { addNotification } = useToaster();
    const { projectDetails } = useContext(ProjectContext);
    const defaultDrive = { id: 0, driveName: 'None' };
    const [drives, setDrives] = useState([defaultDrive]);
    const [selectedDrive, setSelectedDrive] = useState(0);
    const defaultCourse = { id: 0, courseName: 'None' };
    const [courses, setCourses] = useState([defaultCourse]);
    const [selectedCourse, setSelectedCourse] = useState(0);
    const defaultBatch = { id: 0, batchName: 'None' };
    const [batches, setBatches] = useState([defaultBatch]);
    const [selectedBatch, setSelectedBatch] = useState(0);
    const defaultProjectMaster = { id: 0, driveId: 0, courseId: 0, batchId: 0, displayExamDate: 'None' };
    const [projectMasters, setProjectMasters] = useState([defaultProjectMaster]);
    const [selectedProjectMaster, setSelectedProjectMaster] = useState(0);
    const [filteredProjectMasters, setFilteredProjectMasters] = useState([defaultProjectMaster]);
    const [currentState, setCurrentState] = useState({
        drive: 0,
        course: 0,
        batch: 0,
        projectMaster: 0,
    })
    const [candidateCounts, setCandidateCounts] = useState({
        total: 0,
        present: 0,
        absent: 0,
    });

    const [isDownloadingAttendanceData, setIsDownloadingAttendanceData] = useState(false);
    const [isDownloadingQuestionTemplate, setIsDownloadingQuestionTemplate] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const [isGenerating, setIsGenerating] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);
    const [isDownloadingReport, setIsDownloadingReport] = useState(false);

    const scoreManagementSchema = z.object({
        drive: z.number().gte(1),
        course: z.number().gte(1),
        batch: z.number().gte(1),
        examDate: z.number().gte(1),
    });

    const {
        formState: { errors },
        trigger,
        setValue,
    } = useForm({
        resolver: zodResolver(scoreManagementSchema),
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getDataForScoreManagement();
                if (response.code === 'OK') {
                    setDrives([defaultDrive, ...response.data.drives]);
                    setCourses([defaultCourse, ...response.data.courses]);
                    setBatches([defaultBatch, ...response.data.batches]);
                    setProjectMasters(response.data.projectMasters.map(pm => {
                        pm.displayExamDate = dayjs(pm.examDate).format('DD-MM-YYYY')
                        return pm;
                    }));
                }
            } catch (error) {
                console.log('There is an error while fetching update time required data', error);
            }
        }
        fetchData();
    }, []);

    useEffect(() => {
        if (selectedDrive && selectedCourse && selectedBatch) {
            const pms = projectMasters.filter((pm) => pm.driveId === selectedDrive && pm.courseId === selectedCourse && pm.batchId === selectedBatch);
            setFilteredProjectMasters([defaultProjectMaster, ...pms]);
            if (!(selectedDrive === currentState.drive && selectedCourse === currentState.course && selectedBatch === currentState.batch)) {
                setSelectedProjectMaster(0);
                setCandidateCounts({
                    total: 0,
                    present: 0,
                    absent: 0,
                });
            }
        } else {
            setFilteredProjectMasters([defaultProjectMaster]);
            setSelectedProjectMaster(0);
            setCandidateCounts({
                total: 0,
                present: 0,
                absent: 0,
            })
        }
    }, [selectedDrive, selectedCourse, selectedBatch]);

    const handleSelectProjectMaster = async () => {
        if (!selectedProjectMaster) return;
        try {
            const res = await getPresentCount(selectedProjectMaster);
            if (res.code === 'OK') {
                setCurrentState({
                    drive: selectedDrive,
                    course: selectedCourse,
                    batch: selectedBatch,
                    projectMaster: selectedProjectMaster
                })
                setCandidateCounts({
                    total: res.data.totalCount,
                    present: res.data.presentCount,
                    absent: res.data.absentCount
                })
            }
        } catch (error) {
            const code = error?.response?.data?.code;
            if (code === 'EXAM_NOT_ENDED') {
                addNotification('Exam not ended.', 'information');
            }
            console.log('There is an error while getting present count', error);
        }
    }

    const handleDownloadAttendanceData = async () => {
        if (!currentState.projectMaster) {
            addNotification('Please select exam for further processing');
            return;
        }
        setIsDownloadingAttendanceData(true);
        try {
            const res = await downloadAttendanceSheet(currentState.projectMaster);
            const url = window.URL.createObjectURL(res.data);

            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
                'download',
                `attendance-sheet.xlsx`,
            );
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            addNotification('File downloaded successfully.', 'success');

        } catch (error) {
            const code = error?.response?.data?.code;
            if (code === 'E_INTERNAL_SERVER_ERROR') {
                addNotification('Attendance sheet does not generated.');
            }
            console.log('There is an error while downloading attendance data', error);
        } finally {
            setIsDownloadingAttendanceData(false);
        }
    }

    const handleDownloadQuestionTemplate = async () => {
        if (!currentState.projectMaster) {
            addNotification('Please select exam for further processing');
            return;
        }
        setIsDownloadingQuestionTemplate(true);
        try {
            const res = await downloadQuestionTemplate(currentState.projectMaster);
            const url = window.URL.createObjectURL(res.data);

            const link = document.createElement('a');
            link.href = url;
            const course = courses.find(course => course.id === selectedCourse);
            const batch = batches.find(b => b.id === selectedBatch);
            const projectMaster = projectMasters.find(pm => pm.id === selectedProjectMaster);
            link.setAttribute(
                'download',
                `${course?.courseName}_${batch?.batchName}_${projectMaster?.displayExamDate}.xlsx`,
            );
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            addNotification('File downloaded successfully.', 'success');

        } catch (error) {
            const code = error?.response?.data?.code;
            if (code === 'E_INTERNAL_SERVER_ERROR') {
                addNotification('Attendance sheet does not generated.');
            }
            console.log('There is an error while downloading attendance data', error);
        } finally {
            setIsDownloadingQuestionTemplate(false);
        }
    }

    const handleGenerateRawScore = async () => {
        if (!currentState.projectMaster) {
            addNotification('Please select exam for further processing');
            return;
        }
        try {
            const res = await generateRawScore(currentState.projectMaster);
            addNotification('Raw scores generation started.', 'success');
            setIsGenerating(true);
        } catch (error) {
            const code = error?.response?.data?.code;
            if (code === 'EXAM_NOT_ENDED') {
                addNotification('Exam not ended yet.');
            } else if (code === 'UNPROCESSABLE_ENTITY') {
                addNotification('Score generation is in progress.', 'information')
            }
            console.log('There is an error while generating raw scores', error);
        } finally {
            // setIsGenerating(false);
        }
    }

    const handleDownloadRawScore = async () => {
        if (!currentState.projectMaster) {
            addNotification('Please select exam for further processing');
            return;
        }
        setIsDownloading(true);
        try {
            const res = await downloadRawScore(currentState.projectMaster);
            const url = window.URL.createObjectURL(res.data);

            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
                'download',
                `scores.xlsx`,
            );
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            addNotification('File downloaded successfully.', 'success');
        } catch (error) {
            const data = error?.response?.data;
            if (data) {
                const result = await blobToJson(data);
                if (result?.code === 'SCORES_ARE_NOT_GENERATED') {
                    addNotification('Scores are not generated, please generate to proceed.', 'danger');
                } else if (result?.code === 'GENERATING_SCORES') {
                    addNotification('Generating scores please wait.', 'danger');
                } else {
                    addNotification('Error while downloading.', 'danger');
                }
            }
            console.log('There is an error while downloading raw scores', error);
        } finally {
            setIsDownloading(false);
        }
    }

    const handleDownloadReport = async () => {
        if (!currentState.projectMaster) {
            addNotification('Please select exam for further processing');
            return;
        }
        setIsDownloadingReport(true);
        try {
            const res = await downloadTenXReport(currentState.projectMaster);
            const url = window.URL.createObjectURL(res.data);

            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
                'download',
                `10X_Report.xlsx`,
            );
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            addNotification('File downloaded successfully.', 'success');
        } catch (error) {
            const data = error?.response?.data;
            if (data) {
                const result = await blobToJson(data);
                if (result?.code === 'SCORES_ARE_NOT_GENERATED') {
                    addNotification('Scores are not generated, please generate to proceed.', 'danger');
                } else if (result?.code === 'GENERATING_SCORES') {
                    addNotification('Generating scores please wait.', 'danger');
                } else {
                    addNotification('Error while downloading.', 'danger');
                }
            }
            console.log('There is an error while downloading ten x report', error);
        } finally {
            setIsDownloadingReport(false);
        }
    }

    return (
        <MainContainer>
            <Menu>
                <Breadcrumbs aria-label="breadcrumb" separator='' sx={{ gap: 12 }}>
                    <Typography color="text.primary" sx={{ fontWeight: 400, textTransform: 'capitalize' }}>{projectDetails?.projectName}</Typography>
                    <Box sx={{ display: 'flex', alignContent: 'center' }}>
                        <img src={ARROW} alt=''></img>
                    </Box>
                    <Typography color="text.primary" sx={{ fontWeight: 500 }}>
                        Score Management
                    </Typography>
                    <Box sx={{ display: 'flex', alignContent: 'center' }}>
                        <img src={ARROW_OPEN} alt='' ></img>
                    </Box>
                </Breadcrumbs>
            </Menu>

            <Container>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column'
                }}>
                    <Typography component='h5' variant='h5' sx={{ mb: 3 }}>Score Management</Typography>
                    <Box sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        alignItems: 'flex-start',
                        marginBottom: 3,
                        columnGap: 2,
                        rowGap: 1
                    }}>
                        <FormControl className='form-field' sx={{ width: 222 }}>
                            <InputLabel id="driveName">Drive Name</InputLabel>
                            <Select
                                labelId="driveName"
                                id="drive"
                                label='Drive Name'
                                name='drive'
                                error={Boolean(errors.drive)}
                                onBlur={() => trigger('drive')}
                                value={selectedDrive ?? 0}
                                onChange={(e, event) => {
                                    const drive = drives.find((d) => d.id === event.props.value);
                                    setSelectedDrive(drive.id);
                                    setValue('drive', event.props.value);
                                }}
                            >
                                {
                                    drives.map(
                                        (drive) => <MenuItem key={drive.id} value={drive.id}>
                                            {drive.driveName}
                                        </MenuItem>
                                    )
                                }
                            </Select>
                            {errors.drive?.message
                                && <FormHelperText sx={{ color: 'error.main' }}>Please select drive name.</FormHelperText>
                            }
                        </FormControl>
                        <FormControl className='form-field' sx={{ width: 222 }}>
                            <InputLabel id="courseName">Course Name</InputLabel>
                            <Select
                                labelId="courseName"
                                id="course"
                                label='Course Name'
                                name='course'
                                error={Boolean(errors.course)}
                                onBlur={() => trigger('course')}
                                value={selectedCourse ?? 0}
                                onChange={(e, event) => {
                                    const course = courses.find((d) => d.id === event.props.value);
                                    setSelectedCourse(course.id);
                                    setValue('course', event.props.value);
                                }}
                            >
                                {
                                    courses.map(
                                        (course) => <MenuItem key={course.id} value={course.id}>
                                            {course.courseName}
                                        </MenuItem>
                                    )
                                }
                            </Select>
                            {errors.course?.message
                                && <FormHelperText sx={{ color: 'error.main' }}>Please select course name.</FormHelperText>
                            }
                        </FormControl>
                        <FormControl className='form-field' sx={{ width: 222 }}>
                            <InputLabel id="batchName">Batch Name</InputLabel>
                            <Select
                                labelId="batchName"
                                id="batch"
                                label='Batch Name'
                                name='batch'
                                error={Boolean(errors.batch)}
                                onBlur={() => trigger('batch')}
                                value={selectedBatch ?? 0}
                                onChange={(e, event) => {
                                    const batch = batches.find((b) => b.id === event.props.value);
                                    setSelectedBatch(batch.id);
                                    setValue('batch', event.props.value);
                                }}
                            >
                                {
                                    batches.map(
                                        (batch) => <MenuItem key={batch.id} value={batch.id}>
                                            {batch.batchName}
                                        </MenuItem>
                                    )
                                }
                            </Select>
                            {errors.batch?.message
                                && <FormHelperText sx={{ color: 'error.main' }}>Please select batch name.</FormHelperText>
                            }
                        </FormControl>
                        <FormControl className='form-field' sx={{ width: 222 }}>
                            <InputLabel id="examDateLabel">Exam Date</InputLabel>
                            <Select
                                labelId="examDateLabel"
                                id="examDate"
                                label='Exam Date'
                                name='examDate'
                                error={Boolean(errors.examDate)}
                                onBlur={() => trigger('examDate')}
                                value={selectedProjectMaster ?? 0}
                                onChange={(e, event) => {
                                    const projectMaster = filteredProjectMasters.find((pm) => pm.id === event.props.value);
                                    setSelectedProjectMaster(projectMaster.id);
                                    setValue('examDate', event.props.value);
                                }}
                            >
                                {
                                    filteredProjectMasters.map(
                                        (pm) => <MenuItem key={pm.id} value={pm.id}>
                                            {pm.displayExamDate}
                                        </MenuItem>
                                    )
                                }
                            </Select>
                            {errors.examDate?.message
                                && <FormHelperText sx={{ color: 'error.main' }}>Please select exam date.</FormHelperText>
                            }
                        </FormControl>

                        <RadiusButton sx={{
                            textTransform: 'uppercase',
                            fontWeight: 500,
                            ml: 'auto'
                        }} color='primary'
                            variant='contained'
                            size='large'
                            onClick={handleSelectProjectMaster}
                            disabled={!selectedDrive || !selectedCourse || !selectedBatch || !selectedProjectMaster}
                        >
                            select
                        </RadiusButton>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        backgroundColor: 'background.base',
                        p: 3
                    }}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            flexWrap: 'wrap',
                            gap: 2,
                            mb: 3,
                        }}>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                rowGap: 1,
                                borderRadius: 2,
                                border: '1px solid',
                                borderColor: 'grey.500',
                                height: 130,
                                width: '30%',
                                maxWidth: 305,
                                p: 3,
                                textAlign: 'center',
                            }}>
                                <Typography sx={{
                                    color: 'primary.main',
                                    fontSize: 18,
                                    fontWeight: 500,
                                }}>{candidateCounts.total}</Typography>
                                <Typography sx={{
                                    fontWeight: 500,
                                }}>
                                    Candidate Schedule
                                </Typography>
                            </Box>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                rowGap: 1,
                                borderRadius: 2,
                                border: '1px solid',
                                borderColor: 'grey.500',
                                height: 130,
                                width: '30%',
                                maxWidth: 305,
                                p: 3,
                                textAlign: 'center',
                            }}>
                                <Typography sx={{
                                    color: 'success.main',
                                    fontSize: 18,
                                    fontWeight: 500,
                                }}>{candidateCounts.present}</Typography>
                                <Typography sx={{
                                    fontWeight: 500,
                                }}>
                                    Candidate Present
                                </Typography>
                            </Box>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                rowGap: 1,
                                borderRadius: 2,
                                border: '1px solid',
                                borderColor: 'grey.500',
                                height: 130,
                                width: '30%',
                                maxWidth: 305,
                                p: 3,
                                textAlign: 'center',
                            }}>
                                <Typography sx={{
                                    color: 'error.main',
                                    fontSize: 18,
                                    fontWeight: 500,
                                }}>{candidateCounts.absent}</Typography>
                                <Typography sx={{
                                    fontWeight: 500,
                                }}>
                                    Candidate Absent
                                </Typography>
                            </Box>
                        </Box>

                        <Box sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            justifyContent: 'center',
                            maxWidth: 1040,
                            margin: '0 auto',
                            columnGap: 4,
                            rowGap: 4,
                        }}>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'space-evenly',
                                borderRadius: 2,
                                border: '1px solid',
                                borderColor: 'grey.500',
                                height: 210,
                                width: 310
                            }}>
                                <Box sx={{
                                    width: 60,
                                    height: 60,
                                    background: '#E1E8F5',
                                    borderRadius: '50%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignContent: 'center'
                                }}>
                                    <img src={ATTENDANCE_DATA} alt="" />
                                </Box>
                                <Box sx={{
                                    textTransform: 'uppercase',
                                    fontWeight: 600,
                                }}>attendance data</Box>
                                <LoadingButton
                                    color='success'
                                    loadingPosition='start'
                                    startIcon={<img src={DOWNLOAD} alt='' />}
                                    sx={{
                                        '.MuiLoadingButton-label': {
                                            fontSize: 12,
                                            textDecoration: 'underline'
                                        }
                                    }}
                                    loading={isDownloadingAttendanceData}
                                    onClick={handleDownloadAttendanceData}
                                >
                                    Download Now
                                </LoadingButton>
                            </Box>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'space-evenly',
                                borderRadius: 2,
                                border: '1px solid',
                                borderColor: 'grey.500',
                                height: 210,
                                width: 310
                            }}>
                                <Box sx={{
                                    width: 60,
                                    height: 60,
                                    background: '#E1E8F5',
                                    borderRadius: '50%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignContent: 'center'
                                }}>
                                    <img src={QUESTION_TEMPLATE} alt="" />
                                </Box>
                                <Box sx={{
                                    textTransform: 'uppercase',
                                    fontWeight: 600,
                                }}>
                                    question template
                                </Box>
                                <LoadingButton
                                    color='success'
                                    startIcon={<img src={DOWNLOAD} alt='' />}
                                    loadingPosition='start'
                                    sx={{
                                        '.MuiLoadingButton-label': {
                                            fontSize: 12,
                                            textDecoration: 'underline'
                                        }
                                    }}
                                    loading={isDownloadingQuestionTemplate}
                                    onClick={handleDownloadQuestionTemplate}
                                >
                                    Download Now
                                </LoadingButton>
                            </Box>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'space-evenly',
                                borderRadius: 2,
                                border: '1px solid',
                                borderColor: 'grey.500',
                                height: 210,
                                width: 310
                            }}>
                                <Box sx={{
                                    width: 60,
                                    height: 60,
                                    background: '#E1E8F5',
                                    borderRadius: '50%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignContent: 'center'
                                }}>
                                    <img src={ANSWER_KEY} alt="" />
                                </Box>
                                <Box sx={{
                                    textTransform: 'uppercase',
                                    fontWeight: 600,
                                }}>
                                    answer key template
                                </Box>
                                <LoadingButton
                                    color='success'
                                    loadingPosition='start'
                                    startIcon={<img src={UPLOAD} alt='' />}
                                    sx={{
                                        '.MuiLoadingButton-label': {
                                            fontSize: 12,
                                            textDecoration: 'underline'
                                        }
                                    }}
                                    onClick={() => {
                                        if (!selectedProjectMaster) {
                                            addNotification('Please select exam for further processing.')
                                            return;
                                        }
                                        setIsVisible(true);
                                    }}
                                >
                                    Upload Now
                                </LoadingButton>
                            </Box>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'space-evenly',
                                borderRadius: 2,
                                border: '1px solid',
                                borderColor: 'grey.500',
                                height: 210,
                                width: 310
                            }}>
                                <Box sx={{
                                    width: 60,
                                    height: 60,
                                    background: '#E1E8F5',
                                    borderRadius: '50%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignContent: 'center'
                                }}>
                                    <img src={RAW_SCORES} alt="" />
                                </Box>
                                <Box sx={{
                                    textTransform: 'uppercase',
                                    fontWeight: 600,
                                }}>
                                    raw scores
                                </Box>
                                <LoadingButton
                                    color='success'
                                    loadingPosition='start'
                                    startIcon={<img src={GENERATE} alt='' />}
                                    loading={isGenerating}
                                    sx={{
                                        '.MuiLoadingButton-label': {
                                            fontSize: 12,
                                            textDecoration: 'underline'
                                        }
                                    }}

                                    onClick={handleGenerateRawScore}
                                >
                                    Generate Now
                                </LoadingButton>
                            </Box>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'space-evenly',
                                borderRadius: 2,
                                border: '1px solid',
                                borderColor: 'grey.500',
                                height: 210,
                                width: 310
                            }}>
                                <Box sx={{
                                    width: 60,
                                    height: 60,
                                    background: '#E1E8F5',
                                    borderRadius: '50%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignContent: 'center'
                                }}>
                                    <img src={RAW_SCORES} alt="" />
                                </Box>
                                <Box sx={{
                                    textTransform: 'uppercase',
                                    fontWeight: 600,
                                }}>
                                    raw scores
                                </Box>
                                <LoadingButton
                                    color='success'
                                    loadingPosition='start'
                                    startIcon={<img src={DOWNLOAD} alt='' />}
                                    loading={isDownloading}
                                    sx={{
                                        '.MuiLoadingButton-label': {
                                            fontSize: 12,
                                            textDecoration: 'underline'
                                        }
                                    }}

                                    onClick={handleDownloadRawScore}
                                >
                                    Download Now
                                </LoadingButton>
                            </Box>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'space-evenly',
                                borderRadius: 2,
                                border: '1px solid',
                                borderColor: 'grey.500',
                                height: 210,
                                width: 310
                            }}>
                                <Box sx={{
                                    width: 60,
                                    height: 60,
                                    background: '#E1E8F5',
                                    borderRadius: '50%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignContent: 'center'
                                }}>
                                    <img src={REPORT} alt="" />
                                </Box>
                                <Box sx={{
                                    textTransform: 'uppercase',
                                    fontWeight: 600,
                                }}>
                                    10X report
                                </Box>
                                <LoadingButton
                                    color='success'
                                    loadingPosition='start'
                                    startIcon={<img src={DOWNLOAD} alt='' />}
                                    loading={isDownloadingReport}
                                    sx={{
                                        '.MuiLoadingButton-label': {
                                            fontSize: 12,
                                            textDecoration: 'underline'
                                        }
                                    }}

                                    onClick={handleDownloadReport}
                                >
                                    Download Now
                                </LoadingButton>
                            </Box>
                        </Box>
                    </Box>


                </Box>


                {isVisible && <UpdateAnswerKeyModal projectMasterId={selectedProjectMaster} isVisible={isVisible} setIsVisible={setIsVisible} />}
            </Container>

        </MainContainer>
    );
}

import { useContext, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Box, FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField, Typography } from '@mui/material';

import { AuthContext } from '../../../../providers/auth';
import { ProjectContext } from '../../../../providers/projectProvider';
import { useToaster } from '../../../../providers/ToasterProvider';

import storage from '../../../../config/storage';
import { login } from '../../../../services/authService';

import { MainContainer } from '../../../../components/MainContainer/MainContainer';
import { Container } from '../../../../components/Container/Container';
import RadiusButton from '../../../../components/RadiusButton'
import { Menu } from '../Menu/Menu';

import EYE_ICON from '../../../../assets/images/eye.svg';
import EYE_CLOSE_ICON from '../../../../assets/images/eye-close.svg'

import './Login.scss';


const loginSchema = z.object({
    email: z.string().min(1, { message: 'Email is required' }).email('Invalid email address'),
    password: z.string().min(1, 'Password is required'),
});

export const Login = () => {
    const params = useParams();
    const navigate = useNavigate();
    const { user, setUser } = useContext(AuthContext);
    const { projectDetails, setCurrentProjectDetails } = useContext(ProjectContext);
    const [showPassword, setShowPassword] = useState(false);
    const { addNotification } = useToaster();

    useEffect(() => {
        const setProject = async () => {
            if (!projectDetails) {
                let projectName = params?.projectName;
                if (!projectName) {
                    navigate('/list');
                } else {
                    await setCurrentProjectDetails(projectName);
                }
            }
        }
        setProject();
        const resetPasswordData = storage.getResetPasswordData();
        if (resetPasswordData && resetPasswordData?.user && resetPasswordData?.accessToken && resetPasswordData?.refreshToken) {
            const projectShortName = params?.projectName;
            if (projectShortName) {
                navigate(`/${projectShortName}/reset-password`, {
                    state: {
                        accessToken: resetPasswordData.accessToken,
                        refreshToken: resetPasswordData.refreshToken,
                        user: resetPasswordData.user
                    }
                });
            }
        }
    }, []);

    useEffect(() => {
        navigateUser();
    }, [user, navigate]);

    const {
        register,
        handleSubmit,
        formState: { errors },
        trigger
    } = useForm({
        resolver: zodResolver(loginSchema),
    });

    const navigateUser = () => {
        const projectShortName = params?.projectName;
        const userType = user?.userType;
        if (!user || !projectShortName || !userType) return;
        if (userType === 2) {
            navigate(`/${projectShortName}/dashboard`);
        } else if (userType === 3) {
            if (user.modules.includes(101)) {
                navigate(`/${projectShortName}/content-dashboard`);
            } else if (user.modules.includes(102)) {
                navigate(`/${projectShortName}/mac-ids`);
            } else if (user.modules.includes(103)) {
                navigate(`/${projectShortName}/score-management`);
            } else if (user.modules.includes(104)) {
                navigate(`/${projectShortName}/customer/dashboard`);
            }
        } else if (userType === 4) {
            navigate(`/${projectShortName}/content-dashboard`);
        }
    }

    const onSubmit = async (data) => {
        const projectShortName = params?.projectName;
        if (!projectShortName) {
            navigate('/list');
        }
        data = {
            ...data,
            projectShortName: projectShortName
        }
        try {
            const response = await login(data);
            if (response.code === 'OK') {
                if (response.data?.user?.initialLogin) {
                    storage.clearUserDetails();
                    setUser(null);
                    storage.setResetPasswordData({
                        user: response.data.user,
                        accessToken: response.data.token.accessToken,
                        refreshToken: response.data.refreshToken.refreshToken
                    });
                    navigate(`/${projectShortName}/reset-password`, {
                        state: {
                            accessToken: response.data.token.accessToken,
                            refreshToken: response.data.refreshToken.refreshToken,
                            user: response.data.user
                        }
                    });
                } else {
                    storage.setUserDetails(response.data.user);
                    storage.setToken(response.data.token.accessToken);
                    storage.setRefreshToken(response.data.refreshToken.refreshToken);
                    setUser(response.data.user);
                    navigateUser();
                }
            } else {
                throw new Error('Not login')
            }
        } catch (error) {
            const code = error?.response?.data?.code;
            if (code === 'USER_NOT_ACTIVE') {
                addNotification('User is inactivated.', 'danger')
            } else if (code === 'E_BAD_REQUEST') {
                addNotification('User credentials are wrong.', 'danger')
            } else if (code === 'E_USER_NOT_FOUND') {
                addNotification('User credentials are wrong.', 'danger')
            } else if (code === 'UNPROCESSABLE_ENTITY') {
                addNotification('User already logged in.', 'danger')
            }
            console.log('There is an error while logging in user', error);
        }

    }

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    }

    return (
        <MainContainer>
            <Menu menuVisible={false}></Menu>

            <Container sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <Box sx={{
                    width: '80%',
                    maxWidth: 724,
                    px: 4.5,
                    py: 3,
                    borderRadius: 6,
                    bgcolor: 'background.base'
                }} className='login-container' component="form" onSubmit={handleSubmit(onSubmit)}>
                    <Typography variant='h5'>User Log In</Typography>
                    <div className='login-wrapper'>
                        <TextField
                            id='email'
                            fullWidth name='email'
                            placeholder='Email Address'
                            autoFocus label={
                                <>
                                    <Box component='span'>Email</Box>
                                    <Box component='span' sx={{ color: 'error.main', ml: 0.25 }}>*</Box>
                                </>
                            }
                            variant='outlined'
                            {...register('email')}
                            error={Boolean(errors.email)}
                            helperText={errors.email?.message}
                            onBlur={() => trigger('email')}
                        />

                        <FormControl variant='outlined'>
                            <InputLabel htmlFor="password">Password</InputLabel>
                            <OutlinedInput
                                id='password'
                                type={showPassword ? 'text' : 'password'}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={togglePasswordVisibility}
                                            edge="end"
                                        >
                                            <img className='password-eye' src={showPassword ? EYE_CLOSE_ICON : EYE_ICON} alt='' />
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="Password"
                                name="password"
                                autoComplete="current-password"
                                {...register('password')}
                                error={Boolean(errors.password)}
                                onBlur={() => trigger('password')}
                            />
                            {errors?.password?.message && (
                                <FormHelperText sx={{ color: 'error.main' }}>
                                    {errors.password?.message}
                                </FormHelperText>
                            )}
                        </FormControl>
                    </div>
                    <div className='align-center'>
                        <RadiusButton color="secondary" type="submit" variant='contained' size='large'>Log In</RadiusButton>
                    </div>
                </Box>
            </Container>
        </MainContainer>
    );
}
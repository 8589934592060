
import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../providers/auth';

const SuperAdminAuthGuard = ({ component }) => {

    const navigate = useNavigate();
    const { user } = useContext(AuthContext);

    useEffect(() => {
        if (user) {
            const { userType } = user;
            if (userType !== 1) {
                navigate('/login');
            }
        } else {
            navigate('/login')
        }
    }, [navigate, user]);

    return <React.Fragment>{component}</React.Fragment>
}

export default SuperAdminAuthGuard;
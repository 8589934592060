import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { Box, Breadcrumbs, Typography, Select, MenuItem, FormHelperText, InputLabel, FormControl, IconButton } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from '../../../../config/dayjs';

import { Container, MainContainer } from '../../../../components';
import { Menu } from '../Menu/Menu';
import { DriveDetailsModal } from '../DriveDetailsModal/DriveDetailsModal';
import { CourseDetailsModal } from '../CourseDetailsModal/CourseDetailsModal';
import { BatchDetailsModal } from '../BatchDetailsModal/BatchDetailsModal';

import { createMaster, getById, updateMaster } from '../../../../services/masterService';
import { downloadCandidateTemplate } from '../../../../services/candidateService';
import { getAll as getAllDrives } from '../../../../services/driveService';
import { getAll as getAllCourses } from '../../../../services/courseService';
import { getAll as getAllBatches } from '../../../../services/batchService';

import RadiusButton from '../../../../components/RadiusButton'

import ARROW from '../../../../assets/images/breadcrumb-arrow.svg';
import ARROW_OPEN from '../../../../assets/images/breadcrumb-arrow-open.svg';
import XLS from '../../../../assets/images/xls.svg';
import BACK from '../../../../assets/images/back.svg';
import PLUS from '../../../../assets/images/plus.svg';
import DELETE from '../../../../assets/images/action-delete.svg';

import { zodResolver } from '@hookform/resolvers/zod';
import { useToaster } from '../../../../providers/ToasterProvider';
import { LoadingButton } from '@mui/lab';
import { ProjectContext } from '../../../../providers/projectProvider';

const masterSchema = z.object({
    driveName: z.number().gte(1, { message: 'Please select drive' }),
    course: z.number().gte(1, { message: 'Please select course' }),
    batch: z.number().gte(1, { message: 'Please select batch' }),
});

const acceptedFileTypes = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];

export const MasterDetails = () => {

    const params = useParams();
    const projectName = params?.projectName;
    const { addNotification } = useToaster();
    const { projectDetails } = useContext(ProjectContext);
    const location = useLocation();
    const navigate = useNavigate();
    const [masterDetails, setMasterDetails] = useState(null);

    const [driveId, setDriveId] = useState(0);
    const [drives, setDrives] = useState([]);
    const [driveModalToggler, setDriveModalToggler] = useState(false);
    const openDriveDetailsModal = () => {
        setDriveModalToggler(!driveModalToggler);
    }

    useEffect(() => {
        if (!driveModalToggler) {
            const fetchDrives = async () => {
                try {
                    const response = await getAllDrives();
                    const list = response?.data ?? [];
                    setDrives([...list]);
                } catch (error) {
                    console.log('There is an error while getting drives', error);
                }
            }
            fetchDrives();
        }
    }, [driveModalToggler]);

    const [courseId, setCourseId] = useState(0);
    const [courses, setCourses] = useState([]);
    const [courseModalToggler, setCourseModalToggler] = useState(false);
    const openCourseDetailsModal = () => {
        setCourseModalToggler(!courseModalToggler);
    }
    useEffect(() => {
        if (!courseModalToggler) {
            const fetchCourses = async () => {
                try {
                    const response = await getAllCourses();
                    const list = response?.data ?? [];
                    setCourses([...list]);
                } catch (error) {
                    console.log('There is an error while getting courses', error);
                }
            }
            fetchCourses();
        }
    }, [courseModalToggler]);

    const [batchId, setBatchId] = useState(0);
    const [batches, setBatches] = useState([]);
    const [batchModalToggler, setBatchModalToggler] = useState(false);
    const openBatchDetailsModal = () => {
        setBatchModalToggler(!batchModalToggler);
    }

    useEffect(() => {
        if (!batchModalToggler) {
            const fetchBatches = async () => {
                try {
                    const response = await getAllBatches();
                    const list = response?.data ?? [];
                    setBatches([...list]);
                } catch (error) {
                    console.log('There is an error while getting courses', error);
                }
            }
            fetchBatches();
        }
    }, [batchModalToggler]);
    const todayDate = dayjs();
    const [date, setDate] = useState(dayjs())

    const {
        handleSubmit,
        formState: { errors },
        trigger,
        setValue,
    } = useForm({
        resolver: zodResolver(masterSchema),
    });
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const masterId = location?.state?.masterId;
        if (masterId) {
            const fetchData = async () => {
                try {
                    const response = await getById(masterId);
                    if (response.code === 'OK') {
                        setMasterDetails(response.data);
                        setDriveId(response.data.driveId);
                        setValue('driveName', response.data.driveId);
                        setCourseId(response.data.courseId);
                        setValue('course', response.data.courseId);
                        setBatchId(response.data.batchId);
                        setValue('batch', response.data.batchId);
                        const date = dayjs(response.data.examDate);
                        setDate(date);
                    }
                } catch (error) {
                    console.log('There is an error while fetching master details', error);
                }
            }
            fetchData();
        }
    }, [location])

    const onGoBack = () => {
        const path = location.pathname.replace('/master-details', '');
        navigate(path);
    }

    const onDownloadCandidateTemplate = async () => {
        try {
            const res = await downloadCandidateTemplate();
            const url = window.URL.createObjectURL(res.data);

            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
                'download',
                `candidate-template.xlsx`,
            );
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            addNotification('File downloaded successfully.', 'success');
        } catch (error) {
            console.log('error', error);
            addNotification('Unexpected error occurred', 'danger');
        }
    }

    const submitForm = async (values) => {
        const body = {
            driveId: values.driveName.toString(),
            courseId: values.course.toString(),
            batchId: values.batch.toString(),
            examDate: date.format('YYYY-MM-DD'),
        }
        if (masterDetails) {
            setIsLoading(true);
            try {
                const response = await updateMaster(masterDetails.id, body);
                if (response.code === 'OK') {
                    addNotification('Master updated successfully.', 'success');
                    navigate(location.pathname.replace('/master-details', ''));
                } else {
                    addNotification('Master does not updated.')
                }

            } catch (error) {
                console.log('There is an error while creating master', error);
                const code = error?.response?.data?.code;
                if (code === 'UNPROCESSABLE_ENTITY' && error?.response?.data?.status === 422) {
                    addNotification('Master already exists for provided exam date.', 'danger');
                } else if (code === "E_EXAM_LOCKED") {
                    addNotification('Exam has been locked for selected drive and course, so it can not be updated.', 'danger');
                } else {
                    addNotification('Unexpected error occured.', 'danger');
                }
            } finally {
                setIsLoading(false);
            }
            return;
        } else {
            setIsLoading(true);
            try {
                const response = await createMaster(body);
                if (response.code === 'OK') {
                    addNotification('Master created successfully.', 'success')
                    navigate(location.pathname.replace('/master-details', ''))
                } else {
                    addNotification('Master creation failed.')
                }
            } catch (error) {
                console.log('There is an error while creating master', error);
                const code = error?.response?.data?.code;
                if (code === 'UNPROCESSABLE_ENTITY' && error?.response?.data?.status === 422) {
                    addNotification('Master already exists for provided exam date.', 'danger');
                } else if (code === 'E_EXAM_LOCKED') {
                    addNotification('Exam has been locked for selected drive and course, so it can not be created.', 'danger');
                } else {
                    addNotification('Unexpected error occured.', 'danger');
                }
            } finally {
                setIsLoading(false);
            }
        }
    }

    return (
        <MainContainer sx={{ bgcolor: 'red' }}>
            <Menu>
                <Breadcrumbs aria-label="breadcrumb" separator='' sx={{ gap: 12 }}>
                    <Typography color="text.primary" sx={{
                        fontWeight: 400,
                        textTransform: 'capitalize'
                    }}>{projectDetails?.projectName}</Typography>
                    <Box sx={{ display: 'flex', alignContent: 'center' }}>
                        <img src={ARROW} alt=''></img>
                    </Box>

                    <Typography color="text.primary" sx={{ fontWeight: 500 }}>
                        {masterDetails ? 'Edit Master' : 'Add Master'}
                    </Typography>
                    <Box sx={{ display: 'flex', alignContent: 'center' }}>
                        <img src={ARROW_OPEN} alt='' ></img>
                    </Box>
                </Breadcrumbs>
            </Menu>
            <Container sx={{
                display: 'flex',
                flexDirection: 'column',
            }}>

                <div className="search-header-wrapper" style={{ marginBottom: '24px' }}>
                    <Typography component='h5' variant='h5' sx={{
                        mr: 'auto',
                    }}>
                        {masterDetails ? 'Edit Master' : 'Add Master'}
                    </Typography>

                    <RadiusButton sx={{
                        textTransform: 'uppercase',
                        fontWeight: 500,
                    }} color="secondary" type="submit" variant='contained' size='large' startIcon={<img src={XLS} alt='' />} onClick={onDownloadCandidateTemplate}>
                        Download Candidate Details Template
                    </RadiusButton>
                </div>

                <Box component='form' sx={{
                    display: 'flex',
                    flexDirection: 'column'
                }} onSubmit={handleSubmit(submitForm)}>

                    <Box sx={{
                        display: 'flex',
                        gap: 3,
                        justifyContent: 'flex-start',
                        flexWrap: 'wrap',
                        mb: 4,
                    }}>
                        <FormControl sx={{
                            maxWidth: 600,
                            flexGrow: 1
                        }}>
                            <InputLabel id="driveNameLabel">Drive Name*</InputLabel>
                            <Select
                                labelId="driveNameLabel"
                                id="driveName"
                                label='DriveName*'
                                margin='none'
                                name='driveName'
                                error={Boolean(errors.driveName)}
                                onBlur={() => trigger('driveName')}
                                value={driveId}
                                onChange={(e, value) => {
                                    setDriveId(value.props.value)
                                    setValue('driveName', value.props.value)
                                }}
                            >
                                <MenuItem value={0}>None</MenuItem>
                                {
                                    drives.map(
                                        (drive) => <MenuItem key={drive.id} value={drive.id} selected={drive.id === 1}>
                                            {drive.driveName}
                                        </MenuItem>
                                    )
                                }
                            </Select>
                            {errors.driveName?.message && (
                                <FormHelperText sx={{ color: 'error.main' }}>
                                    Please select drive name
                                </FormHelperText>
                            )}
                        </FormControl>

                        <IconButton sx={{
                            width: 48,
                            height: 48,
                            border: '1px solid',
                            borderColor: 'primary.main',
                            borderRadius: 1,
                            '&:hover': {
                                bgcolor: 'primary.50'
                            }
                        }} color="primary" onClick={openDriveDetailsModal} disableRipple>
                            <img src={PLUS} alt='' />
                        </IconButton>
                    </Box>

                    <Box sx={{
                        display: 'flex',
                        gap: 3,
                        justifyContent: 'flex-start',
                        flexWrap: 'wrap',
                        mb: 4,
                    }}>
                        <FormControl sx={{
                            maxWidth: 600,
                            flexGrow: 1
                        }}>
                            <InputLabel id="courseNameLabel">Course Name*</InputLabel>
                            <Select
                                labelId="courseNameLabel"
                                id="course"
                                label='Course Name*'
                                margin='none'
                                name='course'
                                error={Boolean(errors.course)}
                                onBlur={() => trigger('course')}
                                onChange={(e, value) => {
                                    setCourseId(value.props.value)
                                    setValue('course', value.props.value)
                                }}
                                value={courseId}
                            >
                                <MenuItem value={0}>None</MenuItem>
                                {
                                    courses.map(
                                        (course) => <MenuItem key={course.id} value={course.id}>
                                            {course.courseName}
                                        </MenuItem>
                                    )
                                }
                            </Select>
                            {errors.course?.message && (
                                <FormHelperText sx={{ color: 'error.main' }}>
                                    Please select course name
                                </FormHelperText>
                            )}
                        </FormControl>

                        <IconButton sx={{
                            width: 48,
                            height: 48,
                            border: '1px solid',
                            borderColor: 'primary.main',
                            borderRadius: 1,
                            '&:hover': {
                                bgcolor: 'primary.50'
                            }
                        }} color="primary" onClick={openCourseDetailsModal} disableRipple>
                            <img src={PLUS} alt='' />
                        </IconButton>
                    </Box>

                    <Box sx={{
                        display: 'flex',
                        gap: 3,
                        justifyContent: 'flex-start',
                        flexWrap: 'wrap',
                        mb: 4,
                    }}>
                        <FormControl sx={{
                            maxWidth: 600,
                            flexGrow: 1
                        }}>
                            <InputLabel id="batchNameLabel">Batch Name*</InputLabel>
                            <Select
                                labelId="batchNameLabel"
                                id="batch"
                                label='Batch Name*'
                                margin='none'
                                name='batch'
                                error={Boolean(errors.batch)}
                                onBlur={() => trigger('batch')}
                                value={batchId}
                                onChange={(e, event) => {
                                    setBatchId(event.props.value);
                                    setValue('batch', event.props.value);
                                }}
                            >
                                <MenuItem value={0}>None</MenuItem>
                                {
                                    batches.map(
                                        (batch) => <MenuItem key={batch.id} value={batch.id}>
                                            {batch.batchName}
                                        </MenuItem>
                                    )
                                }
                            </Select>
                            {errors.batch?.message && (
                                <FormHelperText sx={{ color: 'error.main' }}>
                                    Please select batch name
                                </FormHelperText>
                            )}
                        </FormControl>

                        <IconButton sx={{
                            width: 48,
                            height: 48,
                            border: '1px solid',
                            borderColor: 'primary.main',
                            borderRadius: 1,
                            '&:hover': {
                                bgcolor: 'primary.50'
                            }
                        }} color="primary" onClick={openBatchDetailsModal} disableRipple>
                            <img src={PLUS} alt='' />
                        </IconButton>
                    </Box>

                    <Box sx={{
                        display: 'flex',
                        gap: 3,
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        flexWrap: 'wrap',
                        mb: 4,
                    }}>
                        <DatePicker label='Date*' minDate={todayDate} format='DD-MM-YYYY' value={date} onChange={(date) => setDate(date)} sx={{
                            flexGrow: 1,
                            maxWidth: 600,
                            '& .MuiFormLabel-root.Mui-error': {
                                color: 'rgba(0, 0, 0, 0.6)'
                            },
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'rgba(0, 0, 0, 0.23) !important'
                            }
                        }} />
                    </Box>

                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 2,
                        mt: 'auto'
                    }}>
                        <RadiusButton sx={{
                            textTransform: 'uppercase',
                            fontWeight: 500,
                        }} color='grey'
                            variant='contained'
                            size='large'
                            // disabled
                            onClick={onGoBack}
                            startIcon={<img src={BACK} alt='' />}>
                            Back
                        </RadiusButton>
                        <LoadingButton sx={{
                            textTransform: 'uppercase',
                            borderRadius: 40,
                        }} color='primary'
                            type="submit"
                            variant='contained'
                            size='large'
                            loading={isLoading}
                        >
                            Submit
                        </LoadingButton>
                    </Box>
                </Box>

            </Container>
            <DriveDetailsModal openState={driveModalToggler} setOpenState={setDriveModalToggler} />
            <CourseDetailsModal openState={courseModalToggler} setOpenState={setCourseModalToggler} />
            <BatchDetailsModal openState={batchModalToggler} setOpenState={setBatchModalToggler} />
        </MainContainer >
    );
}

import { axios } from "../config/axios";

const endPoint = '/project/batch';

const createBatch = async (body) => {
    return axios.post(`${endPoint}`, body);
}

const getAll = async () => {
    return axios.get(`${endPoint}`);
}

export {
    createBatch,
    getAll,
}
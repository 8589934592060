import { useContext, useRef, useState } from "react";
import { Box, List, ListItemButton, Tab, Tabs, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import cryptoJs from "crypto-js";

import { AuthContext } from "../../../../providers/auth";

import useClickoutHook from "../../../../hooks/useClickoutHook";
import { logout } from "../../../../services/authService";

import USER_PROFILE from '../../../../assets/images/user-profile.svg'
import USER_MORE from '../../../../assets/images/user-more.svg';
import BRAND_IMAGE from '../../../../assets/images/logo.png';
import RadiusButton from "../../../../components/RadiusButton";
import { useToaster } from "../../../../providers/ToasterProvider";


export const EncodeDecode = () => {

    const { addNotification } = useToaster();
    const [activeTab, setActiveTab] = useState(0);
    const [encodeInput, setEncodeInput] = useState('');
    const [encodeJSON, setEncodeJSON] = useState(null);
    const [encodedJSON, setEncodedJSON] = useState(null);

    const [decodeInput, setDecodeInput] = useState('');
    const [decodeJSON, setDecodeJSON] = useState(null);
    const [decodedJSON, setDecodedJSON] = useState(null);

    const isValidJSON = (string) => {
        try {
            return JSON.parse(string);
        } catch (err) {
            return null;
        }
    }
    const handleEncodeInput = (e) => {
        const input = e.target.value.trim();
        setEncodeInput(input);
        if (!input) return;

        const validInput = isValidJSON(input);
        setEncodeJSON(validInput);
    }
    const handleCopy = () => {
        navigator.clipboard.writeText(JSON.stringify(encodedJSON))
    }
    const handleEncodeJson = async () => {
        try {
            const data = JSON.stringify(encodeJSON);
            const wordArray = cryptoJs.enc.Utf8.parse(data);

            const key = cryptoJs.lib.WordArray.random(32);
            const iv = cryptoJs.lib.WordArray.random(16);

            const encrypted = cryptoJs.AES.encrypt(wordArray, key, {
                iv: iv,
                mode: cryptoJs.mode.CBC,
                padding: cryptoJs.pad.Pkcs7
            })
            setEncodedJSON({
                encryptedData: encrypted.ciphertext.toString(cryptoJs.enc.Hex),
                key: key.toString(cryptoJs.enc.Hex),
                iv: iv.toString(cryptoJs.enc.Hex)
            });
        } catch (error) {
            console.log('There is an error while encoding body', error);
            addNotification('Unexpected error occured.', 'danger');
        }
    }

    const handleDecodeInput = (e) => {
        const input = e.target.value.trim();
        setDecodeInput(input);
        if (!input) return;

        const validInput = isValidJSON(input);
        setDecodeJSON(validInput);
    }
    const handleDecodeJSON = async () => {
        try {
            const { key, iv, encryptedData } = decodeJSON;
            const decrypted = cryptoJs.AES.decrypt(
                {
                    ciphertext: cryptoJs.enc.Hex.parse(encryptedData)
                },
                cryptoJs.enc.Hex.parse(key),
                {
                    iv: cryptoJs.enc.Hex.parse(iv),
                    mode: cryptoJs.mode.CBC,
                    padding: cryptoJs.pad.Pkcs7
                }
            );
            const decryptedText = decrypted.toString(cryptoJs.enc.Utf8);
            setDecodedJSON(JSON.parse(decryptedText));
        } catch (error) {
            addNotification('Unexpected error occured.', 'danger')
            console.log(error);
        }
    }
    const handleDecodedCopy = () => {
        navigator.clipboard.writeText(JSON.stringify(decodedJSON))
    }

    return (
        <Box sx={{
            width: '100vw',
            minHeight: '100vh',
            background: '#efefef',
            display: ''
        }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={activeTab} onChange={(_, newValue) => setActiveTab(newValue)}>
                    <Tab label="Encode" variant='secondary' color='secondary' id='encode' />
                    <Tab label="Decode" id='decode' />
                </Tabs>
            </Box>
            <div
                role="tabpanel"
                index={0}
                hidden={activeTab !== 0}
                id={`tabpanel-0`}
            >
                {activeTab === 0 && <Box sx={{
                    display: 'grid',
                    gridTemplateColumns: 'minmax(0, 1fr) minmax(0, 1fr)',
                    gap: 3,
                    p: 3,
                }}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 1,
                    }}>
                        <RadiusButton
                            color='secondary'
                            variant='contained'
                            disabled={!encodeJSON}
                            onClick={handleEncodeJson}
                            sx={{
                                width: 200,
                                ml: 'auto'
                            }}
                        >Encode</RadiusButton>
                        <textarea
                            style={{
                                backgroundColor: '#fafafa',
                                padding: '10px 20px',
                                height: 'calc(100vh - 150px)',
                                resize: 'none',
                                outline: 'none',
                                border: '1px solid #ddd',
                                borderRadius: 8,
                                whiteSpace: 'break-spaces',
                                wordBreak: 'break-all'
                            }}
                            placeholder="Raw JSON"
                            onChange={handleEncodeInput}
                            value={encodeInput}
                        ></textarea>
                    </Box>

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 1,
                    }}>
                        <RadiusButton
                            color='secondary'
                            variant='contained'
                            onClick={handleCopy}
                            disabled={!encodedJSON}
                            sx={{
                                width: 200,
                            }}
                        >Copy</RadiusButton>
                        <Box sx={{
                            backgroundColor: '#fafafa',
                            padding: '10px 20px',
                            height: 'auto',
                            resize: 'none',
                            outline: 'none',
                            border: '1px solid #ddd',
                            borderRadius: 2,
                            maxHeight: 'calc(100vh - 150px)',
                            minHeight: 100,
                            overflow: 'auto',
                            whiteSpace: 'break-spaces',
                            wordBreak: 'break-all'
                        }}>
                            {
                                encodedJSON && JSON.stringify(encodedJSON, null, 2)
                            }
                        </Box>
                    </Box>
                </Box>}
            </div>
            <div
                role="tabpanel"
                index={1}
                hidden={activeTab !== 1}
                id={`tabpanel-1`}
            >
                {activeTab === 1 && <Box sx={{
                    display: 'grid',
                    gridTemplateColumns: 'minmax(0, 1fr) minmax(0, 1fr)',
                    gap: 3,
                    p: 3,
                }}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 1,
                    }}>
                        <RadiusButton
                            color='secondary'
                            variant='contained'
                            disabled={!decodeJSON}
                            onClick={handleDecodeJSON}
                            sx={{
                                width: 200,
                                ml: 'auto'
                            }}
                        >Decode</RadiusButton>
                        <textarea
                            style={{
                                backgroundColor: '#fafafa',
                                padding: '10px 20px',
                                height: 'calc(100vh - 150px)',
                                resize: 'none',
                                outline: 'none',
                                border: '1px solid #ddd',
                                borderRadius: 8,
                                whiteSpace: 'break-spaces',
                                wordBreak: 'break-all'
                            }}
                            placeholder="Encoded JSON"
                            onChange={handleDecodeInput}
                            value={decodeInput}
                        ></textarea>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 1,
                    }}>
                        <RadiusButton
                            color='secondary'
                            variant='contained'
                            onClick={handleDecodedCopy}
                            disabled={!decodedJSON}
                            sx={{
                                width: 200,
                            }}
                        >Copy</RadiusButton>
                        <Box sx={{
                            backgroundColor: '#fafafa',
                            padding: '10px 20px',
                            height: 'auto',
                            resize: 'none',
                            outline: 'none',
                            border: '1px solid #ddd',
                            borderRadius: 2,
                            maxHeight: 'calc(100vh - 150px)',
                            minHeight: 100,
                            overflow: 'auto',
                            whiteSpace: 'break-spaces',
                            wordBreak: 'break-all'
                        }}>
                            {
                                decodedJSON && JSON.stringify(decodedJSON, null, 2)
                            }
                        </Box>
                    </Box>
                </Box>}
            </div>
        </Box>
    );
}
import { useRoutes } from "react-router-dom";
// import { Login } from "../shared/login/Login";


import { SuperAdminRoutes } from "./SuperAdminRoutes";
import { AdminRoutes } from "./AdminRoutes";
// import AuthGuard from "./authGuard";

export const AppRoutes = () => {
    const commonRoutes = [
        // {
        //     path: '/',
        //     element: <AuthGuard component={<Login />} />,
        // },
        {
            path: '*',
            element: <>404 - Not Found</>,
        }
    ];

    const routes = useRoutes([
        ...commonRoutes,
        ...SuperAdminRoutes,
        ...AdminRoutes,
    ]);

    return <>{routes}</>;
};

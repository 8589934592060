import { useLocation, useNavigate } from 'react-router-dom';
import { Avatar, Box, Typography } from '@mui/material';

import { Container, MainContainer } from '../../../../components';
import { Menu } from '../Menu/Menu';

import MASTER_MANAGEMENT from '../../../../assets/images/master-management.svg';
import USERS_MANAGEMENT from '../../../../assets/images/users-management.svg';

import './Dashboard.scss';


export const Dashboard = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const onMasterManagement = () => {
        navigate(location.pathname.replace('dashboard', 'masters'));
    }
    const onUsersManagement = () => {
        navigate(location.pathname.replace('dashboard', 'users'));
    }


    return (
        <MainContainer>
            <Menu></Menu>
            <Container>
                <Box sx={{
                    height: '100%',
                    display: 'grid',
                    placeContent: 'center'
                }}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        rowGap: 2,
                        columnGap: 5
                    }}>

                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            minWidth: 370,
                            p: 6,
                            border: '1px solid',
                            borderColor: 'primary.main',
                            borderRadius: 3,
                            bgcolor: 'background.base',
                            cursor: 'pointer'
                        }} onClick={onMasterManagement}>
                            <Avatar sx={{
                                bgcolor: 'primary.50',
                                width: 60,
                                height: 60,
                                mb: 4
                            }}>
                                <img src={MASTER_MANAGEMENT} style={{ height: 32 }} alt='' />
                            </Avatar>

                            <Typography variant='h6' sx={{
                                fontSize: 16,
                                fontWeight: 700,
                                textTransform: 'uppercase',
                                mb: 4
                            }}>Master management</Typography>

                            <Typography variant='h6' sx={{
                                fontSize: 12,
                            }}>(Create new masters for  a project)</Typography>
                        </Box>

                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            minWidth: 370,
                            p: 6,
                            border: '1px solid',
                            borderColor: 'primary.main',
                            borderRadius: 3,
                            bgcolor: 'background.base',
                            cursor: 'pointer'
                        }} onClick={onUsersManagement}>
                            <Avatar sx={{
                                bgcolor: 'primary.50',
                                width: 60,
                                height: 60,
                                mb: 4
                            }}>
                                <img src={USERS_MANAGEMENT} style={{ height: 32 }} alt='' />
                            </Avatar>

                            <Typography variant='h6' sx={{
                                fontSize: 16,
                                fontWeight: 700,
                                textTransform: 'uppercase',
                                mb: 4
                            }}>Users management</Typography>

                            <Typography variant='h6' sx={{
                                fontSize: 12,
                            }}>(Create all users and map to roles)</Typography>
                        </Box>

                    </Box>
                </Box>
            </Container>
        </MainContainer>
    );
}

import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Breadcrumbs, Typography, Box, TextField, Paper } from '@mui/material';

import { useToaster } from '../../../../providers/ToasterProvider';
import { getFeedbackAndInstructionsEnabled, downloadFeedbackTemplate } from '../../../../services/configurationService';
import { downloadContentTemplate } from '../../../../services/questionService';
import { MainContainer, Container } from '../../../../components';
import { Menu } from '../Menu/Menu';
import RadiusButton from '../../../../components/RadiusButton';
import { ContentUpload } from '../ContentUpload/ContentUpload';
import { InstructionsUpload } from '../InstructionsUpload/InstructionsUpload';
import { FeedbackUpload } from '../FeedbackUpload/FeedbackUpload';

import ARROW from '../../../../assets/images/breadcrumb-arrow.svg';
import ARROW_OPEN from '../../../../assets/images/breadcrumb-arrow-open.svg';
import XLS from '../../../../assets/images/xls.svg';
import { ProjectContext } from '../../../../providers/projectProvider';

export const UploadLayout = () => {

    const params = useParams();
    const projectName = params?.projectName;
    const navigate = useNavigate();
    const location = useLocation();
    const { addNotification } = useToaster();
    const { projectDetails } = useContext(ProjectContext);

    const availableTabs = ['content', 'instructions', 'feedback'];
    const [activeTab, setActiveTab] = useState(null);
    const [drive, setDrive] = useState({
        id: null,
        name: 'Drive name'
    });
    const [course, setCourse] = useState({
        id: null,
        name: 'Course name'
    });
    const [instructionsEnabled, setInstructionsEnabled] = useState(false);
    const [feedbackEnabled, setFeedbackEnabled] = useState(false);
    const [configurationId, setConfigurationId] = useState(null);

    useEffect(() => {
        const driveDetails = location?.state?.drive;
        const courseDetails = location?.state?.course;
        const configurationId = location?.state?.configurationId;
        const tab = location?.state?.activeTab;
        if (!driveDetails || !courseDetails || !configurationId) {
            if (projectName) {
                navigate(`/${projectName}/content-dashboard`)
            }
        } else {
            setDrive(() => driveDetails);
            setCourse(() => courseDetails);
            setConfigurationId(configurationId);
            const fetchData = async () => {
                try {
                    const res = await getFeedbackAndInstructionsEnabled(configurationId);
                    if (res.code === 'OK') {
                        setInstructionsEnabled(res.data.instructionSheetsEnabled);
                        setFeedbackEnabled(res.data.feedbackEnabled);
                    }
                } catch (error) {
                    console.log('There is an error while getting instruction sheets and feedback status', error);
                }
            }
            fetchData();
            if (!activeTab && availableTabs.includes(tab)) {
                setActiveTab(tab)
            }
        }
    }, [location, navigate]);

    const changeTab = (tab) => {
        if (activeTab !== tab && availableTabs.includes(tab)) {
            setActiveTab(tab);
        }
    }

    const renderComponent = () => {
        switch (activeTab) {
            case 'content':
                return <ContentUpload drive={drive} course={course} configurationId={configurationId} />
            case 'instructions':
                return <InstructionsUpload configurationId={configurationId} />
            case 'feedback':
                return <FeedbackUpload drive={drive} course={course} configurationId={configurationId} />
            default:
                break;
        }
    }

    const onDownloadContentTemplate = async () => {
        try {
            const res = await downloadContentTemplate();
            const url = window.URL.createObjectURL(res.data);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
                'download',
                `content-template.docx`,
            );
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            addNotification('File downloaded successfully.', 'success');
        } catch (error) {
            console.log('error', error);
            addNotification('Unexpected error occurred', 'danger');
        }
    }

    const onDownloadFeedbackTemplate = async () => {
        try {
            const res = await downloadFeedbackTemplate();
            const url = window.URL.createObjectURL(res.data);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
                'download',
                `feedback-template.docx`,
            );
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            addNotification('File downloaded successfully.', 'success');
        } catch (error) {
            console.log('error', error);
            addNotification('Unexpected error occurred', 'danger');
        }
    }

    return (
        <MainContainer>
            <Menu>
                <Breadcrumbs aria-label="breadcrumb" separator='' sx={{ gap: 12 }}>
                    <Typography sx={{ fontWeight: 400, textTransform: 'capitalize' }}>{projectDetails?.projectName}</Typography>
                    <Box sx={{ display: 'flex', alignContent: 'center' }}>
                        <img src={ARROW} alt=''></img>
                    </Box>

                    <Typography color="text.primary" sx={{ fontWeight: 500, textTransform: 'capitalize' }}>{activeTab} Upload</Typography>
                    <Box sx={{ display: 'flex', alignContent: 'center' }}>
                        <img src={ARROW_OPEN} alt='' ></img>
                    </Box>
                </Breadcrumbs>
            </Menu>
            <Container sx={{
                display: 'flex',
                flexDirection: 'column'
            }}>
                <div className="search-header-wrapper" sx={{ mb: 1.5 }}>
                    <h1 className='title' style={{
                        margin: '0px',
                        marginRight: 'auto',
                        textTransform: 'capitalize'
                    }}>{activeTab} Upload</h1>
                    <RadiusButton
                        color='primary'
                        variant={activeTab === 'content' ? 'contained' : 'outlined'}
                        size='large'
                        sx={{
                            textTransform: 'uppercase',
                        }}
                        onClick={() => changeTab('content')}
                    >Content</RadiusButton>
                    {instructionsEnabled && <RadiusButton
                        color='primary'
                        variant={activeTab === 'instructions' ? 'contained' : 'outlined'}
                        size='large'
                        sx={{
                            textTransform: 'uppercase',
                        }}
                        onClick={() => changeTab('instructions')}
                    >Instructions</RadiusButton>}
                    {feedbackEnabled && <RadiusButton
                        color='primary'
                        variant={activeTab === 'feedback' ? 'contained' : 'outlined'}
                        size='large'
                        sx={{
                            textTransform: 'uppercase',
                        }}
                        onClick={() => changeTab('feedback')}
                    >Feedback</RadiusButton>}
                </div>

                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    columnGap: 2,
                    rowGap: 0.5,
                    mb: 3
                }}>
                    <TextField
                        label="Drive Name"
                        variant="outlined"
                        value={drive?.['name']}
                        disabled
                        InputLabelProps={{
                            shrink: true
                        }}
                        sx={{
                            width: 238,
                            bgcolor: 'background.base'
                        }}
                    />
                    <TextField
                        label="Course Name"
                        variant="outlined"
                        value={course?.['name']}
                        disabled
                        InputLabelProps={{
                            shrink: true
                        }}
                        sx={{
                            width: 238,
                            bgcolor: 'background.base'
                        }}
                    />
                    {activeTab === 'content' && <RadiusButton
                        variant='contained'
                        color='secondary'
                        sx={{ ml: 'auto' }}
                        startIcon={<img src={XLS} alt='' />}
                        onClick={onDownloadContentTemplate}
                    >
                        Download Content Template
                    </RadiusButton>}
                    {activeTab === 'feedback' && <RadiusButton
                        variant='contained'
                        color='secondary'
                        sx={{ ml: 'auto' }}
                        startIcon={<img src={XLS} alt='' />}
                        onClick={onDownloadFeedbackTemplate}
                    >
                        Download Feedback Template
                    </RadiusButton>}
                </Box>

                <Paper sx={{
                    borderRadius: 3,
                    bgcolor: 'background.base',
                    flex: 1,
                    maxWidth: 'calc(100vw - 140px)',
                    overflow: 'auto',
                }}>
                    {renderComponent()}
                </Paper>
            </Container>
        </MainContainer >
    );
}
import Axios from 'axios';
import { axios } from '../config/axios';
import storage from '../config/storage';

const endPoint = '/project/raw-score';

const generateRawScore = (projectMasterId) => {
    return axios.get(`${endPoint}/generate-raw-score/${projectMasterId}`);
}

const downloadRawScore = (projectMasterId) => {
    const token = storage.getToken();

    return Axios.get(`${process.env.REACT_APP_API_URL}${endPoint}/download-raw-score/${projectMasterId}`, {
        responseType: 'blob',
        headers: {
            authorization: token?.accessToken
        }
    });
}

const downloadTenXReport = (projectMasterId) => {
    const token = storage.getToken();

    return Axios.get(`${process.env.REACT_APP_API_URL}${endPoint}/download-ten-x-report/${projectMasterId}`, {
        responseType: 'blob',
        headers: {
            authorization: token?.accessToken
        }
    });
}


export {
    generateRawScore,
    downloadRawScore,
    downloadTenXReport,
}
import { useContext, useEffect, useState } from "react";
import { Box, Breadcrumbs, Checkbox, FormControl, FormControlLabel, FormHelperText, InputLabel, MenuItem, Pagination, Paper, Radio, RadioGroup, Select, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, Typography } from "@mui/material";
import { Container, MainContainer } from "../../../../components";
import { Menu } from '../Menu/Menu';
import ARROW from '../../../../assets/images/breadcrumb-arrow.svg';
import ARROW_OPEN from '../../../../assets/images/breadcrumb-arrow-open.svg';
import SEARCH_ICON from '../../../../assets/images/search-icon.svg';
import APPROVED_ICON from '../../../../assets/images/approved.svg';
import RadiusButton from '../../../../components/RadiusButton';
import { useParams } from "react-router-dom";
import dayjs from '../../../../config/dayjs';
import { TimeField } from "@mui/x-date-pickers";
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from "zod";
import { useForm } from "react-hook-form";
import { approveRequestedExtendedTimes, extendForAllCandidates, extendTimeOfCandidates, getListByProjectMasterWithPagination, getRequiredData } from "../../../../services/extendedTimeService";
import { getListByPage } from "../../../../services/candidateService";
import { useToaster } from "../../../../providers/ToasterProvider";
import { LoadingButton } from "@mui/lab";
import { ProjectContext } from "../../../../providers/projectProvider";


const limit = parseInt(process.env.REACT_APP_PAGE_LIMIT);

export const UpdateTime = () => {

    const { addNotification } = useToaster();
    const { projectDetails } = useContext(ProjectContext);
    const params = useParams();
    const projectName = params?.projectName;
    const [selectedDrive, setSelectedDrive] = useState({
        id: 0,
        driveName: 'None'
    });
    const [selectedCourse, setSelectedCourse] = useState({
        id: 0,
        courseName: 'None'
    });
    const [selectedBatch, setSelectedBatch] = useState({
        id: 0,
        batchName: 'None'
    });
    const [selectedExamDate, setSelectedExamDate] = useState({ id: 0, batchId: 0, courseId: 0, driveId: 0, examDate: 'None', displayExamDate: 'None' });
    const [selectedExamHall, setSelectedExamHall] = useState({
        id: 0,
        projectMasterId: 0,
        hallName: 'All'
    });
    const [drives, setDrives] = useState([{ id: 0, driveName: 'None' }]);
    const [courses, setCourses] = useState([{ id: 0, courseName: 'None' }]);
    const [batches, setBatches] = useState([{ id: 0, batchName: 'None' }]);
    const [projectMasters, setProjectMasters] = useState([]);
    const [examHalls, setExamHalls] = useState([]);
    const [availableExamHalls, setAvailableExamHalls] = useState([{ id: 0, projectMasterId: 0, hallName: 'All' }]);
    const [examDates, setExamDates] = useState([{ id: 0, batchId: 0, courseId: 0, driveId: 0, examDate: 'None', displayExamDate: 'None' }]);
    const [tabIndex, setTabIndex] = useState(0);
    const [forAllCandidates, setForAllCandidates] = useState('Yes');
    const [updateTime, setUpdateTime] = useState(dayjs.utc().hour(0).minute(0).second(0).millisecond(0))
    const [updateTimeErr, setUpdateTimeErr] = useState(null)
    const [maxPages, setMaxPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [candidates, setCandidates] = useState([]);
    const [requestedCandidates, setRequestedCandidates] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [updateCandidates, setUpdateCandidates] = useState(false);
    const [timerId, setTimerId] = useState(null);
    const [generalSelected, setGeneralSelected] = useState(false);
    const [generalUpdateTime, setGeneralUpdateTime] = useState(dayjs.utc().hour(0).minute(0).second(0).millisecond(0));
    const statusOptions = ['all', 'requested', 'approved'];
    const [selectedStatus, setSelectedStatus] = useState('all');
    const [isUpdating, setIsUpdating] = useState(false);
    const [isApproving, setIsApproving] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getRequiredData();
                if (response.code === 'OK') {
                    setDrives([{ id: 0, driveName: 'None' }, ...response.data.drives]);
                    setCourses([{ id: 0, courseName: 'None' }, ...response.data.courses]);
                    setBatches([{ id: 0, batchName: 'None' }, ...response.data.batches]);
                    setProjectMasters(response.data.projectMasters.map(pm => {
                        pm.displayExamDate = dayjs(pm.examDate).format('DD-MM-YYYY')
                        return pm;
                    }));
                    setExamHalls(response.data.examHalls);
                }
            } catch (error) {
                console.log('There is an error while fetching update time required data', error);
            }
        }
        fetchData();
    }, []);

    useEffect(() => {
        if (selectedDrive.id && selectedCourse.id && selectedBatch.id) {
            const dates = projectMasters.filter((pm) => pm.driveId === selectedDrive.id && pm.courseId === selectedCourse.id && pm.batchId === selectedBatch.id);
            setExamDates([{ id: 0, batchId: 0, courseId: 0, driveId: 0, examDate: 'None', displayExamDate: 'None' }, ...dates]);
            setSelectedExamDate({ id: 0, batchId: 0, courseId: 0, driveId: 0, examDate: 'None', displayExamDate: 'None' });
            setValue('examDate', 0)
        }
    }, [selectedDrive, selectedCourse, selectedBatch]);

    useEffect(() => {
        if (tabIndex === 0) {
            if (selectedExamDate && selectedExamDate.id) {
                const fetchCandidates = async () => {
                    try {
                        const response = await getListByPage(selectedExamDate.id, selectedExamHall.id, searchTerm.trim(), currentPage, limit);
                        if (response.code === 'OK') {
                            const candidates = response.data?.rows ?? [];
                            setCandidates(candidates.map(candidate => {
                                candidate.isSelected = false;
                                candidate.extendedTimeErr = null;
                                candidate.extendedTime = dayjs.utc().hour(0).minute(0).second(0).millisecond(0)
                                return candidate;
                            }));
                            const count = response.data.count ?? 1;
                            const max = Math.ceil(count / limit);
                            setMaxPages(max);
                            setGeneralSelected(false);
                            setGeneralUpdateTime(dayjs.utc().hour(0).minute(0).second(0).millisecond(0));
                        }
                    } catch (error) {
                        console.log('There is an error while getting candidates', error);
                    }
                }
                if (forAllCandidates === 'No') {
                    fetchCandidates();
                }
                const eHalls = examHalls.filter((eh) => eh.projectMasterId === selectedExamDate.id);
                setAvailableExamHalls([{ id: 0, projectMasterId: 0, hallName: 'All' }, ...eHalls]);
            } else {
                setCandidates([]);
                setMaxPages(1);
                setCurrentPage(1);
                setSearchTerm('');
                setGeneralSelected(false);
                setGeneralUpdateTime(dayjs.utc().hour(0).minute(0).second(0).millisecond(0));
                setSelectedExamHall({ id: 0, projectMasterId: 0, hallName: 'All' });
                setAvailableExamHalls([{ id: 0, projectMasterId: 0, hallName: 'All' }]);
            }
        } else {
            if (selectedExamDate?.id) {
                const fetchRequestedExtendedTimes = async () => {
                    try {

                        const response = await getListByProjectMasterWithPagination(selectedExamDate.id, selectedExamHall.id, selectedStatus, searchTerm.trim(), currentPage, limit);
                        if (response.code === 'OK') {
                            const candidates = response.data?.rows ?? [];
                            setRequestedCandidates(candidates.map(candidate => {
                                candidate.isSelected = false;
                                const hours = Math.floor(candidate.minutes / 60);
                                const minutes = candidate.minutes % 60;
                                const date = dayjs.utc().hour(hours).minute(minutes);
                                candidate.requestedTime = date.format('HH:mm');

                                return candidate;
                            }));

                            const eHalls = examHalls.filter((eh) => eh.projectMasterId === selectedExamDate.id);
                            setAvailableExamHalls([{ id: 0, projectMasterId: 0, hallName: 'All' }, ...eHalls]);
                            const count = response.data.count ?? 1;
                            const max = Math.ceil(count / limit);
                            setMaxPages(max);
                            setGeneralSelected(false);
                        }
                    } catch (error) {
                        console.log('There is an error while getting candidates', error);
                    }
                }
                fetchRequestedExtendedTimes();
            } else {
                setRequestedCandidates([]);
                setMaxPages(1);
                setCurrentPage(1);
                setSearchTerm('');
                setGeneralSelected(false);
                setSelectedExamHall({ id: 0, projectMasterId: 0, hallName: 'All' });
                setAvailableExamHalls([{ id: 0, projectMasterId: 0, hallName: 'All' }]);
            }
        }
    }, [selectedExamDate, updateCandidates, forAllCandidates]);

    const handleExamHallChange = (e, value) => {
        const examHall = availableExamHalls.find((eh) => eh.id === value.props.value);
        setSelectedExamHall(examHall);
        setValue('examHall', value.props.value);
        setUpdateCandidates(!updateCandidates);
    }

    const updateTimeSchema = z.object({
        drive: z.number().gte(1),
        course: z.number().gte(1),
        batch: z.number().gte(1),
        examDate: z.number().gte(1),
        examHall: z.number(),
    });

    const {
        formState: { errors },
        trigger,
        setValue,
    } = useForm({
        resolver: zodResolver(updateTimeSchema),
    });
    const columns = [
        { id: 'rollNo', label: 'Roll No', minWidth: 140 },
        { id: 'userName', label: 'Username', minWidth: 140 },
        { id: 'candidateName', label: 'Candidate Name', minWidth: 140 },
        { id: 'selected', label: '', minWidth: 200 },
        { id: 'extendTime', label: '', minWidth: 200 },
    ];
    const requestedColumns = [
        { id: 'rollNo', label: 'Roll No', minWidth: 140 },
        { id: 'userName', label: 'Username', minWidth: 140 },
        { id: 'candidateName', label: 'Candidate Name', minWidth: 140 },
        { id: 'requestedTime', label: 'Requested Time', minWidth: 200 },
        { id: 'selected', label: '', minWidth: 200 },
    ];

    const handleTimeDurationChange = (value, err) => {
        let error = err.validationError ? 'Please enter valid time.' : null;
        if (!value) {
            error = 'Please enter valid time.'
        }
        if (value && value.hour() === 0 && value.minute() === 0) {
            error = 'Please enter valid time.'
        }

        setUpdateTimeErr(error);
        if (value) {
            setUpdateTime(value);
        }
    }

    const handleGeneralSelectedChange = (event, value) => {
        setGeneralSelected(value);
        if (tabIndex === 0) {
            setCandidates(
                candidates.map((candidate) => {
                    candidate.isSelected = value;
                    return candidate;
                })
            );
        } else {
            setRequestedCandidates(
                requestedCandidates.map((candidate) => {
                    candidate.isSelected = value;
                    return candidate;
                })
            );
        }
    }

    const handleGeneralUpdateTimeChange = (value, err) => {
        setGeneralUpdateTime(value);
        if (value && value.isValid()) {
            setCandidates(
                candidates.map((candidate) => {
                    candidate.extendedTime = value.clone();
                    candidate.extendedTimeErr = null;
                    return candidate;
                })
            )
        }
    }

    const handleCandidateSelectedChange = (rowIndex, value) => {
        let allSelected = true;
        setCandidates(
            candidates.map((candidate, index) => {
                if (index === rowIndex) {
                    candidate.isSelected = value;
                }
                if (allSelected && !candidate.isSelected) {
                    allSelected = false;
                }
                return candidate;
            })
        );
        setGeneralSelected(allSelected);
    }

    const handleCandidateExtendTimeChange = (rowIndex, date, err) => {
        let error = (err.validationError || !date) ? 'Please enter valid time.' : null;

        setCandidates(
            candidates.map((candidate, index) => {
                if (rowIndex === index) {
                    candidate.extendedTime = date;
                    candidate.extendedTimeErr = error;
                }
                return candidate;
            })
        );
    }

    const renderTableCell = (columnId, rowIndex) => {
        const row = candidates[rowIndex];
        switch (columnId) {
            case 'selected':
                return <Checkbox onChange={(e, value) => handleCandidateSelectedChange(rowIndex, value)} checked={row.isSelected} />
            case 'extendTime':
                return <TimeField
                    sx={{ width: 160 }}
                    size="small"
                    value={row.extendedTime}
                    format="HH:mm"
                    onChange={(d, e) => handleCandidateExtendTimeChange(rowIndex, d, e)}
                    error={!!row.extendedTimeErr}
                    slotProps={{
                        textField: {
                            helperText: row.extendedTimeErr,
                        }
                    }}
                />
            default:
                return row[columnId];
        }
    }
    const renderRequestedTableCell = (columnId, rowIndex) => {
        const row = requestedCandidates[rowIndex];
        switch (columnId) {
            case 'selected':
                return row.isApproved
                    ? <img src={APPROVED_ICON} alt='' />
                    : <Checkbox onChange={(e, value) => {
                        let allSelected = true;
                        setRequestedCandidates(
                            requestedCandidates.map((candidate, index) => {
                                if (index === rowIndex) {
                                    candidate.isSelected = value;
                                }
                                if (allSelected && !candidate.isSelected) {
                                    allSelected = false;
                                }
                                return candidate;
                            })
                        )
                        setGeneralSelected(allSelected);
                    }} checked={row.isSelected} />
            case 'rollNo':
                return row['candidate.rollNo'];
            case 'userName':
                return row['candidate.userName'];
            case 'candidateName':
                return row['candidate.candidateName'];
            case 'requestedTime':
                return row[columnId];
            default:
                return row[columnId];
        }
    }

    const handleSearchTermChange = (e) => {
        setSearchTerm(e.target.value);
        if (timerId) {
            clearTimeout(timerId);
        }
        let timer = setTimeout(() => {
            setCurrentPage(1);
            setUpdateCandidates(!updateCandidates);
        }, 400);
        setTimerId(timer);
    }

    const handlePageChange = (value) => {
        if (currentPage === value) return;
        setCurrentPage(value);
        setUpdateCandidates(!updateCandidates);
    }

    const handleUpdateTimeForAllCandidates = async () => {
        const projectMasterId = selectedExamDate?.id;
        if (!projectMasterId) return;
        if (!updateTime || (updateTime && updateTime.hour() === 0 && updateTime.minute() === 0)) {
            setUpdateTimeErr('Please enter valid time.');
            return;
        }
        setIsUpdating(true);
        try {
            const hours = parseInt(updateTime.format('HH'));
            const minutes = parseInt(updateTime.format('mm'));
            const extendedMinutes = minutes + (hours * 60);
            const body = {
                projectMasterId,
                extendedMinutes: extendedMinutes
            }
            const examHallId = selectedExamHall?.id;
            if (examHallId) {
                body.examHallId = examHallId;
            }
            const res = await extendForAllCandidates(body);
            if (res.code === 'OK') {
                addNotification('Candidates Time updated successfully.', 'success');
                setUpdateTime(dayjs.utc().hour(0).minute(0).second(0).millisecond(0));
                setUpdateTimeErr(null);
            }
        } catch (error) {
            const code = error?.response?.data?.code;
            if (code === 'E_INTERNAL_SERVER_ERROR') {
                addNotification('Candidates time does not updated.', 'danger');
            }
            console.log('There is an error while creating extended times', error);
        } finally {
            setIsUpdating(false);
        }
    }

    const handleUpdateTimeForCandidates = async () => {
        const projectMasterId = selectedExamDate?.id;
        if (!projectMasterId) return;

        let hasErr = false;
        const selectedCandidates = [];
        const preparedCandidates = candidates.map((candidate) => {
            if (candidate.isSelected) {
                selectedCandidates.push(candidate);
                if (!candidate.extendedTime || (candidate.extendedTime && candidate.extendedTime.hour() === 0 && candidate.extendedTime.minute() === 0)) {
                    candidate.extendedTimeErr = 'Please enter valid time.'
                    hasErr = true;
                }
            }
            return candidate;
        });
        if (!selectedCandidates.length) {
            addNotification('Please select candidates.', 'information');
            return;
        }
        if (hasErr) {
            setCandidates(preparedCandidates);
            return;
        }
        setIsUpdating(true);
        try {
            const body = {
                projectMasterId,
                candidates: selectedCandidates.map((candidate) => {
                    const hours = parseInt(candidate.extendedTime.format('HH'));
                    const minutes = parseInt(candidate.extendedTime.format('mm'));
                    return {
                        id: candidate.id,
                        extendedMinutes: minutes + (hours * 60)
                    }
                })
            }
            const res = await extendTimeOfCandidates(body);
            if (res.code === 'OK') {
                addNotification('Candidates Time updated successfully.', 'success');
                setCandidates(
                    candidates.map((candidate) => {
                        candidate.isSelected = false;
                        candidate.extendedTime = dayjs.utc().hour(0).minute(0).second(0).millisecond(0);
                        candidate.extendedTimeErr = null;
                        return candidate;
                    })
                )
                setGeneralSelected(false);
                setGeneralUpdateTime(dayjs.utc().hour(0).minute(0).second(0).millisecond(0));
            }
        } catch (error) {
            const code = error?.response?.data?.code;
            if (code === 'E_INTERNAL_SERVER_ERROR') {
                addNotification('Candidates time does not updated.', 'danger');
            }
            console.log('There is an error while creating extended times', error);
        } finally {
            setIsUpdating(false);
        }
    }

    const handleApproveCandidateExtendedTime = async () => {
        const approvedTimes = requestedCandidates.filter((candidate) => candidate.isSelected && !candidate.isApproved);
        if (!approvedTimes.length) return;
        setIsApproving(true);
        try {
            const body = {
                extendedTimeIds: approvedTimes.map((at) => at.id)
            }
            const response = await approveRequestedExtendedTimes(body);
            if (response.code === 'OK') {
                addNotification('Requested times approved successfully.', 'success');
                setUpdateCandidates(!updateCandidates);
            }
            console.log(response);
        } catch (error) {
            console.log('There is an error while approving candidate extended times.', error);
        } finally {
            setIsApproving(false);
        }
    }

    return <MainContainer>
        <Menu>
            <Breadcrumbs aria-label="breadcrumb" separator='' sx={{ gap: 12 }}>
                <Typography color="text.primary" sx={{
                    fontWeight: 400,
                    textTransform: 'capitalize'
                }}>
                    {projectDetails?.projectName}
                </Typography>
                <Box sx={{ display: 'flex', alignContent: 'center' }}>
                    <img src={ARROW} alt=''></img>
                </Box>
                <Typography color="text.primary" sx={{
                    fontWeight: 400,
                    textTransform: 'capitalize'
                }}>
                    Update Time
                </Typography>
                <Box sx={{ display: 'flex', alignContent: 'center' }}>
                    <img src={ARROW_OPEN} alt=''></img>
                </Box>
            </Breadcrumbs>
        </Menu>

        <Container sx={{
            display: 'flex',
            flexDirection: 'column'
        }}>
            <h1 className="title" style={{ marginBottom: '12px' }}>Update time</h1>
            <Box sx={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: 2,
                py: 3,
            }}>
                <FormControl className='form-field' sx={{ width: 222 }}>
                    <InputLabel id="driveName">Drive Name</InputLabel>
                    <Select
                        labelId="driveName"
                        id="drive"
                        label='Drive Name'
                        name='drive'
                        error={Boolean(errors.drive)}
                        size='small'
                        onBlur={() => trigger('drive')}
                        value={selectedDrive?.id ?? 0}
                        onChange={(e, event) => {
                            const drive = drives.find((d) => d.id === event.props.value);
                            setSelectedDrive(drive);
                            setValue('drive', event.props.value);
                        }}
                    >
                        {
                            drives.map(
                                (drive) => <MenuItem key={drive.id} value={drive.id}>
                                    {drive.driveName}
                                </MenuItem>
                            )
                        }
                    </Select>
                    {errors.drive?.message
                        && <FormHelperText sx={{ color: 'error.main' }}>Please select drive name.</FormHelperText>
                    }
                </FormControl>

                <FormControl className='form-field' sx={{ width: 222 }}>
                    <InputLabel id="courseName">Course Name</InputLabel>
                    <Select
                        labelId="courseName"
                        id="course"
                        label='Course Name'
                        name='course'
                        error={Boolean(errors.course)}
                        size='small'
                        onBlur={() => trigger('course')}
                        value={selectedCourse?.id ?? 0}
                        onChange={(e, event) => {
                            const course = courses.find((c) => c.id === event.props.value);
                            setSelectedCourse(course);
                            setValue('course', event.props.value);
                        }}
                    >
                        {
                            courses.map(
                                (course) => <MenuItem key={course.id} value={course.id}>
                                    {course.courseName}
                                </MenuItem>
                            )
                        }
                    </Select>
                    {errors.course?.message
                        && <FormHelperText sx={{ color: 'error.main' }}>Please select course name.</FormHelperText>
                    }
                </FormControl>

                <FormControl className='form-field' sx={{ width: 222 }}>
                    <InputLabel id="batchName">Batch Name</InputLabel>
                    <Select
                        labelId="batchName"
                        id="batch"
                        label='Batch Name'
                        name='batch'
                        error={Boolean(errors.batch)}
                        size='small'
                        onBlur={() => trigger('batch')}
                        value={selectedBatch?.id ?? 0}
                        onChange={(e, event) => {
                            const batch = batches.find((b) => b.id === event.props.value);
                            setSelectedBatch(batch);
                            setValue('batch', event.props.value);
                        }}
                    >
                        {
                            batches.map(
                                (batch) => <MenuItem key={batch.id} value={batch.id}>
                                    {batch.batchName}
                                </MenuItem>
                            )
                        }
                    </Select>
                    {errors.batch?.message
                        && <FormHelperText sx={{ color: 'error.main' }}>Please select batch name.</FormHelperText>
                    }
                </FormControl>

                <FormControl className='form-field' sx={{ width: 222 }}>
                    <InputLabel id="examDate">Exam Date</InputLabel>
                    <Select
                        labelId="examDate"
                        id="examDate"
                        label='Exam Date'
                        name='examDate'
                        error={Boolean(errors.examDate)}
                        size='small'
                        onBlur={() => trigger('examDate')}
                        value={selectedExamDate?.id ?? 0}
                        onChange={(e, event) => {
                            const examDate = examDates.find((ed) => ed.id === event.props.value);
                            setSelectedExamDate(examDate);
                            setValue('examDate', event.props.value);
                        }}
                    >
                        {
                            examDates.map(
                                (ed) => <MenuItem key={ed.id} value={ed.id}>
                                    {ed.displayExamDate}
                                </MenuItem>
                            )
                        }
                    </Select>
                    {errors.examDate?.message
                        && <FormHelperText sx={{ color: 'error.main' }}>Please select exam date.</FormHelperText>
                    }
                </FormControl>
                {
                    selectedExamDate.id
                        ? <FormControl className='form-field' sx={{ width: 222 }}>
                            <InputLabel id="examHall">Exam Hall</InputLabel>
                            <Select
                                labelId="examHall"
                                id="examHall"
                                label='Exam Hall'
                                name='examHall'
                                // error={Boolean(errors.examHall)}
                                size='small'
                                // onBlur={() => trigger('examHall')}
                                value={selectedExamHall?.id ?? 0}
                                onChange={handleExamHallChange}
                            >
                                {
                                    availableExamHalls.map(
                                        (ed) => <MenuItem key={ed.id} value={ed.id}>
                                            {ed.hallName}
                                        </MenuItem>
                                    )
                                }
                            </Select>
                        </FormControl>
                        : null
                }
            </Box>

            <Tabs variant='scrollable' value={tabIndex} onChange={(e, value) => {
                setSearchTerm('');
                setSelectedStatus('all');
                setTabIndex(value)
            }}>
                <Tab
                    sx={{
                        textTransform: 'capitalize',
                        fontSize: 15,
                        fontWeight: 600,
                        borderTopLeftRadius: 12,
                        borderTopRightRadius: 12,
                    }}
                    label='Approve'
                    id='approveTabPanel'
                    aria-controls='approveTabPanelWrapper'
                />
                <Tab
                    sx={{
                        textTransform: 'capitalize',
                        fontSize: 15,
                        fontWeight: 600,
                        borderTopLeftRadius: 12,
                        borderTopRightRadius: 12,
                    }}
                    label='Requested'
                    id='requestedTabPanel'
                    aria-controls='requestedTabPanelWrapper'
                />
            </Tabs>
            <Paper sx={{
                borderRadius: 3,
                bgcolor: 'transparent',
                border: '1px solid !important',
                borderColor: '#3F41D1 !important',
                borderTopLeftRadius: tabIndex ? 12 : 0,
                boxShadow: 'unset',
                flex: 1,
                maxWidth: 'calc(100vw - 140px)',
                height: 'auto',
                overflow: 'auto',
            }}>
                <Box
                    role='tabpanel'
                    hidden={tabIndex !== 0}
                    id='approveTabPanelWrapper'
                    sx={{ overflow: 'auto', p: 2, height: '100%' }}
                    area-labelledby='approveTabPanelWrapper'
                >
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: 3,
                        height: 46
                    }}>
                        <Typography component='h5' variant="subtitle1" sx={{ color: 'primary.main', fontWeight: 500 }}>Update time for all candidates?</Typography>
                        <RadioGroup
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: 1,
                            }}
                            defaultValue={'Yes'}
                            onChange={(e, value) => {
                                setUpdateTime(dayjs.utc().hour(0).minute(0).second(0).millisecond(0));
                                setUpdateTimeErr(null);
                                setForAllCandidates(value);
                            }}
                        >
                            <FormControlLabel value='Yes' control={<Radio size='small' checked={forAllCandidates === 'Yes'} />} label="Yes" />
                            <FormControlLabel value='No' control={<Radio size='small' checked={forAllCandidates === 'No'} />} label="No" />
                        </RadioGroup>
                        {
                            tabIndex === 0 && forAllCandidates === 'No' && <>
                                <div className='search-wrapper' style={{ marginLeft: 'auto', marginRight: 'unset' }}>
                                    <input type='text' className='search-box' value={searchTerm} onChange={handleSearchTermChange} placeholder='Search...' />
                                    <img className='search-icon' src={SEARCH_ICON} alt='' />
                                </div>
                            </>
                        }
                    </Box>

                    {forAllCandidates === 'Yes'
                        ? <Box sx={{ mt: 3, display: 'flex', flexDirection: 'row', alignItems: 'flex-start', flexWrap: 'wrap', gap: 2, }}>
                            <Typography>
                                Enter time
                                <Typography component='span' sx={{ ml: 0.5, fontSize: 14, color: 'gray' }}>(hours:minutes)</Typography>
                            </Typography>
                            <TimeField
                                sx={{ width: 160 }}
                                size="small"
                                value={updateTime}
                                format="HH:mm"
                                onChange={(d, e) => handleTimeDurationChange(d, e)}
                                error={!!updateTimeErr}
                                slotProps={{
                                    textField: {
                                        helperText: updateTimeErr,
                                    }
                                }}
                            />
                            <LoadingButton
                                sx={{ borderRadius: 40 }}
                                disabled={!!updateTimeErr || !selectedDrive.id || !selectedCourse.id || !selectedBatch.id || !selectedExamDate.id}
                                color='secondary'
                                variant='contained'
                                onClick={handleUpdateTimeForAllCandidates}
                                loading={isUpdating}
                            >
                                Update
                            </LoadingButton>
                        </Box>
                        : <>
                            <Paper sx={{
                                width: '100%',
                                overflow: 'auto',
                                bgcolor: 'background.base',
                                maxHeight: 'calc(100% - 125px)',
                                maxWidth: 'calc(100vw - 140px)',
                                borderRadius: 1,
                                border: '1px solid',
                                borderColor: 'primary.main',
                                mb: 3,
                                mt: 2
                            }}>
                                <TableContainer sx={{
                                    px: 1,
                                    pb: 1,
                                    maxHeight: 'inherit',
                                    maxWidth: 'inherit',
                                    borderRadius: 1
                                }}>
                                    <Table stickyHeader>
                                        <TableHead>
                                            <TableRow>
                                                {columns.map((column) => {
                                                    switch (column.id) {
                                                        case 'selected':
                                                            return <TableCell
                                                                sx={{
                                                                    color: 'primary.main',
                                                                    p: 1.5,
                                                                    '.MuiCheckbox-root': { p: 1 }
                                                                }}
                                                                key={column.id}
                                                                align='center'
                                                                style={{ minWidth: column.minWidth }}
                                                            >
                                                                <Checkbox checked={generalSelected} onChange={handleGeneralSelectedChange} />
                                                            </TableCell>
                                                        case 'extendTime':
                                                            return <TableCell
                                                                sx={{
                                                                    color: 'primary.main',
                                                                    p: 1.5,
                                                                }}
                                                                key={column.id}
                                                                align='center'
                                                                style={{ minWidth: column.minWidth }}
                                                            >
                                                                <TimeField
                                                                    sx={{ width: 160 }}
                                                                    size="small"
                                                                    value={generalUpdateTime}
                                                                    format="HH:mm"
                                                                    onChange={(d, e) => handleGeneralUpdateTimeChange(d, e)}
                                                                />
                                                            </TableCell>
                                                        default:
                                                            return <TableCell
                                                                sx={{
                                                                    color: 'primary.main',
                                                                    p: 1.5,
                                                                }}
                                                                key={column.id}
                                                                align='center'
                                                                style={{ minWidth: column.minWidth }}
                                                            >
                                                                {column.label}
                                                            </TableCell>
                                                    }
                                                })}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {!candidates?.length
                                                ? <TableRow sx={{ mx: 'auto', p: 2, color: 'grey.500' }} selected={true}>
                                                    <TableCell colSpan={5} sx={{ border: 'none', textAlign: 'center' }}>
                                                        No records available
                                                    </TableCell>
                                                </TableRow>
                                                : candidates.map((row, rowIndex) => {
                                                    return (
                                                        <TableRow hover role="checkbox" key={rowIndex} selected={true}>
                                                            {columns.map((column, index) => {
                                                                return <TableCell key={column.id} align='center' sx={{
                                                                    px: 1.5,
                                                                    py: 0.75
                                                                }}>
                                                                    {renderTableCell(column.id, rowIndex)}
                                                                </TableCell>
                                                            })}
                                                        </TableRow>
                                                    );
                                                })
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                gap: 1,
                            }}>
                                <LoadingButton
                                    sx={{ borderRadius: 40 }}
                                    disabled={!selectedDrive.id || !selectedCourse.id || !selectedBatch.id || !selectedExamDate.id || !candidates?.length}
                                    color='secondary'
                                    variant='contained'
                                    onClick={handleUpdateTimeForCandidates}
                                    loading={isUpdating}
                                >
                                    Update
                                </LoadingButton>
                                {
                                    candidates?.length
                                        ? <Pagination sx={{ mt: 'auto' }} count={maxPages} page={currentPage} onChange={(e, value) => handlePageChange(value)} shape="rounded" />
                                        : null
                                }
                            </Box>
                        </>
                    }

                </Box>
                <Box
                    role='tabpanel'
                    hidden={tabIndex !== 1}
                    id='requestedTabPanelWrapper'
                    sx={{ overflow: 'auto', p: 2, height: '100%' }}
                    area-labelledby='requestedTabPanelWrapper'
                >
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        gap: 2,
                    }}>
                        <FormControl className='form-field' sx={{ width: 222 }}>
                            <InputLabel id="extendedTimeStatus">Status</InputLabel>
                            <Select
                                labelId="extendedTimeStatus"
                                id="status"
                                label='Status'
                                name='status'
                                size='small'
                                sx={{ textTransform: 'capitalize' }}
                                // error={Boolean(errors.batch)}
                                // onBlur={() => trigger('batch')}
                                value={selectedStatus}
                                onChange={(e, event) => {
                                    setSelectedStatus(event.props.value);
                                    setUpdateCandidates(!updateCandidates);
                                }}
                            >
                                {
                                    statusOptions.map(
                                        (status) => <MenuItem sx={{ textTransform: 'capitalize' }} key={status} value={status}>
                                            {status}
                                        </MenuItem>
                                    )
                                }
                            </Select>
                        </FormControl>
                        <div className='search-wrapper' style={{ marginRight: 'unset' }}>
                            <input type='text' className='search-box' value={searchTerm} onChange={handleSearchTermChange} placeholder='Search...' />
                            <img className='search-icon' src={SEARCH_ICON} alt='' />
                        </div>
                    </Box>
                    <Paper sx={{
                        width: '100%',
                        overflow: 'auto',
                        bgcolor: 'background.base',
                        maxHeight: 'calc(100% - 125px)',
                        maxWidth: 'calc(100vw - 140px)',
                        borderRadius: 1,
                        border: '1px solid',
                        borderColor: 'primary.main',
                        mb: 3,
                        mt: 2
                    }}>
                        <TableContainer sx={{
                            px: 1,
                            pb: 1,
                            maxHeight: 'inherit',
                            maxWidth: 'inherit',
                            borderRadius: 1
                        }}>
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        {requestedColumns.map((column) => {
                                            switch (column.id) {
                                                case 'selected':
                                                    return <TableCell
                                                        sx={{
                                                            color: 'primary.main',
                                                            p: 1.5,
                                                            '.MuiCheckbox-root': { p: 1 }
                                                        }}
                                                        key={column.id}
                                                        align='center'
                                                        style={{ minWidth: column.minWidth }}
                                                    >
                                                        <Checkbox checked={generalSelected} onChange={handleGeneralSelectedChange} />
                                                    </TableCell>
                                                default:
                                                    return <TableCell
                                                        sx={{
                                                            color: 'primary.main',
                                                            p: 1.5,
                                                        }}
                                                        key={column.id}
                                                        align='center'
                                                        style={{ minWidth: column.minWidth }}
                                                    >
                                                        {column.label}
                                                    </TableCell>
                                            }
                                        })}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {!requestedCandidates?.length
                                        ? <TableRow sx={{ mx: 'auto', p: 2, color: 'grey.500' }} selected={true}>
                                            <TableCell colSpan={5} sx={{ border: 'none', textAlign: 'center' }}>
                                                No records available
                                            </TableCell>
                                        </TableRow>
                                        : requestedCandidates.map((row, rowIndex) => {
                                            return (
                                                <TableRow hover role="checkbox" key={rowIndex} selected={true}>
                                                    {requestedColumns.map((column, index) => {
                                                        return <TableCell key={column.id} align='center' sx={{
                                                            px: 1.5,
                                                            py: 1
                                                        }}>
                                                            {renderRequestedTableCell(column.id, rowIndex)}
                                                        </TableCell>
                                                    })}
                                                </TableRow>
                                            );
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        gap: 1,
                    }}>
                        {selectedStatus !== 'approved' && <LoadingButton
                            sx={{ borderRadius: 40 }}
                            disabled={!selectedDrive.id || !selectedCourse.id || !selectedBatch.id || !selectedExamDate.id || !requestedCandidates?.length}
                            color='secondary'
                            variant='contained'
                            onClick={handleApproveCandidateExtendedTime}
                            loading={isApproving}
                        >
                            Approve
                        </LoadingButton>}
                        {
                            requestedCandidates?.length
                                ? <Pagination sx={{ ml: 'auto', mt: 'auto' }} count={maxPages} page={currentPage} onChange={(e, value) => handlePageChange(value)} shape="rounded" />
                                : null
                        }
                    </Box>
                </Box>
            </Paper>

        </Container>

    </MainContainer >
}

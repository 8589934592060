import { useContext, useState } from 'react';
import { Box, Breadcrumbs, Typography, TextField, InputAdornment, IconButton, FormControl, InputLabel, OutlinedInput, FormHelperText } from '@mui/material';

import { Container, MainContainer } from '../../../../components';
import { Menu } from '../Menu/Menu';
import { useToaster } from '../../../../providers/ToasterProvider';

import ARROW from '../../../../assets/images/breadcrumb-arrow.svg';
import ARROW_OPEN from '../../../../assets/images/breadcrumb-arrow-open.svg';
import EYE_ICON from '../../../../assets/images/eye.svg';
import EYE_CLOSE_ICON from '../../../../assets/images/eye-close.svg'

import { z } from 'zod';
import { ProjectContext } from '../../../../providers/projectProvider';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { changePassword } from '../../../../services/authService';
import { AuthContext } from '../../../../providers/auth';
import patterns from '../../../../constant/patterns';

export const ChangePassword = () => {

    const { projectDetails } = useContext(ProjectContext);
    const { addNotification } = useToaster();
    const { user } = useContext(AuthContext);
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [newPasswordVisible, setNewPasswordVisible] = useState(false);
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

    const passwordSchema = z.object({
        password: z.string().min(8, 'Password should be at least 8 characters long'),
        newPassword: z.string()
            .min(8, 'Password should be at least 8 characters long')
            .refine((value) => patterns.PASSWORD.test(value ?? ""), 'Password must contain one uppercase character, one lowercase character, one digit and one special character'),
        confirmPassword: z.string(),
    }).superRefine(({ password, newPassword, confirmPassword }, ctx) => {
        if (newPassword === password) {
            ctx.addIssue({
                code: 'custom',
                message: 'New password must be different from existing one',
                path: ['newPassword']
            });
        }
        if (newPassword !== confirmPassword) {
            ctx.addIssue({
                code: 'custom',
                message: 'Passwords did not match',
                path: ['confirmPassword']
            });
        }
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
        trigger,
        reset,
    } = useForm({
        resolver: zodResolver(passwordSchema),
    });

    const submitForm = async (values) => {
        try {
            const res = await changePassword(values);
            if (res.code === 'OK') {
                addNotification('Password has been changed successfully.', 'success');
            }
            reset();
        } catch (error) {
            const code = error?.response?.data?.code;
            if (code === 'E_BAD_REQUEST') {
                addNotification('password does not match.', 'danger');
            } else {
                addNotification('unexpected error occured.', 'danger');
            }
            console.log('There is an error while changing password', error);
        }
    }

    return (
        <MainContainer sx={{ bgcolor: 'red' }}>
            <Menu>
                <Breadcrumbs aria-label="breadcrumb" separator='' sx={{ gap: 12 }}>
                    <Typography color="text.primary" sx={{ fontWeight: 400, textTransform: 'capitalize' }}>{projectDetails?.projectName}</Typography>
                    <Box sx={{ display: 'flex', alignContent: 'center' }}>
                        <img src={ARROW_OPEN} alt=''></img>
                    </Box>
                    {/* <Typography color="text.primary" sx={{ fontWeight: 400, textTransform: 'capitalize' }}>
                        {blocker.state}
                    </Typography> */}

                    {/* <Typography color="text.primary" sx={{ fontWeight: 500, textTransform: 'capitalize' }}>Change Password</Typography>
                    <Box sx={{ display: 'flex', alignContent: 'center' }}>
                        <img src={ARROW_OPEN} alt=''></img>
                    </Box> */}
                </Breadcrumbs>
            </Menu>
            <Container sx={{
                display: 'flex',
                flexDirection: 'column',
            }}>

                <Typography component='h5' variant='h5' sx={{ mb: 3 }}>Change Password</Typography>

                <Box sx={{
                    bgcolor: 'background.base',
                    borderRadius: 6,
                    p: 4
                }} >
                    <Box component='form' onSubmit={handleSubmit(submitForm)} sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2
                    }}>

                        <FormControl variant='outlined' sx={{ width: '100%', maxWidth: 500 }}>
                            <InputLabel htmlFor="password">Password</InputLabel>
                            <OutlinedInput
                                id='password'
                                type={passwordVisible ? 'text' : 'password'}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setPasswordVisible(!passwordVisible)}
                                            edge="end"
                                        >
                                            <img className='password-eye' src={passwordVisible ? EYE_CLOSE_ICON : EYE_ICON} alt='' />
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="Password"
                                name="password"
                                {...register('password')}
                                error={Boolean(errors.password)}
                                onBlur={() => trigger('password')}
                            />
                            {errors?.password?.message && (
                                <FormHelperText sx={{ color: 'error.main' }}>
                                    {errors.password?.message}
                                </FormHelperText>
                            )}
                        </FormControl>

                        <FormControl variant='outlined' sx={{ width: '100%', maxWidth: 500 }}>
                            <InputLabel htmlFor="newPasswordLabel">New Password</InputLabel>
                            <OutlinedInput
                                id='newPasswordLabel'
                                type={newPasswordVisible ? 'text' : 'password'}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setNewPasswordVisible(!newPasswordVisible)}
                                            edge="end"
                                        >
                                            <img className='password-eye' src={newPasswordVisible ? EYE_CLOSE_ICON : EYE_ICON} alt='' />
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="New Password"
                                name="newPassword"
                                {...register('newPassword')}
                                error={Boolean(errors.newPassword)}
                                onBlur={() => trigger('newPassword')}
                            />
                            {errors?.newPassword?.message && (
                                <FormHelperText sx={{ color: 'error.main' }}>
                                    {errors.newPassword?.message}
                                </FormHelperText>
                            )}
                        </FormControl>

                        <FormControl variant='outlined' sx={{ width: '100%', maxWidth: 500 }}>
                            <InputLabel htmlFor="confirmPasswordLabel">Confirm Password</InputLabel>
                            <OutlinedInput
                                id='confirmPasswordLabel'
                                type={confirmPasswordVisible ? 'text' : 'password'}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setConfirmPasswordVisible(!confirmPasswordVisible)}
                                            edge="end"
                                        >
                                            <img className='password-eye' src={confirmPasswordVisible ? EYE_CLOSE_ICON : EYE_ICON} alt='' />
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="Confirm Password"
                                name="confirmPassword"
                                {...register('confirmPassword')}
                                error={Boolean(errors.confirmPassword)}
                                onBlur={() => trigger('confirmPassword')}
                            />
                            {errors?.confirmPassword?.message && (
                                <FormHelperText sx={{ color: 'error.main' }}>
                                    {errors.confirmPassword?.message}
                                </FormHelperText>
                            )}
                        </FormControl>

                        <LoadingButton sx={{
                            textTransform: 'uppercase',
                            borderRadius: 40,
                            alignSelf: 'flex-start',
                            mt: 2,
                        }} color='primary'
                            type="submit"
                            variant='contained'
                            size='large'
                        >
                            Change Password
                        </LoadingButton>
                    </Box>
                </Box>
            </Container>
        </MainContainer >
    );
}

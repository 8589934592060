import { useEffect, useState } from 'react';
import { Box, CssBaseline } from '@mui/material';
import {  Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';

import ProjectContextProvider from '../../../../providers/projectProvider';
import { SidebarMenu } from '../../../../components/SidebarMenu/SidebarMenu';
import { Toaster } from '../../../../components';

import './AdminLayout.scss';

export const AdminLayout = () => {
    const [isFloating, setIsFloating] = useState(true);
    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const fragments = location.pathname.split('/');
        if (location.pathname.includes('/login')) {
            setIsFloating(true);
        } else if ((fragments.length === 2 && fragments[1] === params?.projectName)) {
            setIsFloating(true);
        } else if (fragments.length === 3 && fragments[1] === params?.projectName && (fragments[2] === 'dashboard' || fragments[2] === 'reset-password' || !fragments[2])) {
            setIsFloating(true);
        } else {
            setIsFloating(false);
        }
    }, [location, params, navigate]);

    return (
        <ProjectContextProvider>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />

                <SidebarMenu isFloating={isFloating} />

                <Toaster />
                <Outlet />
            </Box>
        </ProjectContextProvider>
    );
}

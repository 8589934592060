const moduleTypes = [
    {
        code: 101,
        key: 'CONTENT_ADMIN',
        displayKey: 'Content Admin'
    },
    {
        code: 102,
        key: 'IT_SUPPORT_HEAD',
        displayKey: 'IT Support Head'
    },
    {
        code: 103,
        key: 'BACK_OFFICE',
        displayKey: 'Back Office'
    },
    {
        code: 104,
        key: 'CUSTOMER',
        displayKey: 'Customer'
    }
]

export default moduleTypes;
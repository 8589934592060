import { axios } from "../config/axios";

const endPoint = '/admin/projects';

const createProject = async (formData) => {
    return axios.post(`${endPoint}`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
}

const getPublicList = async () => {
    return axios.get(`${endPoint}/public-list`);
}

const getListByPage = async (search, pageNumber, limit) => {
    return axios.post(`${endPoint}/pagination`, {
        search: search,
        page: pageNumber,
        limit: limit
    });
}

const updateById = async (projectId, body) => {
    return axios.put(`${endPoint}/${projectId}`, body);
}

const getDetailsById = async (projectId) => {
    return axios.get(`${endPoint}/${projectId}`);
}

const getDetailsByShortName = async (projectShortName) => {
    return axios.get(`${endPoint}/by-short-name/${projectShortName}`);
}

const getDetailsByEncryptedShortName = async (projectShortName) => {
    return axios.get(`${endPoint}/by-encrypted-short-name/${projectShortName}`);
}

export {
    createProject,
    getListByPage,
    updateById,
    getDetailsById,
    getPublicList,
    getDetailsByShortName,
    getDetailsByEncryptedShortName
}
import { Fragment, useState } from 'react';
import { Box, Typography, Modal, FormControlLabel, Switch, Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';

import { useToaster } from '../../../../providers/ToasterProvider';
import { createBulkUsers, downloadUserTemplate } from '../../../../services/usersService';

import RadiusButton from '../../../../components/RadiusButton';

import DOWNLOAD_BLUE from '../../../../assets/images/download-blue.svg';
import DELETE_ICON from '../../../../assets/images/action-delete.svg';
import { LoadingButton } from '@mui/lab';

export const BulkUserUploadModal = ({ isVisible, setIsVisible, userType, dataUpdated, setDataUpdated }) => {

    const [file, setFile] = useState(null);
    const [sendMail, setSendMail] = useState(false);
    const [uploadResult, setUploadResult] = useState({
        isVisible: false,
        createdRecordsCount: 0,
        errors: []
    });
    const [isUploading, setIsUploading] = useState(false);
    const { addNotification } = useToaster();
    const columns = [
        { id: 'index', label: 'Row Number', minWidth: 140 },
        { id: 'errors', label: 'Errors', minWidth: 190 },
    ];


    const handleFileChange = ({ target }) => {
        const file = target?.files[0];
        if (file && file?.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
            setFile(file);
        }
    }
    const handleFileRemove = () => {
        const element = document.getElementById('userFile');
        if (element) {
            element.value = '';
            setFile(null);
        }
    }
    const handleModalClose = () => {
        setIsVisible(false);
        setFile(null);
        if (uploadResult.createdRecordsCount > 0) {
            setDataUpdated(!dataUpdated);
        }
        setUploadResult({
            isVisible: false,
            createdRecordsCount: 0,
            errors: []
        })
        const ele = document.getElementById('userFile');
        if (ele) {
            ele.value = '';
        }
    }
    const handleDownloadTemplate = async () => {
        try {
            const res = await downloadUserTemplate();
            const url = window.URL.createObjectURL(res.data);

            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
                'download',
                `users-template.xlsx`,
            );
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            addNotification('File downloaded successfully.', 'success');
        } catch (error) {
            console.log('There is an error while downloading user template.', error);
        }
    }
    const handleFileUpload = async () => {
        if (!file || !userType) return;
        setIsUploading(true);
        try {
            const body = new FormData();
            body.append('userType', userType);
            body.append('sendMail', sendMail);
            body.append('file', file);
            const res = await createBulkUsers(body);
            setUploadResult({
                isVisible: true,
                createdRecordsCount: res.data.createdRecordsCount,
                errors: res.data.errors
            });
            if (res.data.createdRecordsCount > 0) {
                setDataUpdated(!dataUpdated);
            }
            const ele = document.getElementById('userFile');
            if (ele) {
                ele.value = '';
            }
        } catch (error) {
            console.log('There is an error while uploading bulk users', error);
        } finally {
            setIsUploading(false);
        }
    }

    const renderTableCell = (columnId, rowIndex) => {
        const row = uploadResult.errors[rowIndex];
        switch (columnId) {
            case 'index':
                return row['recordIndex']
            case 'errors':
                return <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: 1,
                }}>
                    {row['errors'].map((err, index) => {
                        return <Typography key={index}>
                            {err}
                        </Typography>
                    })}

                </Box>
            default:
                return row[columnId];
        }
    }

    return (
        <Fragment>
            <Modal
                hideBackdrop={true}
                className='backdrop'
                open={isVisible}
                onClose={handleModalClose}
            >
                <Box className='modal-container' sx={{
                    maxWidth: 640,
                    p: 3,
                }}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        mb: 3,
                        gap: 1,
                    }}>
                        <Typography variant="h6" component="h6" sx={{
                            color: 'primary.main'
                        }}>
                            Bulk upload users
                        </Typography>
                        <RadiusButton
                            variant='outlined'
                            startIcon={<img src={DOWNLOAD_BLUE} />}
                            onClick={handleDownloadTemplate}
                        >
                            Download Template
                        </RadiusButton>
                    </Box>

                    <Box sx={{
                        pb: 2,
                        overflow: 'auto',
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                    }}>
                        <RadiusButton
                            variant='contained'
                            component='label'
                            htmlFor='userFile'
                            color='secondary'
                        >
                            Select File
                        </RadiusButton>
                        <input
                            style={{ display: 'none' }}
                            type='file'
                            id='userFile'
                            onChange={handleFileChange}
                            accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                        />
                        {
                            file && file?.name && <Fragment>
                                <Typography variant='subtitle1' >{file.name}</Typography>
                                <img
                                    style={{ cursor: 'pointer' }}
                                    src={DELETE_ICON}
                                    onClick={handleFileRemove}
                                />
                            </Fragment>
                        }
                    </Box>
                    <FormControlLabel
                        sx={{
                            mb: 2
                        }}
                        control={
                            <Switch
                                checked={sendMail}
                                onChange={(e, value) => setSendMail(value)}
                            />
                        }
                        label="Send mail"
                    />

                    {uploadResult.isVisible && <>
                        <Typography sx={{ fontSize: 17, mb: 2 }}>
                            Successfully created users count: {uploadResult.createdRecordsCount}
                        </Typography>
                        {uploadResult.errors?.length ? <>
                            <Typography sx={{ fontSize: 17, mb: 1 }}>Errors: </Typography>
                            <Paper sx={{
                                width: '100%',
                                overflow: 'hidden',
                                bgcolor: 'background.base',
                                maxHeight: 250,
                                borderRadius: 1,
                                mb: 4
                            }}>
                                <TableContainer sx={{
                                    px: 1,
                                    pb: 1,
                                    maxHeight: 'inherit',
                                    maxWidth: 'inherit',
                                    border: '1px solid',
                                    borderColor: 'primary.main',
                                    borderRadius: 1
                                }}>
                                    <Table stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow>
                                                {columns.map((column) => (
                                                    <TableCell
                                                        sx={{
                                                            color: 'primary.main',
                                                            p: 1,
                                                        }}
                                                        key={column.id}
                                                        align='center'
                                                        style={{ minWidth: column.minWidth }}
                                                    >
                                                        {column.label}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {!uploadResult?.errors?.length
                                                ? <TableRow sx={{ mx: 'auto', p: 2, color: 'grey.500' }}>
                                                    <TableCell colSpan={2} sx={{ border: 'none', textAlign: 'center' }}>
                                                        No records available
                                                    </TableCell>
                                                </TableRow>
                                                : uploadResult.errors.map((row, rowIndex) => {
                                                    return (
                                                        <TableRow hover role="checkbox" key={rowIndex}>
                                                            {columns.map((column) => {
                                                                return <TableCell key={column.id} align='center' sx={{
                                                                    px: 1.5,
                                                                    py: 0.75
                                                                }}>
                                                                    {renderTableCell(column.id, rowIndex)}
                                                                </TableCell>
                                                            })}
                                                        </TableRow>
                                                    );
                                                })
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                        </> : null}
                    </>}

                    <Box className='actions-container'>
                        <RadiusButton
                            variant='contained'
                            color='grey'
                            sx={{ ml: 'auto' }}
                            onClick={handleModalClose}
                            disabled={isUploading}
                        >
                            Cancel
                        </RadiusButton>
                        <LoadingButton
                            variant='contained'
                            sx={{
                                borderRadius: 40,
                                textTransform: 'capitalize'
                            }}
                            onClick={handleFileUpload}
                            disabled={!file || isUploading}
                            loading={isUploading}
                        >
                            Upload
                        </LoadingButton>
                    </Box>

                </Box>
            </Modal>
        </Fragment>
    );
}

import { useEffect, useState } from 'react';

import { getById } from '../../../../services/configurationService';
import { UploadBySet } from '../UploadBySet/UploadBySet'
import { UploadByBulk } from '../UploadByBulk/UploadByBulk';


export const ContentUpload = ({ drive, course, configurationId }) => {

    const [contentUploadMethod, setContentUploadMethod] = useState(null);

    useEffect(() => {
        if (configurationId) {
            const fetchConfiguration = async () => {
                try {
                    const res = await getById(configurationId);
                    if (res.code === 'OK') {
                        setContentUploadMethod(res.data.contentUploadMethod);
                    }
                } catch (error) {
                    console.log('There is an error while getting configuration', error);
                }
            }
            fetchConfiguration();
        }
    }, [configurationId]);

    return (
        <>
            {contentUploadMethod && contentUploadMethod === 'bulk'
                ? <UploadByBulk drive={drive} course={course} configurationId={configurationId}></UploadByBulk>
                : <UploadBySet drive={drive} course={course} configurationId={configurationId}></UploadBySet>
            }
        </>
    );
}
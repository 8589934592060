import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Breadcrumbs, Typography, Switch, TextField, FormControlLabel, FormHelperText, FormControl, InputLabel, Select, MenuItem, InputAdornment, IconButton, OutlinedInput } from '@mui/material';
import { Container, MainContainer } from '../../../../components';
import { UserCreationMail } from '../UserCreationMail/UserCreationMail';
import { Menu } from '../Menu/Menu';

import { useToaster } from '../../../../providers/ToasterProvider';
import { getAll as getAllDrives } from '../../../../services/driveService';
import { getAll as getAllCourses } from '../../../../services/courseService';
import { createUser, getById, updateById } from '../../../../services/usersService';

import RadiusButton from '../../../../components/RadiusButton'

import ARROW from '../../../../assets/images/breadcrumb-arrow.svg';
import ARROW_OPEN from '../../../../assets/images/breadcrumb-arrow-open.svg';
import BACK from '../../../../assets/images/back.svg';
import EYE_ICON from '../../../../assets/images/eye.svg';
import EYE_CLOSE_ICON from '../../../../assets/images/eye-close.svg'

import './ContentDetails.scss';
import { LoadingButton } from '@mui/lab';
import { ProjectContext } from '../../../../providers/projectProvider';
import patterns from '../../../../constant/patterns';

export const ContentDetails = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    const projectName = params?.projectName;
    const { addNotification } = useToaster();
    const { projectDetails } = useContext(ProjectContext);
    const [isActive, setIsActive] = useState(true);
    const [mailModal, setMailModal] = useState({
        isOpen: false,
        isUpdated: false,
        cancelPressed: false
    });
    const [userDetails, setUserDetails] = useState(null);
    const [editMode, setEditMode] = useState(false);
    const [driveId, setDriveId] = useState(0);
    const [drives, setDrives] = useState([]);
    const [courseId, setCourseId] = useState(0);
    const [courses, setCourses] = useState([]);
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const contentUserSchema = z.object({
        firstName: z.string().min(1, { message: 'First name is required.' }),
        lastName: z.string().min(1, { message: 'Last name is required.' }),
        email: z.string().min(1, { message: 'Email address is required.' }).email('Please enter valid email address.'),
        password: z.string(),
        drive: z.number().gte(1, { message: 'Please select drive.' }),
        course: z.number().gte(1, { message: 'Please select course.' }),
    }).superRefine(({ password }, ctx) => {
        if ((editMode && password && password.length < 8) || (!editMode && password.length < 8)) {
            ctx.addIssue({
                code: 'custom',
                message: 'Password should be at least 8 characters long',
                path: ['password']
            });
        }
        if ((editMode && password && !patterns.PASSWORD.test(password)) || (!editMode && !patterns.PASSWORD.test(password))) {
            ctx.addIssue({
                code: 'custom',
                message: 'Password must contain one uppercase character, one lowercase character, one digit and one special character',
                path: ['password']
            });
        }
    });

    useEffect(() => {
        const fetchCourses = async () => {
            try {
                const driveRes = await getAllDrives();
                const drivesList = driveRes?.data ?? [];
                setDrives([...drivesList]);
                const courseRes = await getAllCourses();
                const coursesList = courseRes?.data ?? [];
                setCourses([...coursesList]);

            } catch (error) {
                console.log('There is an error while getting courses', error);
            }
        }
        fetchCourses();
    }, [location]);

    const {
        register,
        handleSubmit,
        formState: { errors },
        trigger,
        setValue,
        reset,
    } = useForm({
        resolver: zodResolver(contentUserSchema),
        // defaultValues: {
        //     firstName: 'jayesh',
        //     lastName: 'vora',
        //     email: 'tone@mailinator.com',
        //     password: 'test@123'
        // }
    });

    useEffect(() => {
        if (location?.state?.userId) {
            const fetchContentDetails = async () => {
                try {
                    const res = await getById(location.state.userId);
                    if (res.code === 'OK') {
                        setEditMode(true);
                        setUserDetails(res.data);
                        setValue('firstName', res.data.firstName);
                        setValue('lastName', res.data.lastName);
                        setValue('email', res.data.email);
                        setValue('drive', res.data.driveId);
                        setDriveId(res.data.driveId);
                        setValue('course', res.data.courseId);
                        setCourseId(res.data.courseId);
                        setIsActive(res.data.isActive);
                    }
                } catch (error) {
                    console.log('There is an error while getting content user details', error);
                }
            }
            fetchContentDetails();
        }
    }, [location, setValue]);

    useEffect(() => {
        if (mailModal.cancelPressed) {
            const path = location.pathname.replace('/content-details', '');
            navigate(path);
        }
    }, [mailModal, navigate, location]);

    const submitForm = async (values) => {
        const body = {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            password: values.password,
            driveId: driveId,
            courseId: courseId,
            isActive: isActive,
        }
        if (editMode) {
            try {
                setIsLoading(true);
                if (!body.password) {
                    delete body.password;
                }
                const res = await updateById(userDetails.id, body);
                if (res.code === 'OK') {
                    addNotification('User updated successfully', 'success');
                    if (body.password) {
                        setUserDetails({
                            ...res.data.user,
                            password: values.password
                        });
                        setMailModal({
                            ...mailModal,
                            isUpdated: true,
                            isOpen: true,
                        });
                    } else {
                        onGoBack();
                    }
                }
            } catch (error) {
                console.log('There is an error while updating content user details', error);
            } finally {
                setIsLoading(false);
            }
        } else {
            body.userType = 4;
            setIsLoading(true);
            try {
                const res = await createUser(body);
                if (res.code === 'CREATED') {
                    const data = {
                        ...res.data.user,
                        password: values.password
                    }
                    setUserDetails(data);
                    setMailModal({
                        ...mailModal,
                        isUpdated: false,
                        isOpen: true,
                    });
                    addNotification('User added successfully.', 'success');
                    reset();
                }

            } catch (error) {
                if (error?.response?.data?.data?.code === 'E_DUPLICATE') {
                    addNotification('User email address already exists.', 'danger');
                }
                console.log('There is an error while creating content user', error);
            } finally {
                setIsLoading(false);
            }
        }

    }

    const onGoBack = () => {
        if (projectName) {
            navigate(`/${projectName}/content`);
        }
    }

    const handleActiveChange = () => {
        setIsActive(!isActive);
    }

    return (
        <MainContainer sx={{ bgcolor: 'red' }}>
            <Menu>
                <Breadcrumbs aria-label="breadcrumb" separator='' sx={{ gap: 12 }}>
                    <Typography color="text.primary" sx={{
                        fontWeight: 400,
                        textTransform: 'capitalize'
                    }}>{projectDetails?.projectName}</Typography>
                    <Box sx={{ display: 'flex', alignContent: 'center' }}>
                        <img src={ARROW} alt=''></img>
                    </Box>

                    <Typography color="text.primary" sx={{ fontWeight: 500 }}>Content</Typography>
                    <Box sx={{ display: 'flex', alignContent: 'center' }}>
                        <img src={ARROW_OPEN} alt='' ></img>
                    </Box>
                </Breadcrumbs>
            </Menu>
            <Container sx={{
                display: 'flex',
                flexDirection: 'column',
            }}>

                <Typography component='h5' variant='h5' sx={{
                    mb: 3
                }}>
                    Create Content User
                </Typography>

                <Box sx={{
                    bgcolor: 'background.base',
                    borderRadius: 6,
                    p: 4
                }} className='create-user-form-wrapper'>
                    <Box component='form' onSubmit={handleSubmit(submitForm)}>
                        <div className="user-form-details">

                            <TextField
                                id='firstName'
                                name='firstName'
                                className='form-field'
                                label={
                                    <>
                                        <Box component='span'>First Name</Box>
                                        <Box component='span' sx={{ color: 'error.main', ml: 0.25 }}>*</Box>
                                    </>
                                }
                                variant='outlined'
                                {...register('firstName')}
                                error={Boolean(errors.firstName)}
                                helperText={errors.firstName?.message}
                                onBlur={() => trigger('firstName')}
                                InputLabelProps={editMode ? { shrink: true } : {}}
                            />

                            <TextField
                                id='lastName'
                                name='lastName'
                                className='form-field'
                                label={
                                    <>
                                        <Box component='span'>Last Name</Box>
                                        <Box component='span' sx={{ color: 'error.main', ml: 0.25 }}>*</Box>
                                    </>
                                }
                                variant='outlined'
                                {...register('lastName')}
                                error={Boolean(errors.lastName)}
                                helperText={errors.lastName?.message}
                                onBlur={() => trigger('lastName')}
                                InputLabelProps={editMode ? { shrink: true } : {}}
                            />

                            <TextField
                                id='email'
                                name='email'
                                className='form-field'
                                label={
                                    <>
                                        <Box component='span'>Email Id</Box>
                                        <Box component='span' sx={{ color: 'error.main', ml: 0.25 }}>*</Box>
                                    </>
                                }
                                variant='outlined'
                                {...register('email')}
                                error={Boolean(errors.email)}
                                helperText={errors.email?.message}
                                onBlur={() => trigger('email')}
                                InputLabelProps={editMode ? { shrink: true } : {}}
                                disabled={editMode ? true : false}
                            />

                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column'
                            }}>
                                <FormControl variant='outlined' sx={{ mb: 2 }}>
                                    <InputLabel htmlFor="passwordLabel">Password</InputLabel>
                                    <OutlinedInput
                                        id='passwordLabel'
                                        type={passwordVisible ? 'text' : 'password'}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => setPasswordVisible(!passwordVisible)}
                                                    edge="end"
                                                >
                                                    <img className='password-eye' src={passwordVisible ? EYE_CLOSE_ICON : EYE_ICON} alt='' />
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        label="Password"
                                        name="password"
                                        {...register('password')}
                                        error={Boolean(errors.password)}
                                        onBlur={() => trigger('password')}
                                    />
                                    {errors?.password?.message && (
                                        <FormHelperText sx={{ color: 'error.main' }}>
                                            {errors.password?.message}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                                {editMode && <FormHelperText sx={{ mt: '-10px', color: 'primary.main', mb: 1.5 }}>
                                    Enter password only if you wish to update.
                                </FormHelperText>}
                            </Box>


                            <FormControl sx={{
                                // maxWidth: 600,
                                flexGrow: 1
                            }}>
                                <InputLabel id="driveNameLabel">Drive Name<Box component='span' sx={{ color: 'error.main', ml: 0.25 }}>*</Box></InputLabel>
                                <Select
                                    labelId="driveNameLabel"
                                    id="drive"
                                    label='Drive Name*'
                                    margin='none'
                                    name='drive'
                                    error={Boolean(errors.drive)}
                                    onBlur={() => trigger('drive')}
                                    onChange={(e, value) => {
                                        setDriveId(value.props.value)
                                        setValue('drive', value.props.value)
                                    }}
                                    value={driveId}
                                >
                                    <MenuItem value={0}>None</MenuItem>
                                    {
                                        drives.map(
                                            (drive) => <MenuItem key={drive.id} value={drive.id}>
                                                {drive.driveName}
                                            </MenuItem>
                                        )
                                    }
                                </Select>
                                {errors.drive?.message && (
                                    <FormHelperText sx={{ color: 'error.main' }}>
                                        Please select drive name
                                    </FormHelperText>
                                )}
                            </FormControl>

                            <FormControl sx={{
                                // maxWidth: 600,
                                flexGrow: 1
                            }}>
                                <InputLabel id="courseNameLabel">Course Name<Box component='span' sx={{ color: 'error.main', ml: 0.25 }}>*</Box></InputLabel>
                                <Select
                                    labelId="courseNameLabel"
                                    id="course"
                                    label='Course Name*'
                                    margin='none'
                                    name='course'
                                    error={Boolean(errors.course)}
                                    onBlur={() => trigger('course')}
                                    onChange={(e, value) => {
                                        setCourseId(value.props.value)
                                        setValue('course', value.props.value)
                                    }}
                                    value={courseId}
                                >
                                    <MenuItem value={0}>None</MenuItem>
                                    {
                                        courses.map(
                                            (course) => <MenuItem key={course.id} value={course.id}>
                                                {course.courseName}
                                            </MenuItem>
                                        )
                                    }
                                </Select>
                                {errors.course?.message && (
                                    <FormHelperText sx={{ color: 'error.main' }}>
                                        Please select course name
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </div>

                        <FormControlLabel sx={{
                            mt: 2,
                            mb: 3
                        }} control={
                            <Switch
                                checked={isActive}
                                onChange={handleActiveChange}
                            />
                        } label={<Box component='span'>Active</Box>} />

                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 2
                        }}>
                            <RadiusButton sx={{
                                textTransform: 'uppercase',
                                fontWeight: 500,
                            }} color='grey'
                                variant='contained'
                                size='large'
                                // disabled
                                onClick={onGoBack}
                                startIcon={<img src={BACK} alt='' />}>
                                Back
                            </RadiusButton>
                            <LoadingButton sx={{
                                textTransform: 'uppercase',
                                borderRadius: 40,
                            }} color='primary'
                                type="submit"
                                variant='contained'
                                size='large'
                                loading={isLoading}
                            >
                                Submit
                            </LoadingButton>
                        </Box>


                    </Box>
                </Box>

            </Container>
            <UserCreationMail userDetails={userDetails} openState={mailModal.isOpen} isUpdated={mailModal.isUpdated} setMailModal={setMailModal} />
        </MainContainer>
    );
}

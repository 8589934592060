import { axios } from '../config/axios';

const endPoint = '/project/feedback-question';

const getDataForFeedbackDashboard = (configurationId) => {
    return axios.get(`${endPoint}/data-for-feedback-dashboard/${configurationId}`);
}

const getAllByConfigurationId = (body) => {
    return axios.post(`${endPoint}/list-by-configuration-id`, body);
}

const getForPreview = (body) => {
    return axios.post(`${endPoint}/for-preview`, body);
}

const createFromFile = (body) => {
    return axios.post(`${endPoint}/create-from-file`, body);
}

const deleteAll = (body) => {
    return axios.post(`${endPoint}/delete-all`, body);
}

export {
    getDataForFeedbackDashboard,
    getAllByConfigurationId,
    getForPreview,
    createFromFile,
    deleteAll,
}
import { Fragment } from 'react';
import { Box, Typography, Modal } from '@mui/material';

import RadiusButton from '../../../../components/RadiusButton'

export const ContentUploadErrorsModal = ({ currentState, setCurrentState }) => {

    const handleClose = () => {
        setCurrentState({
            ...currentState,
            isVisible: false,
        });
    }

    return (
        <Modal
            hideBackdrop={true}
            className='backdrop'
            open={currentState.isVisible}
            onClose={handleClose}
        >
            <Box className='modal-container' sx={{
                maxWidth: 800,
                p: 3,
            }}>
                <Typography variant="h5" component="h5" sx={{
                    mb: 1.5,
                    color: 'primary.main'
                }}>
                    Questions upload errors
                </Typography>

                <Box sx={{
                    mb: 3,
                    overflow: 'auto',
                    maxHeight: 320
                }}>
                    {
                        currentState.errors.map((error, index) => {
                            return (
                                <Fragment key={index}>
                                    {error.index && (
                                        <Typography variant="h6" sx={{ ml: 2, fontSize: 14 }}>
                                            For row number {error.index} {error.errors.join(', ')}
                                        </Typography>
                                    )}
                                    {error.questionNo && (
                                        <Typography variant="h6" sx={{ ml: 2, fontSize: 14 }}>
                                            Question no {error.questionNo} is {error.error}
                                        </Typography>
                                    )}
                                    {error.language && (
                                        <Typography variant="h6" sx={{ ml: 2, fontSize: 14 }}>
                                            For {error.language} language {error.error}
                                        </Typography>
                                    )}
                                    {error.sectionTitle && (
                                        <Typography variant="h6" sx={{ ml: 2, fontSize: 14 }}>
                                            For {error.sectionTitle} section {error.error}
                                        </Typography>
                                    )}
                                </Fragment>
                            )
                        })
                    }
                </Box>

                <Box className='actions-container'>
                    <RadiusButton variant='contained' sx={{ ml: 'auto' }} onClick={handleClose}>
                        Okay
                    </RadiusButton>
                </Box>

            </Box>
        </Modal>
    );
}

import { useContext, useEffect, useState } from 'react';
import { Box, Typography, InputAdornment, IconButton, FormControl, InputLabel, OutlinedInput, FormHelperText } from '@mui/material';

import { Container, MainContainer } from '../../../../components';
import { Menu } from '../Menu/Menu';
import { useToaster } from '../../../../providers/ToasterProvider';

import EYE_ICON from '../../../../assets/images/eye.svg';
import EYE_CLOSE_ICON from '../../../../assets/images/eye-close.svg'

import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { resetPassword } from '../../../../services/authService';
import { AuthContext } from '../../../../providers/auth';
import patterns from '../../../../constant/patterns';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import storage from '../../../../config/storage';

export const ResetPassword = () => {

    const params = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const { addNotification } = useToaster();
    const { user, setUser } = useContext(AuthContext);
    const [accessToken, setAccessToken] = useState(null);
    const [refreshToken, setRefreshToken] = useState(null);
    const [userData, setUserData] = useState(false);
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [newPasswordVisible, setNewPasswordVisible] = useState(false);
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

    const passwordSchema = z.object({
        password: z.string().min(8, 'Password should be at least 8 characters long'),
        newPassword: z.string()
            .min(8, 'Password should be at least 8 characters long')
            .refine((value) => patterns.PASSWORD.test(value ?? ""), 'Password must contain one uppercase character, one lowercase character, one digit and one special character'),
        confirmPassword: z.string(),
    }).superRefine(({ password, newPassword, confirmPassword }, ctx) => {
        if (newPassword === password) {
            ctx.addIssue({
                code: 'custom',
                message: 'New password must be different from existing one',
                path: ['newPassword']
            });
        }
        if (newPassword !== confirmPassword) {
            ctx.addIssue({
                code: 'custom',
                message: 'Passwords did not match',
                path: ['confirmPassword']
            });
        }
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
        trigger,
        reset,
    } = useForm({
        resolver: zodResolver(passwordSchema),
    });

    useEffect(() => {
        const userToken = location?.state?.accessToken;
        const userRefreshToken = location?.state?.refreshToken;
        const user = location?.state?.user;
        if (!userToken || !user || !userRefreshToken) {
            const projectShortName = params?.projectName;
            if (projectShortName) {
                navigate(`/${projectShortName}/login`);
            }
        } else {
            setRefreshToken(userRefreshToken);
            setAccessToken(userToken);
            setUserData(user);
        }
    }, [location]);

    useEffect(() => {
        if (user) {
            const projectShortName = params?.projectName;
            if (projectShortName) {
                navigate(`/${projectShortName}/login`)
            }
        } else {
            const resetPasswordData = storage.getResetPasswordData();
            if (!resetPasswordData || !resetPasswordData?.refreshToken || !resetPasswordData?.accessToken || !resetPasswordData?.user) {
                const projectShortName = params?.projectName;
                navigate(`/${projectShortName}/login`);
                return;
            }
            setRefreshToken(resetPasswordData.refreshToken);
            setAccessToken(resetPasswordData.accessToken);
            setUserData(resetPasswordData.user);
        }
    }, []);

    const navigateUser = () => {
        const projectShortName = params?.projectName;
        if (!userData || !projectShortName) return;
        const userType = userData.userType;
        if (userType === 2) {
            navigate(`/${projectShortName}/dashboard`);
        } else if (userType === 3) {
            if (userData.modules.includes(101)) {
                navigate(`/${projectShortName}/content-dashboard`);
            } else if (userData.modules.includes(102)) {
                navigate(`/${projectShortName}/mac-ids`);
            } else if (userData.modules.includes(103)) {
                navigate(`/${projectShortName}/score-management`);
            } else if (userData.modules.includes(104)) {
                navigate(`/${projectShortName}/customer/dashboard`);
            }
        } else if (userType === 4) {
            navigate(`/${projectShortName}/content-dashboard`);
        }
    }

    const submitForm = async (values) => {
        try {
            const res = await resetPassword(accessToken, values);
            if (res.code === 'OK') {
                addNotification('Password has been reset successfully.', 'success');
                storage.setUserDetails(userData);
                storage.setToken(accessToken);
                storage.setRefreshToken(refreshToken);
                storage.clearResetPasswordData();
                setUser(userData);
                navigateUser();
            }
            reset();
        } catch (error) {
            const code = error?.response?.data?.code;
            if (code === 'E_BAD_REQUEST') {
                addNotification('password does not match.', 'danger');
            } else {
                addNotification('unexpected error occured.', 'danger');
            }
            console.log('There is an error while changing password', error);
        }
    }


    return (
        <MainContainer sx={{ bgcolor: 'red' }}>
            <Menu>
                {/* <Breadcrumbs aria-label="breadcrumb" separator='' sx={{ gap: 12 }}></Breadcrumbs> */}
            </Menu>
            <Container sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <Box sx={{
                    width: '100%',
                    maxWidth: 620,
                    px: 4.5,
                    py: 3,
                    borderRadius: 6,
                    bgcolor: 'background.base',
                }} className='login-container'>
                    <Typography variant='h5' sx={{ mb: 3 }}>Reset Password</Typography>
                    <Box component='form' onSubmit={handleSubmit(submitForm)} sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2
                    }}>
                        <FormControl variant='outlined' sx={{ width: '100%' }}>
                            <InputLabel htmlFor="password">Password</InputLabel>
                            <OutlinedInput
                                id='password'
                                type={passwordVisible ? 'text' : 'password'}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setPasswordVisible(!passwordVisible)}
                                            edge="end"
                                        >
                                            <img className='password-eye' src={passwordVisible ? EYE_CLOSE_ICON : EYE_ICON} alt='' />
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="Password"
                                name="password"
                                {...register('password')}
                                error={Boolean(errors.password)}
                                onBlur={() => trigger('password')}
                            />
                            {errors?.password?.message && (
                                <FormHelperText sx={{ color: 'error.main' }}>
                                    {errors.password?.message}
                                </FormHelperText>
                            )}
                        </FormControl>

                        <FormControl variant='outlined' sx={{ width: '100%' }}>
                            <InputLabel htmlFor="newPasswordLabel">New Password</InputLabel>
                            <OutlinedInput
                                id='newPasswordLabel'
                                type={newPasswordVisible ? 'text' : 'password'}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setNewPasswordVisible(!newPasswordVisible)}
                                            edge="end"
                                        >
                                            <img className='password-eye' src={newPasswordVisible ? EYE_CLOSE_ICON : EYE_ICON} alt='' />
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="New Password"
                                name="newPassword"
                                {...register('newPassword')}
                                error={Boolean(errors.newPassword)}
                                onBlur={() => trigger('newPassword')}
                            />
                            {errors?.newPassword?.message && (
                                <FormHelperText sx={{ color: 'error.main' }}>
                                    {errors.newPassword?.message}
                                </FormHelperText>
                            )}
                        </FormControl>

                        <FormControl variant='outlined' sx={{ width: '100%' }}>
                            <InputLabel htmlFor="confirmPasswordLabel">Confirm Password</InputLabel>
                            <OutlinedInput
                                id='confirmPasswordLabel'
                                type={confirmPasswordVisible ? 'text' : 'password'}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setConfirmPasswordVisible(!confirmPasswordVisible)}
                                            edge="end"
                                        >
                                            <img className='password-eye' src={confirmPasswordVisible ? EYE_CLOSE_ICON : EYE_ICON} alt='' />
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="Confirm Password"
                                name="confirmPassword"
                                {...register('confirmPassword')}
                                error={Boolean(errors.confirmPassword)}
                                onBlur={() => trigger('confirmPassword')}
                            />
                            {errors?.confirmPassword?.message && (
                                <FormHelperText sx={{ color: 'error.main' }}>
                                    {errors.confirmPassword?.message}
                                </FormHelperText>
                            )}
                        </FormControl>
                        <LoadingButton
                            sx={{
                                textTransform: 'uppercase',
                                borderRadius: 40,
                                alignSelf: 'flex-start',
                                mt: 2,
                                mx: 'auto'
                            }} color='secondary'
                            type="submit"
                            variant='contained'
                            size='large'
                        >
                            Reset Password
                        </LoadingButton>
                    </Box>
                </Box>

            </Container>
        </MainContainer >
    );
}

import { Fragment, useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { ProjectContext } from "../providers/projectProvider";
import { AuthContext } from "../providers/auth";


const BackOfficeAuthGuard = ({ component }) => {
    const { user } = useContext(AuthContext);
    const { projectDetails } = useContext(ProjectContext);
    const navigate = useNavigate();
    const params = useParams();

    useEffect(() => {
        const projectName = params?.projectName ? params.projectName : 'list';
        if (!user || user?.userType != 3 || !user?.modules?.includes(103)) {
            if (projectName) {
                navigate(`/${projectName}/login`)
            } else {
                navigate('/list');
            }
        }
    }, [user, navigate, projectDetails]);

    return (
        <Fragment>{component}</Fragment>
    );
}

export default BackOfficeAuthGuard;
import { axios } from "../config/axios";

const endPoint = '/project/course';

const createCourse = async (body) => {
    return axios.post(`${endPoint}`, body);
}

const getAll = async () => {
    return axios.get(`${endPoint}`);
}

export {
    createCourse,
    getAll,
}
import { useEffect, useState } from 'react';
import { Box, Breadcrumbs, Typography, Table, TableContainer, Paper, FormControlLabel, TableHead, TableRow, TableCell, TableBody, Pagination, FormGroup, Switch } from '@mui/material';
import { Container, MainContainer } from '../../../../components';

import SEARCH_ICON from '../../../../assets/images/search-icon.svg';
import ARROW from '../../../../assets/images/breadcrumb-arrow.svg';
import ARROW_OPEN from '../../../../assets/images/breadcrumb-arrow-open.svg';
import ADD_PROJECT from '../../../../assets/images/add-project.svg';
import PENCIL from '../../../../assets/images/pencil.svg';
import VIEW from '../../../../assets/images/view.svg';


import './Projects.scss';
import RadiusButton from '../../../../components/RadiusButton';
import { Link, useNavigate } from 'react-router-dom';
import { getListByPage, updateById } from '../../../../services/projectService';
import { SuperAdminMenu } from '../SuperAdminMenu/SuperAdminMenu';
import storage from '../../../../config/storage';

const columns = [
    { id: 'projectName', label: 'Project Name', minWidth: 130 },
    { id: 'customerName', label: 'Customer Name', minWidth: 142 },
    { id: 'customerSPOC', label: 'Customer SPOC', minWidth: 145 },
    { id: 'mobile', label: 'Contact No', minWidth: 120 },
    { id: 'projectDescription', label: 'Project Description', minWidth: 170 },
    { id: 'examMode', label: 'Exam Mode', minWidth: 80 },
    { id: 'actions', label: '', minWidth: 170 },
    { id: 'isActive', label: '', minWidth: 60 },
];
const limit = parseInt(process.env.REACT_APP_PAGE_LIMIT);
let timer = null;

export const Projects = () => {

    const [projects, setProjects] = useState([]);
    const [maxPages, setMaxPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState('');
    const [newSearch, setNewSearch] = useState(false);
    const handlePageChange = (e, value) => {
        setCurrentPage(value)
    };

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await getListByPage(search, currentPage, limit);
                if (response?.code === 'OK') {
                    const totalPages = Math.ceil(response.data.count / limit);
                    setMaxPages(totalPages);
                    setProjects(response.data.rows);
                }
            } catch (error) {
                console.log('There is an error while fetching projects list', error);
            }
        }
        fetchData();
    }, [currentPage, newSearch]);


    const navigate = useNavigate();
    const handleAddProject = () => {
        navigate('/project-details')
    }

    const onActiveToggle = async (projectIndex, value) => {
        const { id, isActive } = projects[projectIndex];
        if (isActive !== value) {
            try {
                await updateById(id, { isActive: value });
                setProjects(projects.map((p) => {
                    if (p.id === id) {
                        p.isActive = value;
                    }
                    return p;
                }))

            } catch (error) {
                console.log('There is an error while updating project active state', error);
            }
        }
    }

    const onSearch = (e) => {
        setSearch(e.target.value);
        if (timer) {
            clearTimeout(timer);
        }
        timer = setTimeout(() => {
            setCurrentPage(1);
            setNewSearch(!newSearch)
        }, 400);
    }

    const handleViewProject = (project) => {
        const encryptedProjectShortName = project.encryptedProjectShortName;
        if (!encryptedProjectShortName) return;

        storage.clearToken();
        storage.clearUserDetails();
        navigate(`/${encryptedProjectShortName}/login`);
    }

    const renderTableCell = (columnId, projectIndex) => {
        const project = projects[projectIndex];
        switch (columnId) {
            case 'actions':
                return <>
                    <Box component='span' sx={{
                        mr: 1,
                        cursor: 'pointer'
                    }} onClick={() => handleViewProject(project)}>
                        <img src={VIEW} alt='' />
                        <Typography variant='body1' sx={{
                            display: 'inline-block',
                            verticalAlign: 'super',
                            ml: 1,
                            color: 'primary.main'
                        }}>View</Typography>
                    </Box>
                    <Box component='span' sx={{
                        cursor: 'pointer'
                    }}>
                        <Link to='/project-details' state={{ projectId: project.id }}>
                            <img src={PENCIL} alt='' />
                            <Typography variant='body1' sx={{
                                display: 'inline-block',
                                verticalAlign: 'super',
                                ml: 1,
                                color: 'success.main'
                            }}>Edit</Typography>
                        </Link>
                    </Box>
                </>
            case 'isActive':
                const checked = project['isActive'];
                return <FormGroup>
                    <FormControlLabel control={<Switch checked={checked} onChange={(e, value) => onActiveToggle(projectIndex, value)} />} label="Active" />
                </FormGroup>
            case 'mobile':
                return project[columnId].split(',').join(', ')
            default:
                return project[columnId]
        }
    }

    return (
        <MainContainer>
            <SuperAdminMenu>
                <Breadcrumbs aria-label="breadcrumb" separator='' sx={{ gap: 12 }}>
                    <Typography color="text.primary" sx={{ fontWeight: 400 }}>View Project</Typography>
                    <Box sx={{ display: 'flex', alignContent: 'center' }}>
                        <img src={ARROW} alt=''></img>
                    </Box>

                    <Typography color="text.primary" sx={{ fontWeight: 500 }}>Project List</Typography>
                    <Box sx={{ display: 'flex', alignContent: 'center' }}>
                        <img src={ARROW_OPEN} alt='' ></img>
                    </Box>
                </Breadcrumbs>
            </SuperAdminMenu>

            <Container sx={{
                display: 'flex',
                flexDirection: 'column'
            }}>
                <h1 className='title'>
                    Project List
                </h1>

                <div className="search-header-wrapper">
                    <div className='search-wrapper'>
                        <input type='text' value={search} onChange={onSearch} className='search-box' placeholder='Search...' />
                        <img className='search-icon' src={SEARCH_ICON} alt='' />

                    </div>

                    <RadiusButton sx={{
                        textTransform: 'uppercase',
                        fontWeight: 500,
                    }} color="secondary"
                        type="submit"
                        variant='contained'
                        size='large'
                        onClick={handleAddProject}
                        startIcon={<img src={ADD_PROJECT} alt='' />}>
                        Add Project
                    </RadiusButton>
                </div>


                <Paper sx={{
                    width: '100%',
                    overflow: 'hidden',
                    bgcolor: 'background.base',
                    maxHeight: 'calc(100vh - 305px)',
                    maxWidth: 'calc(100vw - 48px)',
                    borderRadius: 1,
                    mb: 4
                }}>
                    <TableContainer sx={{
                        px: 1,
                        pb: 1,
                        maxHeight: 'inherit',
                        maxWidth: 'inherit',
                        border: '1px solid',
                        borderColor: 'primary.main',
                        borderRadius: 1
                    }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                            sx={{
                                                color: 'primary.main',
                                                maxWidth: 400
                                            }}
                                            key={column.id}
                                            align='center'
                                            style={{ minWidth: column.minWidth }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {!projects?.length
                                    ? <TableRow sx={{ mx: 'auto', p: 2, color: 'grey.500' }}>
                                        <TableCell colSpan={8} sx={{ border: 'none', textAlign: 'center' }}>
                                            No records available
                                        </TableCell>
                                    </TableRow>
                                    : projects.map((_, projectIndex) => {
                                        return (
                                            <TableRow hover role="checkbox" key={projectIndex}>
                                                {columns.map((column) => {
                                                    return <TableCell key={column.id} align={'center'} sx={{
                                                        px: 1.5,
                                                        py: 0.75
                                                    }}>
                                                        {renderTableCell(column.id, projectIndex)}
                                                    </TableCell>
                                                })}
                                            </TableRow>
                                        );
                                    })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>

                <Pagination count={maxPages} page={currentPage} onChange={handlePageChange} sx={{ mt: 'auto' }} shape="rounded" />


            </Container>

        </MainContainer>
    );
}

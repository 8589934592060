import { axios } from "../config/axios";

const endPoint = '/project/extended-time';

const getListByProjectMasterWithPagination = async (projectMasterId, examHallId, status, searchTerm, pageNumber, limit) => {
    return axios.post(`${endPoint}/list-requested-by-project-master-with-pagination`, {
        projectMasterId: projectMasterId,
        examHallId: examHallId ?? 0,
        status: status,
        search: searchTerm,
        page: pageNumber,
        limit: limit
    });
}

const getRequiredData = () => {
    return axios.get(`${endPoint}/required-data`);
}

const extendForAllCandidates = (body) => {
    return axios.post(`${endPoint}/extend-for-all-candidates`, body);
}

const extendTimeOfCandidates = (body) => {
    return axios.post(`${endPoint}/extend-time-of-candidates`, body);
}

const approveRequestedExtendedTimes = (body) => {
    return axios.post(`${endPoint}/approve-extended-times`, body);
}

export {
    getListByProjectMasterWithPagination,
    getRequiredData,
    extendForAllCandidates,
    extendTimeOfCandidates,
    approveRequestedExtendedTimes,
}
import { jwtDecode } from 'jwt-decode';
const storagePrefix = "dte_";

const storage = {
  getToken: () => {
    return JSON.parse(window.sessionStorage.getItem(`${storagePrefix}token`));
  },
  setToken: (token) => {
    const decoded = jwtDecode(token);
    if (decoded?.exp) {
      window.sessionStorage.setItem(
        `${storagePrefix}token`,
        JSON.stringify({
          accessToken: token,
          exp: decoded.exp
        })
      );
    }
  },
  clearToken: () => {
    window.sessionStorage.removeItem(`${storagePrefix}token`);
  },
  getRefreshToken: () => {
    return JSON.parse(window.sessionStorage.getItem(`${storagePrefix}refresh_token`));
  },
  setRefreshToken: (token) => {
    const decoded = jwtDecode(token);
    if (decoded?.exp) {
      window.sessionStorage.setItem(
        `${storagePrefix}refresh_token`,
        JSON.stringify({
          refreshToken: token,
          exp: decoded.exp
        })
      );
    }
  },
  clearRefreshToken: () => {
    window.sessionStorage.removeItem(`${storagePrefix}refresh_token`);
  },
  getUserDetails: () => {
    return JSON.parse(window.sessionStorage.getItem(`${storagePrefix}user_details`));
  },
  setUserDetails: (userDetails) => {
    window.sessionStorage.setItem(
      `${storagePrefix}user_details`,
      JSON.stringify(userDetails)
    );
  },
  clearUserDetails: () => {
    window.sessionStorage.removeItem(`${storagePrefix}user_details`);
  },
  getProjectShortName: () => {
    const value = window.sessionStorage.getItem(`${storagePrefix}project_short_name`);
    if (!value) {
      return null;
    } else {
      return JSON.parse(value);
    }
  },
  setProjectShortName: (projectShortName) => {
    window.sessionStorage.setItem(
      `${storagePrefix}project_short_name`,
      JSON.stringify(projectShortName)
    );
  },
  clearProjectShortName: () => {
    window.sessionStorage.removeItem(`${storagePrefix}project_short_name`);
  },
  setResetPasswordData: (data) => {
    window.sessionStorage.setItem(
      `${storagePrefix}reset_password_data`,
      JSON.stringify(data)
    );
  },
  getResetPasswordData: (data) => {
    const value = window.sessionStorage.getItem(`${storagePrefix}reset_password_data`);
    if (!value) {
      return null;
    } else {
      return JSON.parse(value);
    }
  },
  clearResetPasswordData: () => {
    window.sessionStorage.removeItem(`${storagePrefix}reset_password_data`);
  }
};

export default storage;
